import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientGroupsComponent } from './admin/client-groups/client-groups.component';
import { ClientDevicesComponent } from './admin/client-devices/client-devices.component';
import { ClientOrderDetailsComponent } from './admin/client-order-details/client-order-details.component';
import { ClientOrdersComponent } from './admin/client-orders/client-orders.component';
import { ClientsComponent } from './admin/clients/clients.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { DashComponent } from './dash/dash.component';
import { DevicesComponent } from './devices/devices.component';
import { GroupsComponent } from './groups/groups.component';
import { AuthGuard } from './guards/auth.guard';
import { CommandComponent } from './reports/command/command.component';
import { StatusComponent } from './reports/status/status.component';
import { DeviceDetailsComponent } from './reports/device-details/device-details.component';
import { RolesGuard } from './guards/roles.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'groups', component: GroupsComponent, canActivate: [AuthGuard] },
  { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgetPasswordComponent },
  { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
  {
    path: 'clients',
    component: ClientsComponent,
    data: { expectedRole: '0' },
    canActivate: [RolesGuard, AuthGuard],
  },
  {
    path: 'reports',
    children: [
      { path: 'command', component: CommandComponent },
      { path: 'status', component: StatusComponent },
      { path: 'device-details', component: DeviceDetailsComponent },
    ],
  },
  { path: 'client-groups', component: ClientGroupsComponent },
  { path: 'client-order', component: ClientOrdersComponent },
  { path: 'client-order-details', component: ClientOrderDetailsComponent },
  { path: 'client-devices', component: ClientDevicesComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
