<div class="container-fluid py-5">
  <div class="row">
    <div class="col-12 mb-4">
      <p class="d-inline p-3">Commands not Compelete: <span class="badge badge-indingo">{{ numberOfCommands }}</span>
      </p>
    </div>
    <div class="col-12 mb-4">
      <div class="card border-0 shadow-sm h-100">
        <div class="card-header">
          <div class="card-title"><span class="label">Groups and Status</span></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="table-responsive">
                <p class="text-right" *ngIf="groupsDataSource.data.length <= 0">no data available</p>
                <table mat-table multiTemplateDataRows [dataSource]="groupsDataSource" class="mat-elevation-z8 w-100"
                  *ngIf="groupsDataSource.data.length > 0">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Group Name</th>
                    <td mat-cell *matCellDef="let element">{{ element.group }}</td>
                  </ng-container>
                  <ng-container matColumnDef="number of devices">
                    <th mat-header-cell *matHeaderCellDef># of devices</th>
                    <td mat-cell *matCellDef="let element">{{ element.devices }}</td>
                  </ng-container>
                  <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef>Active</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.active }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="non active">
                    <th mat-header-cell *matHeaderCellDef>Non Active</th>
                    <td mat-cell *matCellDef="let element">{{ element.inActive }}</td>
                  </ng-container>
                  <ng-container matColumnDef="online">
                    <th mat-header-cell *matHeaderCellDef>network (online)</th>
                    <td mat-cell *matCellDef="let element">{{ element.online }}</td>
                  </ng-container>
                  <ng-container matColumnDef="offline">
                    <th mat-header-cell *matHeaderCellDef>network (offline)</th>
                    <td mat-cell *matCellDef="let element">{{ element.offline }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator #groupsPaginator [pageSizeOptions]="[5, 10, 20]" length="groupsDataSource.data.length"
                  showFirstLastButtons></mat-paginator>
                <!-- Ends Devices Table -->
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="chart my-3 rounded shadow-sm" *ngIf="groupsDataSource.data.length > 0">
                <apx-chart [series]="groupChartOptions.series" [chart]="groupChartOptions.chart"
                  [xaxis]="groupChartOptions.xaxis" [title]="groupChartOptions.title"
                  [plotOptions]="groupChartOptions.plotOptions" [fill]="groupChartOptions.fill"
                  [legend]="groupChartOptions.legend"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="card border-0 shadow-sm h-100">
        <div class="card-header">
          <div class="card-title br-1">Devices by Area</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="table-responsive">
                <p class="text-right" *ngIf="devicesAreaDataSource.data.length <= 0">no data available</p>
                <table class="mat-elevation-z8 w-100" mat-table multiTemplateDataRows
                  [dataSource]="devicesAreaDataSource" *ngIf="devicesAreaDataSource.data.length > 0">

                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Area </th>
                    <td mat-cell *matCellDef="let element"> {{element.area}} </td>
                  </ng-container>

                  <ng-container matColumnDef="number of devices">
                    <th mat-header-cell *matHeaderCellDef> # of devices </th>
                    <td mat-cell *matCellDef="let element"> {{element.devices}} </td>
                  </ng-container>

                  <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef> active </th>
                    <td mat-cell *matCellDef="let element"> {{element.active}} </td>
                  </ng-container>

                  <ng-container matColumnDef="non active">
                    <th mat-header-cell *matHeaderCellDef> non active </th>
                    <td mat-cell *matCellDef="let element"> {{element.inActive}} </td>
                  </ng-container>

                  <ng-container matColumnDef="online">
                    <th mat-header-cell *matHeaderCellDef> online </th>
                    <td mat-cell *matCellDef="let element"> {{element.online}} </td>
                  </ng-container>

                  <ng-container matColumnDef="offline">
                    <th mat-header-cell *matHeaderCellDef> offline </th>
                    <td mat-cell *matCellDef="let element"> {{element.offline}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #devicesAreaPaginator [pageSizeOptions]="[5, 10, 20]"
                  length="devicesAreaDataSource.data.length" showFirstLastButtons></mat-paginator>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="chart my-3 shadow-sm" *ngIf="devicesAreaDataSource.data.length > 0">
                <apx-chart [series]="areaChartOptions.series" [chart]="areaChartOptions.chart"
                  [xaxis]="areaChartOptions.xaxis" [title]="areaChartOptions.title"
                  [plotOptions]="areaChartOptions.plotOptions" [fill]="areaChartOptions.fill"
                  [legend]="groupChartOptions.legend">
                </apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-4">
      <div class="card border-0 shadow-sm h-100">
        <div class="card-header">
          <div class="card-title">Account Managers</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="table-responsive">
                <p class="text-right" *ngIf="accountManagers.data.length <= 0">no data available</p>
                <table mat-table multiTemplateDataRows [dataSource]="accountManagers" class="mat-elevation-z8 w-100"
                  *ngIf="accountManagers.data.length > 0">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Account Manager</th>
                    <td mat-cell *matCellDef="let element">{{ element.accountManager }}</td>
                  </ng-container>
                  <ng-container matColumnDef="number of devices">
                    <th mat-header-cell *matHeaderCellDef># of devices</th>
                    <td mat-cell *matCellDef="let element">{{ element.devices }}</td>
                  </ng-container>
                  <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef>Active</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.active }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="non active">
                    <th mat-header-cell *matHeaderCellDef>Non Active</th>
                    <td mat-cell *matCellDef="let element">{{ element.inActive }}</td>
                  </ng-container>
                  <ng-container matColumnDef="online">
                    <th mat-header-cell *matHeaderCellDef>network (online)</th>
                    <td mat-cell *matCellDef="let element">{{ element.online }}</td>
                  </ng-container>
                  <ng-container matColumnDef="offline">
                    <th mat-header-cell *matHeaderCellDef>network (offline)</th>
                    <td mat-cell *matCellDef="let element">{{ element.offline }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator #accountManagersPaginator [pageSizeOptions]="[5, 10, 20]"
                  length="groupsDataSource.data.length" showFirstLastButtons></mat-paginator>
                <!-- Ends Devices Table -->
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="chart my-3 shadow-sm" *ngIf="accountManagers.data.length > 0">
                <apx-chart [series]="managersChartOptions.series" [chart]="managersChartOptions.chart"
                  [xaxis]="managersChartOptions.xaxis" [title]="managersChartOptions.title"
                  [plotOptions]="managersChartOptions.plotOptions" [fill]="managersChartOptions.fill"
                  [legend]="groupChartOptions.legend">
                </apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-4">
      <div class="card border-0 shadow-sm h-100">
        <div class="card-header">
          <div class="card-title">Last 10 Log Records</div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <p class="text-right" *ngIf="Last10Logs.data.length <= 0">no data available</p>
            <table mat-table multiTemplateDataRows [dataSource]="Last10Logs" class="mat-elevation-z8 w-100"
              *ngIf="Last10Logs.data.length > 0">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>DEVICE ID</th>
                <td mat-cell *matCellDef="let element">{{ element.deviceId }}</td>
              </ng-container>
              <ng-container matColumnDef="command">
                <th mat-header-cell *matHeaderCellDef>COMMAND</th>
                <td mat-cell *matCellDef="let element">{{ element.command }}</td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>DATE SENT</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.dateSent | date: "M/d/yy, h:mm a" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef>USERNAME</th>
                <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>COMMAND STATUS</th>
                <td mat-cell *matCellDef="let element">{{ element.commandStatus == null? '-' : element.commandStatus }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="logsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: logsColumns"></tr>
            </table>
            <mat-paginator #logsPaginator [pageSizeOptions]="[5, 10, 20]" length="Last10Logs.data.length"
              showFirstLastButtons></mat-paginator>
            <!-- Ends Devices Table -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>