<div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" color="primary" style="text-align: center">
    <button
      routerLink="/login"
      class="back-icon"
      mat-icon-button
      aria-label="Example icon button with a vertical three dot icon"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>
    <mat-card-header>
      <mat-card-title>Forgot Password</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName="email" />
        </mat-form-field>
      </mat-card-content>
      <button mat-stroked-button color="primary" class="btn-block">
        OK
        <span
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
      </button>
    </form>
  </mat-card>
</div>
