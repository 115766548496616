import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
})
export class ForgetPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  isLoading: Boolean = false;
  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  get f() {
    return this.forgotForm.controls;
  }

  onSubmit() {
    if (this.forgotForm.invalid) return;
    this.isLoading = true;
    this.auth.forgotPassword(this.f.email.value).subscribe(
      (res) => {
        this.isLoading = false;
        console.log({ res });
        this.auth.userMail = this.f.email.value;
        this.toaster.success('Code Sent Successfully Please Check Your Mail');
        setTimeout(() => {
          this.router.navigateByUrl('reset-password');
        }, 1000);
      },
      (err) => {
        this.isLoading = false;
        console.error({ err });
      }
    );
  }
}
