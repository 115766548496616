<h2 mat-dialog-title>Command Group</h2>
<mat-dialog-content
  style="width: 800px; min-height: fit-content; max-height: fit-content"
  class="add-device-dialog mat-typography"
  minWidth="1920"
>
  <mat-tab-group>
    <!-- Start dd Devices By Search Dialog -->
    <mat-tab label="Command" style="height: fit-content">
      <mat-grid-list cols="4" rowHeight="1:4">
        <mat-grid-tile colspan="2">
          <mat-grid-list
            cols="4"
            rowHight="2"
            style="width: 100%; height: 100%"
          >
            <!-- Command Card Loadder -->
            <mat-progress-spinner
              style="margin: auto; padding: 10px; margin-top: 20px"
              mode="indeterminate"
              *ngIf="isLoading"
              diameter="80"
            ></mat-progress-spinner>

            <div *ngIf="map">
              <google-map height="200px">
                <map-marker
                  *ngFor="let marker of markers"
                  [position]="marker.position"
                  [label]="marker.label"
                  [title]="marker.title"
                  [options]="marker.options"
                >
                </map-marker>
              </google-map>
            </div>
            <!-- Ends Command Card Loadder -->
            <!-- Commands Card -->
            <div *ngIf="!isLoading">
              <mat-grid-tile
                *ngFor="let command of Commands"
                colspan="1"
                rowspan="1"
              >
                <mat-card
                  class="command-card"
                  mat-button
                  (click)="sendCommand(command, true)"
                  [class.selected]="command.state"
                  [class.power-off]="command.id == 6"
                  [class.reboot]="command.id == 5"
                  [class.location]="command.id == 7"
                >
                  <mat-icon>{{ command.icon }} </mat-icon>
                  <br />
                </mat-card>
              </mat-grid-tile>
            </div>
            <!-- Ends Commands Card -->
            <!-- Test Printer Input -->
            <mat-grid-tile colspan="4">
              <mat-form-field class="print-field">
                <mat-label>Clearable input</mat-label>
                <input matInput type="text" [(ngModel)]="printingText" />
              </mat-form-field>
              <button
                mat-button
                color="primary"
                (click)="printText()"
                class="print-btn"
              >
                Print
              </button>
              <br />
            </mat-grid-tile>
            <!-- Ends Test Printer Input -->

            <!-- Install APK -->
            <mat-grid-tile colspan="4">
              <ngx-file-drop
                class="full-width"
                dropZoneLabel="Drop files here"
                (onFileDrop)="fileUpload.dropped($event)"
                (onFileOver)="fileUpload.fileOver($event)"
                (onFileLeave)="fileUpload.fileLeave($event)"
                dropZoneClassName="file-drop-zone"
                contentClassName="file-drop-zone-content"
              >
                <ng-template
                  ngx-file-drop-content-tmp
                  let-openFileSelector="openFileSelector"
                >
                  Drag APK in Here or
                  <button
                    mat-button
                    color="primary"
                    (click)="openFileSelector()"
                  >
                    Browse Files
                  </button>
                </ng-template>
              </ngx-file-drop>
            </mat-grid-tile>
            <!-- Ends Install APK -->
            <!-- Install APK URL -->
            <mat-grid-tile colspan="4">
              <mat-form-field class="print-field">
                <mat-label> APK URL</mat-label>
                <input
                  matInput
                  type="text"
                  [(ngModel)]="apkUrl"
                /> </mat-form-field
              ><button
                mat-button
                color="primary"
                class="print-btn"
                (click)="installApk()"
              >
                Install</button
              ><br />
            </mat-grid-tile>
            <!-- Ends Install APK URL -->
            <!-- Exutes Func -->

            <mat-grid-tile colspan="2" rowspan="0.5">
              <button mat-stroked-button color="primary" class="full-width">
                Complete Recall
                <mat-icon> send </mat-icon>
              </button>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="0.5">
              <button mat-stroked-button color="primary" class="full-width">
                Update Products
                <mat-icon> send </mat-icon>
              </button>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="0.5">
              <button mat-stroked-button color="primary" class="full-width">
                Update Logos
                <mat-icon> send </mat-icon>
              </button>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="0.5">
              <button mat-stroked-button color="primary" class="full-width">
                Update Layout
                <mat-icon> send </mat-icon>
              </button>
            </mat-grid-tile>
            <!-- Ends Exutes Func -->

            <br /> </mat-grid-list
          ><br />
        </mat-grid-tile>
        <!-- Ends Exutes Func -->

        <mat-grid-tile colspan="2">
          <mat-grid-list
            cols="3"
            rowHight="1:2"
            style="width: 100%; height: 100%"
          >
            <mat-grid-tile
              colspan="3"
              rowspan="0.5"
              *ngIf="!isCheckingDeviceSate"
            >
              <mat-label style="margin-top: -20%">
                Devices
                <span
                  >{{ deviceOnline }} Online - {{ deviceOffline }} Offline
                  <button mat-button color="primary" (click)="checkDevices()">
                    Reload
                    <mat-icon>autorenew</mat-icon>
                  </button>
                </span>
              </mat-label>
            </mat-grid-tile>

            <mat-grid-tile
              colspan="3"
              rowspan="0.5"
              *ngIf="isCheckingDeviceSate"
            >
              <mat-progress-spinner
                style="margin: auto; padding: 10px; margin-top: 20px"
                mode="indeterminate"
                diameter="80"
              ></mat-progress-spinner>
            </mat-grid-tile>

            <mat-grid-tile
              colspan="3"
              style="min-height: -webkit-fill-available; overflow-y: scroll"
            >
              <mat-table
                [dataSource]="datasource"
                style="width: 100%; height: fit-content"
              >
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.id | slice: 0:6 }}..
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="serialNumber">
                  <mat-header-cell *matHeaderCellDef>S/N</mat-header-cell>
                  <mat-cell *matCellDef="let element"
                    >{{ element.serialNumber | slice: 0:6 }}..
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                  <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                  <mat-cell *matCellDef="let element"
                    >{{
                      element.deviceType != undefined
                        ? element.deviceType.name
                        : "null"
                    }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="command">
                  <mat-header-cell *matHeaderCellDef>Command</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <button
                      mat-stroked-button
                      color="primary"
                      (click)="openCommandDeviceDialog(element)"
                    >
                      <!-- {{ element.action }} -->
                      <mat-icon *ngIf="element.action == 'Send'">send</mat-icon>
                      <span
                        *ngIf="element.isInstalling"
                        class="spinner-border spinner-border-sm mr-1"
                      ></span>
                    </button>

                    <!--
                    <button
                      mat-stroked-button
                      color="primary"
                      (click)="openCommandDeviceDialog(element)"
                    >
                      <mat-icon>send</mat-icon>
                    </button> -->
                  </mat-cell>
                </ng-container>

                <mat-header-row
                  *matHeaderRowDef="SelectedDevicesTable"
                ></mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: SelectedDevicesTable"
                ></mat-row>
              </mat-table>
            </mat-grid-tile>
          </mat-grid-list>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>

    <!-- Ends dd Devices By Search Dialog -->
    <mat-divider [vertical]="true"></mat-divider>
    <!-- Start Add Devices By Sheet  Dialog -->
    <mat-tab label="Logs">
      <mat-grid-list cols="9" rowHeight="1:1">
        <mat-grid-tile colspan="6" rowspan="1">
          <mat-form-field style="width: 100%">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
              [rangePicker]="picker"
              [formGroup]="dateRangeFilter"
            >
              <input
                matStartDate
                matInput
                placeholder="Start date"
                formControlName="startDate"
              />
              <input
                matEndDate
                matInput
                placeholder="End date"
                formControlName="endDate"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </mat-grid-tile>
        <mat-grid-tile colspan="3" rowspan="1">
          <button mat-raised-button color="primary" (click)="filterLogs()">
            Search
          </button>
        </mat-grid-tile>
        <mat-grid-tile colspan="6" rowspan="1">
          <form class="example-form" style="width: 100%">
            <mat-form-field class="example-full-width">
              <mat-label>Commands Filter</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event)"
                placeholder="Search Commands"
                #input
              />
            </mat-form-field>
          </form>
        </mat-grid-tile>

        <mat-grid-tile colspan="9" rowspan="6">
          <mat-table
            [dataSource]="logDataSource"
            style="width: 100%; overflow-y: scroll; height: 100%"
          >
            <!-- Position Column -->
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
              <mat-cell
                style="font-size: 80%; font-wight: bolder"
                *matCellDef="let element"
              >
                {{ element.id | slice: 0:10 }}
              </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="commandData">
              <mat-header-cell *matHeaderCellDef> Command </mat-header-cell>
              <mat-cell
                style="font-size: 80%; font-wight: bolder"
                *matCellDef="let element"
              >
                {{ element.commandData }}
              </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="reciveTime">
              <mat-header-cell *matHeaderCellDef> Recive Time </mat-header-cell>
              <mat-cell
                style="font-size: 80%; font-wight: bolder"
                *matCellDef="let element"
              >
                {{ element.receivingDateTime | date: "medium" }}
              </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="sendTime">
              <mat-header-cell *matHeaderCellDef> Send Time </mat-header-cell>
              <mat-cell
                style="
                  min-width: fit-content;
                  font-size: 80%;
                  font-wight: bolder;
                "
                *matCellDef="let element"
              >
                {{ element.sendingDateTime | date: "medium" }}
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="user">
              <mat-header-cell *matHeaderCellDef> User </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.user }}
              </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="group">
              <mat-header-cell *matHeaderCellDef> Group </mat-header-cell>
              <mat-cell
                style="font-size: 80%; font-wight: bolder"
                *matCellDef="let element"
              >
                {{ this.groupData.name }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumnsLogs"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsLogs"
            ></mat-row>
          </mat-table>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>

    <!-- Ends Add Devices By Sheet  Dialog -->
  </mat-tab-group>
</mat-dialog-content>
