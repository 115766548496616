import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn: boolean = false;
  userMail: string;
  clientMenuItems = [
    {
      displayName: 'Dashboard',
      iconName: 'dashboard',
      icon: 'src/assets/img/monitor.svg',
      route: '/dashboard',
      children: [],
    },
    {
      displayName: 'devices',
      iconName: 'devices',
      icon: 'src/assets/img/monitor.svg',
      route: '/devices',
      children: [],
    },
    {
      displayName: 'groups',
      iconName: 'groups',
      icon: '',
      route: '/groups',
      children: [],
    },
    {
      displayName: 'reports',
      iconName: 'receipt_long',
      icon: '',
      route: '',
      children: [
        {
          displayName: 'Commands',
          iconName: 'send',
          icon: '',
          route: '/reports/command',
        },
        {
          displayName: 'Status',
          iconName: 'signal_wifi_4_bar',
          icon: '',
          route: '/reports/status',
        },
        {
          displayName: 'Device Details',
          iconName: 'info',
          icon: '',
          route: '/reports/device-details',
        },
      ],
    },
  ];
  adminMenuItems = [
    {
      displayName: 'clients',
      iconName: 'person',
      icon: '',
      route: '/clients',
      children: [],
    },
    {
      displayName: 'reports',
      iconName: 'receipt_long',
      icon: '',
      route: '',
      children: [
        {
          displayName: 'Commands',
          iconName: 'send',
          icon: '',
          route: '/reports/command',
        },
        {
          displayName: 'Status',
          iconName: 'signal_wifi_4_bar',
          icon: '',
          route: '/reports/status',
        },
        {
          displayName: 'Device Details',
          iconName: 'info',
          icon: '',
          route: '/reports/device-details',
        },
      ],
    },
  ];
  menuItems = [];

  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    return this.http
      .post(environment.baseURI + 'account/login', {
        email,
        password,
      })
      .pipe(
        map((res: any) => {
          if (res.result == 'success') {
            console.log(res.result, res)
            this.isLoggedIn = true;
            localStorage.setItem('isLoggedIn', JSON.stringify(true));
            localStorage.setItem('access_token', res.token);
            localStorage.setItem('clientID', res.clientId);
            localStorage.setItem('apiKey', res.apiKey);
            localStorage.setItem('roleID', res.role);
            console.log(res.apiKey, 'RES');

            if (res.role > 0) {
              console.log('Client Login');
              this.menuItems = this.clientMenuItems;
              localStorage.setItem('menuType', '0');
            } else {
              this.menuItems = this.adminMenuItems;
              localStorage.setItem('menuType', '1');
            }
            return res;

            //console.log(res.status, 'states');
          } else {
            console.log(res.result, res)
          }
          
        }, err => console.log(err))
      );
  }

  setMenuType() {
    let type = localStorage.getItem('menuType');
    console.log({ type });

    type == '1'
      ? (this.menuItems = this.adminMenuItems)
      : (this.menuItems = this.clientMenuItems);

    return type;
  }

  forgotPassword(email: string) {
    return this.http.post(
      environment.baseURI + `account/forgot-password/${email}`,
      {}
    );
  }

  resetPassword(password: string, code: string) {
    return this.http.post(environment.baseURI + `account/reset-password`, {
      email: this.userMail,
      password,
      code,
    });
  }

  isUserLoggedIn(): boolean {
    let accessToken = localStorage.getItem('access_token');
    const isExpired = helper.isTokenExpired(accessToken);
    let isLoggedIn =
      localStorage.getItem('isLoggedIn') == 'true' ? true : false;
    return !isExpired && isLoggedIn;
  }

  clearUserData() {
    localStorage.clear();
    this.isLoggedIn = false;
  }
}
