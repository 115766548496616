<h2 mat-dialog-title>Edit Device</h2>
<mat-dialog-content
  style="width: 700px"
  class="add-device-dialog mat-typography"
  minWidth="1920"
>
  <mat-tab-group>
    <!-- Start Edit Device Dialog -->

    <mat-tab label="Edit Device">
      <mat-grid-list cols="9" rowHeight="1:5.5">
        <mat-grid-tile colspan="6">
          <form class="example-form" [formGroup]="editDevice">
            <mat-form-field class="example-full-width">
              <mat-label>Serial Number</mat-label>
              <input matInput [value]="Device_Data.device.id" disabled />
            </mat-form-field>
            <br />
            <br />

            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Type</mat-label>
              <mat-select
                (selectionChange)="onTypeChange($event)"
                formControlName="deviceTypeId"
                placeholder="Type"
              >
                <mat-option
                  *ngFor="let type of typesList"
                  value="{{ type.id }}"
                >
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Status</mat-label>
              <mat-select
                formControlName="deviceStatus"
                [(value)]="Device_Data.device.status"
                placeholder="Status"
              >
                <mat-option
                  *ngFor="let state of statusList | keyvalue"
                  [value]="toNumber(state.key)"
                >
                  {{ state.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <br />
          </form>
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
          <img
            style="width: 15vw"
            mat-card-image
            [src]="deviceImageUrl"
            alt="Photo of a Shiba Inu"
          />
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>

    <!-- Ends Edit Device Dialog -->
  </mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">Cancel</button>
  <button mat-button cdkFocusInitial (click)="editDeviceInfo()">
    Save
    <span
      *ngIf="isLoading"
      class="spinner-border spinner-border-sm mr-1"
    ></span>
  </button>
</mat-dialog-actions>
