<table
  mat-table
  multiTemplateDataRows
  [dataSource]="dataSource"
  class="mat-elevation-z8"
>
  <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

  <ng-container
    matColumnDef="{{ getColomn(column) }}"
    *ngFor="let column of displayedColumns"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column | titlecase }}</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="column == 'count'">{{ getDevicesCount(element) }}</div>
      {{ column == "id" ? (element.id | slice: 0:5) + "..." : element[column] }}
    </td>
  </ng-container>

  <!-- Commands Column -->
  <ng-container matColumnDef="commands">
    <th mat-header-cell *matHeaderCellDef>Commands</th>
    <td mat-cell *matCellDef="let element">
      <button
        mat-stroked-button
        color="primary"
        (click)="openCommandGroupDialog(element)"
      >
        <mat-icon>send</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Options Column -->
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>Options</th>
    <td mat-cell *matCellDef="let element">
      <button
        class="row-button-margin"
        mat-stroked-button
        color="primary"
        (click)="openEditDeviceDialog()"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button
        class="row-button-margin"
        mat-stroked-button
        color="primary"
        (click)="openAddGroupDialog(element)"
      >
        <mat-icon>queue</mat-icon>
      </button>
      <button
        class="row-button-margin"
        mat-stroked-button
        color="primary"
        (click)="openAddDeviceToGroupDialog(element)"
      >
        <mat-icon>add</mat-icon>
      </button>

      <button
        class="row-button-margin"
        mat-stroked-button
        color="primary"
        (click)="openDeleteGroupDialog(element)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div
        *ngIf="element['subGroups'].length > 1"
        class="example-element-detail"
        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <div class="example-element-description">
          <app-recursive-table
            [group]="element['subGroups']"
          ></app-recursive-table>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="example-detail-row"
  ></tr>
</table>
