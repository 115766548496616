<div class="loading-shade" *ngIf="isLoading">
  <mat-progress-spinner
    style="margin: auto"
    mode="indeterminate"
    *ngIf="isLoading"
  ></mat-progress-spinner>
</div>

<mat-grid-list cols="9" rowHeight="2:1">
  <mat-grid-tile colspan="1">
    <mat-label style="font-size: large; font-weight: 420"> Orders </mat-label>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <button
      mat-raised-button
      color="primary"
      style=""
      (click)="openAddOrderDialog(clientData)"
    >
      Add Order
    </button>
  </mat-grid-tile>
  <mat-grid-tile colspan="7"> </mat-grid-tile>

  <mat-grid-tile colspan="9" rowspan="13">
    <table
      mat-table
      multiTemplateDataRows
      [dataSource]="ordersDataSource"
      *ngIf="ordersDataSource.data.length > 0"
      class="mat-elevation-z8 devices-table"
    >
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID. </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.id | slice: 0:6 }}..
        </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="clientId">
        <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.clientId | slice: 0:6 }}..
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="count">
        <mat-header-cell *matHeaderCellDef> Count </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.count }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="currentCount">
        <mat-header-cell *matHeaderCellDef> Current Count </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.currentCount }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="expiryDate">
        <mat-header-cell *matHeaderCellDef> Expriay Date </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.expiryDate }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumnsClients"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsClients"></mat-row>
    </table>

    <div class="empty pt-5" *ngIf="ordersDataSource.data.length == 0">
      <img src="../../../assets/img/no-data.svg" alt="" />
      <h2 class="text-center">Client Has No Orders !</h2>
    </div>
  </mat-grid-tile>
</mat-grid-list>
