<h1 mat-dialog-title>Delete Group</h1>
<div mat-dialog-content>Are You sure you want to delete Group</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button color="warn" (click)="deleteGroup(groupId)">
    Delete
    <span
      *ngIf="isLoading"
      class="spinner-border spinner-border-sm mr-1"
    ></span>
  </button>
</div>
