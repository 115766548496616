<!-- <mat-toolbar color="primary" class="mat-elevation-z1">
  <button mat-icon-button id="menu">
    <mat-icon>menu</mat-icon>
  </button>
  <span>Dynamic Expanding Nav Drawer Example</span>
</mat-toolbar> -->

<app-nav
  *ngIf="auth.isUserLoggedIn()"
  (sidenavClose)="sidenav.close()"
  #sidenav
></app-nav>

<router-outlet></router-outlet>
