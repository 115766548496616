import { Component, Input, OnInit } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { speedDialFabAnimations } from './speed-dial-fab.animations';
import { saveAs } from 'file-saver';
import { TooltipPosition } from '@angular/material/tooltip';
import { FormatsService } from 'src/app/services/formats.service';

@Component({
  selector: 'app-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations,
})
export class SpeedDialFabComponent implements OnInit {
  @Input() filters: any;
  @Input() reportType: any;

  fabButtons = [
    {
      icon: 'picture_as_pdf',
      action: 'pdf',
    },
    {
      icon: 'article',
      action: 'excel',
    },
  ];
  buttons = [];
  fabTogglerState = 'inactive';
  isLoading: boolean = false;
  constructor(private reportsService: ReportsService, private formatsService: FormatsService) {}

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  ngOnInit(): void {}

  createFileType(e): string {
    let fileType: string = '';
    if (e == 'pdf' || e == 'csv') {
      fileType = `application/${e}`;
    } else if (e == 'jpeg' || e == 'jpg' || e == 'png') {
      fileType = `image/${e}`;
    } else if (e == 'txt') {
      fileType = 'text/plain';
    } else if (e == 'ppt' || e == 'pot' || e == 'pps' || e == 'ppa') {
      fileType = 'application/vnd.ms-powerpoint';
    } else if (e == 'pptx') {
      fileType =
        'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    } else if (e == 'doc' || e == 'dot') {
      fileType = 'application/msword';
    } else if (e == 'docx') {
      fileType =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (e == 'xls' || e == 'xlt' || e == 'xla') {
      fileType = 'application/vnd.ms-excel';
    } else if (e == 'xlsx') {
      fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    return fileType;
  }

  downloadFile(response) {
    console.log({ response });

    let header_content = response.headers.get('content-disposition');
    console.log({ header_content });

    let file = this.reportType + '.xlsx';
    // file = file.substring(1, file.length - 1);
    // let extension = file.split('.')[1].toLowerCase();
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([response.body], {
      type: 'application/vnd.ms-excel',
    });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = file;
    link.click();
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 400);
  }

  onButtonClick(action) {
    console.log(action);
    if(this.filters.excel == false) {
      this.filters.excel = true
    }

    console.log('filters:', this.filters);
    switch (this.reportType) {
      case 'commandReport':
        this.isLoading = true;
        // this.formatsService.formatDate(this.filters);
        this.reportsService.commandReport(this.filters, 'blob').subscribe(
          (res) => {
            console.log('command post res:', res);
            this.downloadFile(res);
            this.isLoading = false;
          },
          (err) => {
            console.log({ err });
            this.isLoading = false;
          }
        );
        break;

      case 'statusReport':
        this.isLoading = true;
        this.reportsService.statusReport(this.filters, 'blob').subscribe(
          (res) => {
            console.log('status post res:', res);
            this.downloadFile(res);
            this.isLoading = false;
          },
          (err) => {
            console.log({ err });
            this.isLoading = false;
          }
        );
        break;

      case 'deviceDetailedReport':
        this.isLoading = true;
        this.reportsService.detailedDeviceReport(this.filters, 'blob').subscribe(
          (res) => {
            console.log('device details post res:', res);
            this.downloadFile(res);
            this.isLoading = false;
          },
          (err) => {
            console.log({ err });
            this.isLoading = false;
          }
        );
        break;
    }
  }
}
