import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { AddClientComponent } from '../modals/add-client/add-client.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
})
export class ClientsComponent implements OnInit {
  isLoading = false;

  displayedColumnsClients: any[] = [
    'No',
    'Name',
    'phone',
    'created',
    'Groups',
    'Devices',
    'Orders',
  ];
  clientsDataSorce = new MatTableDataSource();
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    console.log(this.clientsDataSorce);
    this.getClients();
  }

  getClients() {
    this.isLoading = true;
    this.clientService.getClient().subscribe((res: any) => {
      console.log({ res });
      this.clientsDataSorce.data = res;
      this.isLoading = false;
    });
  }

  openAddClientDialog() {
    this.dialog
      .open(AddClientComponent)
      .afterClosed()
      .subscribe((data) => {
        console.log({ data });
        if (data.result == 'success') this.getClients();
      });
  }
  goToGroups(client) {
    this.router.navigateByUrl('client-groups', { state: { client } });
  }
  openOrders(client) {
    this.router.navigateByUrl('client-order', { state: { client } });
  }
  goToDevices(client) {
    this.router.navigateByUrl('client-devices', { state: { client } });
  }
}
