import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { NavComponent } from './nav/nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DashComponent } from './dash/dash.component';
import { CardComponent } from './card/card.component';
import { ProductSalesChartComponent } from './charts/product-sales-chart/product-sales-chart.component';
import { SalesTrafficChartComponent } from './charts/sales-traffic-chart/sales-traffic-chart.component';
import { AnnualSalesChartComponent } from './charts/annual-sales-chart/annual-sales-chart.component';
import { StoreSessionsChartComponent } from './charts/store-sessions-chart/store-sessions-chart.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { GroupsComponent } from './groups/groups.component';
import { DevicesComponent } from './devices/devices.component';
import { RecursiveTableComponent } from './components/recursive-table/recursive-table.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { AddDeviceComponent } from './modals/add-device/add-device.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditDeviceComponent } from './modals/edit-device/edit-device.component';
import { CommandDeviceComponent } from './modals/command-device/command-device.component';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { environment } from 'src/environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToastrModule } from 'ngx-toastr';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AddDevicesGroupComponent } from './modals/add-devices-group/add-devices-group.component';
import { GroupCommandComponent } from './modals/group-command/group-command.component';
import { HttpClientModule } from '@angular/common/http';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { DeleteGroupComponent } from './dialogs/delete-group/delete-group.component';
import { AddGroupComponent } from './modals/add-group/add-group.component';
import { GroupsAppsComponent } from './components/groups-apps/groups-apps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ClientsComponent } from './admin/clients/clients.component';
import { AddClientComponent } from './admin/modals/add-client/add-client.component';
import { ClientGroupsComponent } from './admin/client-groups/client-groups.component';
import { AdminAddGroupComponent } from './admin/modals/admin-add-group/admin-add-group.component';
import { ClientOrdersComponent } from './admin/client-orders/client-orders.component';
import { AdminAddOrderComponent } from './admin/modals/admin-add-order/admin-add-order.component';
import { ClientOrderDetailsComponent } from './admin/client-order-details/client-order-details.component';
import { ClientDevicesComponent } from './admin/client-devices/client-devices.component';
import { ClientAddDeviceComponent } from './admin/modals/client-add-device/client-add-device.component';
import { ClientDeleteDeviceComponent } from './admin/modals/client-delete-device/client-delete-device.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DeleteAppComponent } from './dialogs/delete-app/delete-app.component';
import { ClientEditDeviceComponent } from './admin/modals/client-edit-device/client-edit-device.component';
import { LogoutComponent } from './dialogs/logout/logout.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { NavService } from './services/nav.service';
import { CommandComponent } from './reports/command/command.component';
import { StatusComponent } from './reports/status/status.component';
import { DeviceDetailsComponent } from './reports/device-details/device-details.component';
import { SpeedDialFabComponent } from './components/speed-dial-fab/speed-dial-fab.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatChipsModule} from '@angular/material/chips';
import { LoaderComponent } from './loader/loader.component';
import { NgApexchartsModule } from 'ng-apexcharts';
//date format modules
import { DatePipe } from '@angular/common'


const config: SocketIoConfig = {
  url: environment.SOCKET_ENDPOINT,
  options: {
    transports: ['websocket'],
    query: { serialNo: userIDGetter() },
  },
};

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

function userIDGetter(): string {
  const helper = new JwtHelperService();
  let accessToken = localStorage.getItem('access_token');
  console.log({ accessToken });
  let decoded = helper.decodeToken(accessToken);
  console.log({ decoded });
  if (decoded) return decoded.userId;
  return 'Dashboard';
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    DashComponent,
    CardComponent,
    ProductSalesChartComponent,
    SalesTrafficChartComponent,
    AnnualSalesChartComponent,
    StoreSessionsChartComponent,
    LoginComponent,
    ResetPasswordComponent,
    GroupsComponent,
    DevicesComponent,
    RecursiveTableComponent,
    AddDeviceComponent,
    EditDeviceComponent,
    CommandDeviceComponent,
    AddDevicesGroupComponent,
    GroupCommandComponent,
    ForgetPasswordComponent,
    DeleteGroupComponent,
    ClientDeleteDeviceComponent,
    AddGroupComponent,
    GroupsAppsComponent,
    ClientsComponent,
    AddClientComponent,
    ClientGroupsComponent,
    AdminAddGroupComponent,
    ClientOrdersComponent,
    AdminAddOrderComponent,
    ClientOrderDetailsComponent,
    ClientDevicesComponent,
    ClientAddDeviceComponent,
    ClientEditDeviceComponent,
    DateAgoPipe,
    DeleteAppComponent,
    LogoutComponent,
    MenuItemComponent,
    CommandComponent,
    StatusComponent,
    DeviceDetailsComponent,
    SpeedDialFabComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ChartsModule,
    LayoutModule,
    SocketIoModule.forRoot(config),
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    ToastrModule.forRoot(),
    NgxFileDropModule,
    HttpClientModule,
    GoogleMapsModule,
    NgxSkeletonLoaderModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatMenuModule,
    MatChipsModule,
    MatButtonToggleModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [environment.domain],
        disallowedRoutes: [
          environment.baseURI + 'account/login',
          environment.baseURI + 'ccount/forgot-password',
          environment.baseURI + 'ccount/reset-password',
        ],
      },
    }),
    NgApexchartsModule,
  ],
  providers: [NavService, DatePipe],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
