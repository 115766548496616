import { Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-client-delete-device',
  templateUrl: './client-delete-device.component.html',
  styleUrls: ['./client-delete-device.component.css'],
})
export class ClientDeleteDeviceComponent implements OnInit {
  isLoading = false;
  constructor(public dialogRef: MatDialogRef<ClientDeleteDeviceComponent>, @Inject(MAT_DIALOG_DATA) public data: {serialNumber: string}, private deviceService: DeviceService, private toastr: ToastrService,
  ) {}

  ngOnInit(): void {}

  onDeleteDevice() {
    console.log("delete" + this.data.serialNumber)
    this.deviceService.deleteDevice(this.data.serialNumber).subscribe(res => {
      this.toastr.success('device deleted Successfully');
      this.dialogRef.close();
      this.deviceService.refresh.emit();
    }, err => {
      console.log(err);
      this.toastr.error('errorr');
    })
  }
}
