<div class="container py-5">
  <app-loader *ngIf="isLoading"></app-loader>
  <form class="devices-top-form px-5" [formGroup]="statusReportForm" (ngSubmit)="onSubmit()">
    <h6 class="my-3 title">Device Status Report</h6>
    <div class="row">
      <div class="col-6 px-5" *ngIf="meanuType == '1'">
        <mat-form-field>
          <mat-label>client Name</mat-label>
          <mat-select [(ngModel)]='clientId' [ngModelOptions]="{standalone: true}" (ngModelChange)="onChange($event)">
            <mat-option *ngFor="let client of clients" [value]="client.id">
              {{ client.clientName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>Device Group Id</mat-label>
          <input matInput formControlName="deviceGroupId" [matAutocomplete]="auto" [value]="groupInput">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let groupId of filteredGroupIds | async" [value]="groupId.id"
              (onSelectionChange)="getName(groupId.name)">
              {{groupId.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>Account Manager</mat-label>
          <mat-select formControlName="accountManagerId">
            <mat-option *ngFor="let manager of accountManagers" [value]="manager.accountManagerID">
              {{ manager.accountManagerName ? manager.accountManagerName : manager.accountManagerID }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>From Date</mat-label>
          <input matInput [matDatepicker]="fromDate" formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>To Date</mat-label>
          <input matInput [matDatepicker]="toDate" formControlName="endDate" />
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex justify-content-end my-4">
      <button type="submit" class="px-4 py-2 mr-2 btn-primary-custome">Submit</button>
      <button type="button" class="px-4 py-2 btn-secondary-custome" (click)="reset()">Clear</button>
    </div>
  </form>
</div>
<div class="container">
  <div class="text-right mb-3">
    <app-speed-dial-fab [filters]="filters" [reportType]="'statusReport'"
      *ngIf="showDownload"></app-speed-dial-fab>
  </div>
  <div *ngFor="let data of sliceTableData; let i = index;">
    <div class="table-responsive mat-elevation-z8 mb-5">
      <p class="p-3 mb-0 fw-semibold">account manager name: {{data.accountManager.accountManagerName}}</p>
      <table class="table">
        <thead class="thead-light">
          <th>Device type (model)</th>
          <th>Device id</th>
          <th>Serial #</th>
          <th>Status</th>
          <th>Network</th>
          <th>Merchant name</th>
          <th>Location name</th>
        </thead>
        <tbody>
          <tr *ngFor="let device of data.devices">
            <td>{{device.deviceType.name}}</td>
            <td>{{device.id}}</td>
            <td>{{device.serialNumber}}</td>
            <td>{{device.deviceStatus == 1 ? 'available' : 'offline'}}</td>
            <td>{{device.networkStatus ? device.networkStatus : '-'}}</td>
            <td>{{device.ref ? device.ref : '-'}}</td>
            <td>{{device.deviceLocation ? device.deviceLocation : '-'}}</td>
          </tr>
        </tbody>
      </table>
      <mat-paginator [pageSizeOptions]="[4, 10, 20]" [length]="tableData[i].devices.length" (page)="onPageChange($event, i)">
      </mat-paginator>
    </div>
  </div>
</div>