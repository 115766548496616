import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-delete-app',
  templateUrl: './delete-app.component.html',
  styleUrls: ['./delete-app.component.css'],
})
export class DeleteAppComponent implements OnInit {
  isLoading: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public appData: any,
    private socketService: SocketService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<DeleteAppComponent>
  ) {}

  deleteApp() {
    if (this.appData.app.appName != 'mdm') {
      this.isLoading = true;
      console.log(this.appData);
      let args = {
        packageName: this.appData.app.packageName,
        // appName: this.appData.app.appName,
      };
      this.socketService
        .sendCommandWithoutTimeOut(
          'uninstallAPK',
          this.appData.serialNumber,
          args
        )
        .then((res: any) => {
          this.isLoading = false;
          console.log({ res });
          if (res.args.data.unInstalled) {
            this.toastr.success('Success');
            this.dialogRef.close();
          } else {
            this.toastr.error('Unable To Uninstall APK');
          }
        });
    } else {
      this.toastr.error('You Cannot Delete This App !');
      this.dialogRef.close();
    }
  }

  ngOnInit(): void {}
}
