import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GroupService } from 'src/app/services/group.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-admin-add-order',
  templateUrl: './admin-add-order.component.html',
  styleUrls: ['./admin-add-order.component.css'],
})
export class AdminAddOrderComponent implements OnInit {
  isLoading: boolean = false;
  addOrderForm = this.formBuilder.group({
    count: ['', Validators.required],
    currentCount: ['', Validators.required],
    expiryDate: ['', Validators.required],
  });
  constructor(
    private formBuilder: FormBuilder,
    private groupService: GroupService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public clientData: any,
    private orderService: OrderService,
    private dialogRef: MatDialogRef<AdminAddOrderComponent>
  ) {
    console.log({ clientData });
  }

  ngOnInit(): void {}

  addOrder() {
    console.log(this.addOrderForm.value);
    if (this.addOrderForm.valid) {
      this.isLoading = true;
      this.orderService
        .addOrder(this.addOrderForm.value, this.clientData)
        .subscribe(
          (res) => {
            console.log({ res });
            this.isLoading = false;
            this.toastr.success('Success');
            this.dialogRef.close(res);
          },
          (err) => {
            this.isLoading = false;
            this.toastr.error(err.message);
          }
        );
    }
  }
}
