import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DeleteAppComponent } from 'src/app/dialogs/delete-app/delete-app.component';
import { Device } from 'src/app/models/device';
import { DeviceService } from 'src/app/services/device.service';
import { GroupService } from 'src/app/services/group.service';
import { SocketService } from 'src/app/services/socket.service';
import { Group } from 'src/app/models/group';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-groups-apps',
  templateUrl: './groups-apps.component.html',
  styleUrls: ['./groups-apps.component.css'],
})
export class GroupsAppsComponent implements OnInit {
  @Input() device: Device;
  constructor(
    private socketService: SocketService,
    public groupService: GroupService,
    public deviceService: DeviceService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  //matchips
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  Device_Group = new FormControl(); // Device Groups Add
  Device_Groups: Group[] = [];
  deviceGroups: string[] = [];
  groups: any[] = [];
  resGroups: any = [];
  filteredDeviceGroups: Observable<string[]>;
  groupsCtrl = new FormControl();

  Device_App = new FormControl(); // Device Groups Add
  Device_Apps: any = [];
  filteredDeviceApps: Observable<any[]>;

  ngOnInit() {
    this.getDeviceGroups();
    this.getAllGroups();
    // console.log('CHIELD', this.device.serialNumber);

    this.installedApps();

    this.filteredDeviceGroups = this.groupsCtrl.valueChanges.pipe(
      startWith(''),
      map((group: string | null) =>
        group ? this._filter(group) : this.groups.slice()
      )
    );
  }

  installedApps() {
    this.socketService
      .getDeviceInfo(this.device.serialNumber)
      .then((deviceInfo) => {
        if (deviceInfo.event != 'error')
          this.Device_Apps = deviceInfo.args.data.installedPackages;
        this.Device_Apps = this.Device_Apps.filter((app) => {
          if (app.appType != 'SystemApp') return app;
        });
      })
      .catch((e) => {
        console.error({ e });
      });
  }

  removeAPK(app: any) {
    let serialNumber = this.device.serialNumber;
    this.dialog
      .open(DeleteAppComponent, { data: { app, serialNumber } })
      .afterClosed()
      .subscribe(() => {
        this.installedApps();
      });
  }

  getDeviceGroups() {
    this.groupService.getDeviceGroup(this.device.serialNumber).subscribe(
      (res: any) => {
        console.log('groups res', res.groups);
        this.resGroups = res.groups;

        res.groups.forEach((group) => {
          this.deviceGroups.push(group);
        });

        // console.log(this.deviceGroups, "deviceGroups")
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAllGroups() {
    this.groupService.getGroups().subscribe((res) => {
      //console.log(typeof(res), res)
      res.forEach((group) => {
        this.groups.push({ name: group.name, id: group.id });
      });

      // console.log(this.groups)
    });
  }

  RemoveDeviceFromGroup(group: any) {
    //console.log(group, this.device.serialNumber)
    this.groupService.removeDeviceFromGroup(this.device.serialNumber).subscribe(
      (res) => {
        console.log(res);
        this.toastr.success(`device removed from ${group.groupName}`);
        this.deviceGroups = [];
        this.getDeviceGroups();
      },
      (err) => {
        this.toastr.error(err.statusText);
      }
    );

    // var index = this.deviceGroups.indexOf(group);
    // this.deviceGroups.splice(index, 1);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.groups.filter(
      (group) => group.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  addDeviceToGroup(value: string, name: string): void {
    // console.log('typing..', value, this.device.serialNumber)
    const body = {
      groupId: value,
      serials: [this.device.serialNumber],
    };

    this.groupService.addDeviceToGroup(body).subscribe(
      (res) => {
        //console.log(res)
        this.deviceGroups = [];
        this.toastr.success(`device added to ${name} group`);
        this.getDeviceGroups();
      },
      (err) => {
        //console.log(err)
        if (err.ok == false) {
          this.toastr.warning(err.error.message);
        }
      }
    );
  }

  private _filterApps(value: string): Group[] {
    const filterValue = value.toLowerCase();

    return this.Device_Groups.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
