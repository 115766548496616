import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SocketResponse, SocketService } from 'src/app/services/socket.service';

import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith, takeWhile } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Command, Device, DeviceInfo } from 'src/app/models/device';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { DeviceService } from 'src/app/services/device.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { interval } from 'rxjs';
import { take, mergeMap } from 'rxjs/operators';
@Component({
  selector: 'app-command-device',
  templateUrl: './command-device.component.html',
  styleUrls: ['./command-device.component.css'],
})
export class CommandDeviceComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  printing: boolean = false;
  installing: boolean = false;
  sending: boolean = false;
  map: boolean = true;
  center: google.maps.LatLngLiteral;
  markerOptions: { animation: google.maps.Animation.BOUNCE };
  deviceLat: any;
  deviceLng: any;
  deviceLocathion: any;
  installAPKSubscription: Subscription;
  installAPKBtn: string = 'install';
  isInstalling: boolean = false;
  device: Device;
  displayedColumnsLogs: any[] = [
    'id',
    'commandData',
    'commandStatus',
    'sendingDateTime',
    'userName',
  ];
  dataSource = new MatTableDataSource();
  SelectedGroups: string[] = [];
  command_status: boolean = false;
  Device_Group = new FormControl(); // Device Groups Add
  Device_Groups: string[] = ['Altakamul', 'Another', 'Another'];
  filteredDeviceGroups: Observable<string[]>;
  Device_Apps: string[] = ['App', 'AnotherApp', 'AnotherApp'];
  printingText: string;
  deviceInfo: DeviceInfo = {
    osVersion: 0,
    model: 'N\\A',
    serialNumber: 'N\\A',
    bluetoothStatus: 'N\\A',
    nfcStatus: 'N\\A',
    dataStatus: 'N\\A',
    locationStatus: 'N\\A',
    baterry: 'N\\A',
    wifiStatus: 'N\\A',
    status: 'Offline',
  };
  apkUrl: string = '';
  isDeviceOnline: boolean = false;
  imageLoaded: boolean = false;
  Commands: Command[] = [
    {
      id: 1,
      name: 'Wifi',
      command: 'toggleWifi',
      icon: 'wifi',
      state: false,
    },
    {
      id: 2,
      name: 'Data',
      command: 'toggleDataConnection',
      icon: 'signal_cellular_alt',
      state: false,
    },
    {
      id: 3,
      command: 'toggleBluetooth',
      name: 'Bluetooth',
      icon: 'bluetooth',
      state: false,
    },
    { id: 4, name: 'NFC', command: 'toggleNFC', icon: 'nfc', state: false },
    {
      id: 5,
      name: 'Reboot',
      icon: 'restart_alt',
      state: false,
      command: 'rebootDevice',
    },
    {
      id: 6,
      name: 'PowerOff',
      icon: 'power_off',
      command: 'powerOff',
      state: false,
    },
    {
      id: 7,
      name: 'Location',
      command: 'getLocation',
      icon: 'location_on',
      state: false,
    },
    {
      id: 8,
      name: 'Test Printer',
      command: 'printText',
      icon: 'print',
      state: false,
      text: 'testPrint',
    },
  ];

  apps: any[] = [
    { name: 'ALtakamul' },
    { name: 'Apps' },
    { name: 'Another App' },
  ];
  deviceImageUrl: string = 'Gafar';
  sub: Subscription;
  Filter: FormGroup;
  logData: any = [];
  loading: boolean = false;
  constructor(
    private socketService: SocketService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { device: Device },
    public fileUpload: FileUploadService,
    public deviceService: DeviceService,
    private fb: FormBuilder
  ) {}

  @ViewChild('input', { static: true }) input: ElementRef;

  ngOnInit() {
    this.deviceImageUrl =
      this.data.device.type != undefined
        ? this.data.device.type.imageUrl
        : this.data.device.deviceType.imageUrl;
    //console.log(this.data.device.deviceType, 'TYPE');
    this.device = this.data.device;

    this.getDeviceInfoAsync();

    this.filteredDeviceGroups = this.Device_Group.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterGroups(value))
    );

    this.getDeviceLog();

    this.Filter = this.fb.group({
      date: this.fb.group({
        startDate: [''],
        endDate: [''],
      }),
      commandData: [''],
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onFilterLogs() {
    let commandValue = this.Filter.get('commandData').value;
    let startDate = this.Filter.get('date.startDate').value;
    let endDate = this.Filter.get('date.endDate').value;

    if (
      (startDate == '' || startDate == null) &&
      (commandValue == '' || commandValue == null)
    ) {
      return (this.dataSource.data = this.logData);
    } else if (startDate == '' || startDate == null) {
      //filter by command
      let data = this.logData.filter((log) => {
        if (log.commandData !== null) {
          return log.commandData.includes(commandValue);
        }
      });

      this.dataSource.data = data;
      this.Filter.reset();
    } else if (commandValue == '' || commandValue == null) {
      //filter by date
      let data = this.logData.filter((log) => {
        let date = new Date(log.dateTime).getTime();
        return date >= startDate.getTime() && date <= endDate.getTime();
      });

      this.dataSource.data = data;
      this.Filter.reset();
    } else {
      //filter by date and command
      let data = this.logData.filter((log) => {
        let date = new Date(log.dateTime).getTime();
        if (log.commandData !== null) {
          return (
            date >= startDate &&
            date <= endDate &&
            log.commandData.includes(commandValue)
          );
        }
      });

      this.dataSource.data = data;
      this.Filter.reset();
    }
  }

  onClearFilter() {
    //console.log('clear filter')
    this.dataSource.data = this.logData;
  }

  onImageLoad() {
    this.imageLoaded = true;
  }

  getDeviceInfoAsync() {
    const _interval = interval(1000);
    const isOnline = this.deviceInfo.status == 'Online';
    this.sub = _interval
      .pipe(mergeMap(async () => this.getDeviceInfo()))
      .pipe(takeWhile(() => this.deviceInfo.status == 'Offline'))
      .pipe(take(10))
      .subscribe((data) => {
        console.log({ data });
      });
  }

  private _filterGroups(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.Device_Groups.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  location() {
    // this.map = !this.map;
    // var myLatlng = new google.maps.LatLng(parseFloat(this.deviceLat),parseFloat(this.deviceLng));
    this.center = {
      lat: parseFloat(this.deviceLat),
      lng: parseFloat(this.deviceLng),
    };
    // navigator.geolocation.getCurrentPosition((position) => {

    // });
  }

  updateDeviceInfo(deviceInfo) {
    let info = deviceInfo.args.data;
    this.deviceInfo.baterry = info.deviceInfo.Battery_level;
    this.deviceInfo.serialNumber = info.deviceInfo.Serial_number;
    this.deviceInfo.model = info.deviceInfo.Model;
    this.deviceInfo.osVersion = info.deviceInfo.Android_version;
    this.deviceInfo.status = 'Online';
    info.bluetoothStatus == 'ON'
      ? (this.Commands[2].state = true)
      : (this.Commands[2].state = false);
    info.nfcStatus == 'enabled'
      ? (this.Commands[3].state = true)
      : (this.Commands[3].state = false);

    info.wifiStatus == 'wifi state enabled'
      ? (this.Commands[0].state = true)
      : (this.Commands[0].state = false);

    info.dataStatus == 'DataStatus: true'
      ? (this.Commands[1].state = true)
      : (this.Commands[1].state = false);
  }

  getDeviceInfo() {
    this.socketService
      .getDeviceInfo(this.device.serialNumber)
      .then((deviceInfo) => {
        console.log({ deviceInfo });
        console.log(deviceInfo.args.data, 'args');
        if (deviceInfo.event != 'error') {
          this.isDeviceOnline = true;
          this.updateDeviceInfo(deviceInfo);
          var lng = deviceInfo['args'].data.locationStatus.split(3);
          console.log('GAFAR', lng);
          var d = JSON.parse(deviceInfo['args'].data.locationStatus);
          var deviceCordinates = d.mangerCoordinates.split([','], [2]);
          console.log({ deviceCordinates });
          this.deviceLat = deviceCordinates[0];
          this.deviceLng = deviceCordinates[1];
          this.deviceLocathion = [];
          this.deviceLocathion.push(this.deviceLat);
          this.deviceLocathion.push(this.deviceLat);
          console.log('GAFARYAHYA', this.deviceLat);
          console.log('GAFARYAHYA', this.deviceLng);
        }
      })
      .catch((e) => {
        console.error({ e });
      });
  }

  printText() {
    this.printing = true;
    let printText = this.printingText;
    console.log({ printText });

    this.socketService
      .sendCommand('printText', [this.device.serialNumber], { printText })
      .then(
        (data: any) => {
          console.log(data);
          if (data.event != 'error') {
            // command.state = !command.state;
            this.toastr.success('Success');
          } else {
            this.toastr.error(data.args.message);
          }
          this.printing = false;
        },
        (error) => {
          this.printing = false;
        }
      );
  }

  installApk() {
    let expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);

    if (!this.isDeviceOnline) return this.toastr.error('Device is Offline');

    if (this.apkUrl.match(regex)) {
      this.installing = true;
      let url = this.apkUrl;
      console.log({ url });
      this.installAPKSubscription = this.socketService
        .sendCommandObs('installAPK', [this.device.serialNumber], { url })
        .subscribe(
          (res: SocketResponse) => {
            if (res.args.data.installStatus.statusText == 'STATUS_RUNNING') {
              this.isInstalling = true;
              this.installAPKBtn = 'running';
            } else if (
              res.args.data.installStatus.statusText == 'STATUS_FAILED'
            ) {
              this.isInstalling = false;
              this.installAPKBtn = 'try again!';
              this.installAPKSubscription.unsubscribe();
              this.toastr.error(res.args.data.installStatus.reasonText);
              this.installing = false;
            } else if (
              res.args.data.installStatus.statusText == 'STATUS_SUCCESSFUL'
            ) {
              this.isInstalling = false;
              this.installing = false;
              this.toastr.success('installed Succesfully');
              this.installAPKBtn = 'install';
              this.apkUrl = '';
              this.installAPKSubscription.unsubscribe();
            } else if (
              res.args.data.installStatus.statusText == 'STATUS_PENDING'
            ) {
              this.isInstalling = true;
              this.installAPKBtn = 'pending';
            }
          },
          (error) => {
            this.installing = false;
          }
        );
    } else {
      this.toastr.error('Please Enter a Valid URL');
    }
  }

  sendCommand(command) {
    this.sending = true;
    let args: any = {};
    this.map = true;
    if (command.id == 8) args.printText = command.text;
    this.socketService
      .sendCommand(command.command, [this.device.serialNumber], args)
      .then(
        (data: any) => {
          if (data.event != 'error') {
            data.args.data != undefined ? this.updateDeviceInfo(data) : null;
            if (command.id == 7) {
              this.map = !this.map;
              this.location();
            }
            this.toastr.success('Success');
            this.socketService.updateDeviceLog(data.args.ray_id, 1).subscribe(
              (res) => {
                this.getDeviceLog();
              },
              (err) => {
                console.log('failed to update status');
              }
            );
          } else {
            this.toastr.error(data.args.message);
          }
          this.sending = false;
        },
        (error) => {
          this.socketService
            .addDeviceLog('Request Timeout', [
              this.device.serialNumber,
              this.device.id,
            ])
            .subscribe(
              (res) => {
                this.getDeviceLog();
              },
              (err) => {
                console.log(err);
              }
            );

          this.toastr.error(error);
          this.sending = false;
        }
      );
  }

  public files: NgxFileDropEntry[] = [];

  dropped(files: NgxFileDropEntry[]) {
    this.isLoading = true;
    this.files = files;
    if (files.length > 1) {
      this.toastr.error('Cannot add more than 1 File at a time.');
    } else {
      for (const droppedFile of files) {
        // Is it a file?
        if (
          droppedFile.fileEntry.isFile &&
          this.isFileAllowed(droppedFile.fileEntry.name)
        ) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            this.isLoading = true;
            // Here you can access the real file
            console.log(droppedFile.relativePath, file);

            // You could upload it like this:
            const formData = new FormData();
            formData.append('apk', file, droppedFile.relativePath);

            this.deviceService.uploadAPK(formData).subscribe((res: any) => {
              console.log({ res });
              this.isLoading = false;
              if (res.isSucceeded) {
                this.toastr.success('Uploaded Successfully');
                this.apkUrl = environment.baseAPKUrl + res.apkFileURL;
                this.installApk();
              } else {
                this.toastr.error('Something Went Wrong');
              }
            });
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          this.toastr.error(
            "Only files in '.apk', '.abb'  format are accepted and directories are not allowed."
          );
          // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // console.log(droppedFile.relativePath, fileEntry);
        }
      }
    }
  }

  fileOver(event) {
    this.isLoading = true;
    console.log(event);
  }

  fileLeave(event) {
    console.log(event);
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.apk', '.aab'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  getDeviceLog() {
    var body = {
      serialNumber: this.device.serialNumber,
    };

    this.loading = true;
    this.deviceService.getDeviceLog(body).subscribe(
      (res: any) => {
        //console.log('LOGS', res);

        this.dataSource.data = res;
        this.logData = res;
        this.loading = false;
      },
      (error) => {
        this.toastr.error(error.message);
        this.loading = false;
      }
    );
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
