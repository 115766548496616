import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Group } from '../models/group';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  header: HttpHeaders = new HttpHeaders();
  clientId: string;
  apiKey: string;
  rawGroup: any;
  groups: Group[] = [];
  constructor(private http: HttpClient) {
    this.apiKey = localStorage.getItem('apiKey');
    this.clientId = localStorage.getItem('clientID');
    this.header = this.header.append('x-api-key', this.apiKey);
  }

  getDeviceGroup(deviceSerial) {
    return this.http.get(
      environment.baseURI + `groups/device/${deviceSerial}`,
      {
        headers: this.header,
      }
    );
  }

  getGroups(id?: string) {
    let clientId = id ? id : this.clientId;
    return this.http
      .get(environment.baseURI + `groups/${clientId}`, {
        headers: this.header,
      })
      .pipe(
        map((res) => {
          this.groups = [];
          this.rawGroup = res;
          this.rawGroup.forEach((group: any) => {
            if (group.group.rootId == null) {
              group.group.subGroups = [];
              group.group.devices = group.devices;
              this.groups.push(group.group);
            } else {
              group.group.devices = group.devices;
              group.group.subGroups = [];
            }
          });
          this.formatGroups();
          return this.groups;
        })
      );
  }

  deviceGroupIdList(id?: string) {
    let groupId: any;
    let groupIdList: any[] = [];

    let client = id ? id : this.clientId;
    console.log(client)

      this.getGroups(client).subscribe(
        res => {
          groupId = res;
          groupId.forEach(group => {
            let parentGroup;
            let subGroup = [];
            parentGroup = {id: group.id, name: group.name};
            if (group.subGroups.length > 0) {
              group.subGroups.forEach(subgroup => {
                subGroup.push({id: subgroup.id, name: subgroup.name});
              });
            };
            
            groupIdList.push(parentGroup, ...subGroup);
          });
        } 
      )

      return groupIdList;
  }



  getGroupsByClientID(clientID) {
    return this.http
      .get(environment.baseURI + `groups/${clientID}`, {
        headers: this.header,
      })
      .pipe(
        map((res) => {
          this.groups = [];
          this.rawGroup = res;
          this.rawGroup.forEach((group: any) => {
            if (group.group.rootId == null) {
              group.group.subGroups = [];
              group.group.devices = group.devices;
              this.groups.push(group.group);
            } else {
              group.group.devices = group.devices;
              group.group.subGroups = [];
            }
          });
          this.formatGroups();
          return res;
        })
      );
  }

  findGroupByID(id) {
    let idx = this.rawGroup.findIndex((group) => {
      if (group.group.id == id) return group;
    });
    return this.rawGroup[idx];
  }
  
  addGroup(data, root, client?) {
    data.clientId = this.clientId;
    if (client) data.clientId = client.id;
    console.log({ root });
    console.log({ client });

    if (root)
      return this.http.post(environment.baseURI + `groups/${root.id}`, data, {
        headers: this.header,
      });
    return this.http.post(environment.baseURI + 'groups', data, {
      headers: this.header,
    });
  }
  
  deleteGroup(id) {
    console.log(id)
    return this.http.delete(environment.baseURI + 'Groups/' + id, {
      headers: this.header,
    });
  }

  formatGroups() {
    this.rawGroup.forEach((group) => {
      if (group.group.rootId) {
        let rootGroup = this.findGroupByID(group.group.rootId);
        if (rootGroup) {
          let idx = this.groups.findIndex((group) => {
            if (group.id == rootGroup.group.id) return group;
          });
          if (idx >= 0) this.groups[idx].subGroups.push(group.group);
        }
      }
    });
  }

  addDeviceToGroup(data) {
    console.log('APKI KEY', this.header);

    return this.http.post(environment.baseURI + 'groups/device', data, {
      headers: this.header,
    });
  }

  removeDeviceFromGroup(serialNumber: string) {
    return this.http.delete(environment.baseURI + `Devices/DeleteDeviceGroup/${serialNumber}`, {
      headers: this.header,
    });
  }

  getGroupsLogs(groupID) {
    return this.http.get(environment.baseURI + `groupcommandLog/${groupID}`, {
      headers: this.header,
    });
  }
}
