<h2 mat-dialog-title>Add Client</h2>

<mat-dialog-content
  style="width: 130vh"
  class="add-device-dialog mat-typography"
  minWidth="1920"
>
  <mat-grid-list cols="9" rowHeight="1:5.5">
    <mat-grid-tile colspan="6">
      <form
        class="example-form"
        [formGroup]="addClientForm"
        (ngSubmit)="onSubmit()"
      >
        <mat-form-field class="example-full-width">
          <mat-label>Client Name</mat-label>
          <input matInput formControlName="clientName" />
        </mat-form-field>
        <br />
        <br />

        <mat-form-field class="example-full-width">
          <mat-label>Username</mat-label>
          <input matInput formControlName="username" />
        </mat-form-field>
        <br />
        <br />

        <mat-form-field class="example-full-width">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" />
        </mat-form-field>
        <br />
        <br />

        <mat-form-field class="example-full-width">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="phoneNumber" />
        </mat-form-field>
        <br />
        <br />

        <mat-form-field class="example-full-width">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
        <br />
        <br />

        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Type</mat-label>
          <mat-select placeholder="Type" formControlName="role">
            <mat-option
              *ngFor="let type of typeList | keyvalue"
              [value]="toNumber(type.key)"
            >
              {{ type.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <br />
        <mat-dialog-actions align="end">
          <button mat-button mat-dialog-close>Cancel</button>
          <button mat-button cdkFocusInitial type="submit">
            Add
            <span
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-1"
            ></span>
          </button>
        </mat-dialog-actions>
      </form>
    </mat-grid-tile>
    <mat-grid-tile colspan="3">
      <mat-grid-list cols="3" rowHeight="1:1" style="width: 100%; height: 100%">
        <mat-grid-tile colspan="3">
          <mat-label> Logo </mat-label>
        </mat-grid-tile>
        <mat-grid-tile colspan="3" rowspan="3">
          <ngx-skeleton-loader
            style="width: 100%; height: 100%"
            *ngIf="!imageLoaded"
            [theme]="{
              width: '100%',
              height: '100%',
              margin: '5%',
              'text-align': 'center',
              display: 'block',
              height: '100%'
            }"
          ></ngx-skeleton-loader>
          <img
            mat-card-image
            (load)="onImageLoad()"
            [src]=""
            *ngIf="imageLoaded"
            style="width: 15vw"
            alt="Client Logo"
          />
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
          <button mat-raised-button color="primary">Brwose</button>
        </mat-grid-tile>
      </mat-grid-list>

      <br />
    </mat-grid-tile>
  </mat-grid-list>
</mat-dialog-content>
