import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-admin-add-group',
  templateUrl: './admin-add-group.component.html',
  styleUrls: ['./admin-add-group.component.css'],
})
export class AdminAddGroupComponent implements OnInit {
  isLoading: boolean = false;
  addGroupForm = this.formBuilder.group({
    groupName: ['', Validators.required],
  });
  constructor(
    private formBuilder: FormBuilder,
    private groupService: GroupService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public groupData: any
  ) {
    console.log({ groupData });
  }

  ngOnInit(): void {}

  addGroup() {
    console.log(this.addGroupForm.value.groupName);
    if (this.addGroupForm.valid) {
      this.isLoading = true;
      this.groupService
        .addGroup(this.addGroupForm.value, this.groupData)
        .subscribe(
          (res) => {
            this.toastr.success('Success');
            console.log(res);
            this.isLoading = false;
            this.dialog.closeAll();
          },
          (error) => {
            this.isLoading = false;
            // this.toastr.error(error.args.message);
            console.log(error);
          }
        );
    }
  }
}
