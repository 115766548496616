import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  isLoading: Boolean = false;
  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      password: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  onSubmit() {
    if (this.resetForm.invalid) return;
    this.isLoading = true;
    this.auth.resetPassword(this.f.password.value, this.f.code.value).subscribe(
      (res) => {
        this.isLoading = false;
        console.log({ res });
        this.toaster.success('Code Sent Successfully Please Check Your Mail');
        setTimeout(() => {
          this.router.navigateByUrl('reset-password');
        }, 1000);
      },
      (err) => {
        this.isLoading = false;
        console.error({ err });
      }
    );
  }
}
