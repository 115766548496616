<div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" color="primary" style="text-align: center">
    <mat-card-header>
      <mat-card-title>Reset Password</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="New Password"
            formControlName="password"
          />
        </mat-form-field>
      </mat-card-content>

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input
            matInput
            placeholder="Confirmation Code"
            formControlName="code"
          />
        </mat-form-field>
      </mat-card-content>

      <button mat-stroked-button color="primary" class="btn-block">
        Update Password
        <span
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
      </button>
    </form>
  </mat-card>
</div>
