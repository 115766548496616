import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

const ELEMENT_DATA: any[] = [
  {
    ID: 1,
    SerialNo: '19-03-2021',
    Type: 10,
    Status: 'Valid',
    Group: 10,
    SendCommand: 'Commands',
    Option: '',
  },
];

@Component({
  selector: 'app-client-order-details',
  templateUrl: './client-order-details.component.html',
  styleUrls: ['./client-order-details.component.css'],
})
export class ClientOrderDetailsComponent implements OnInit {
  isLoading: boolean = false;
  typesLists = ['MP4', 'MP4+', 'MP3'];
  GroupList = [
    'Reset Filter',
    'Altkamul',
    'Another',
    'AnotherOne',
    'AnotherOneTow',
  ];
  statusList = ['', 'True', 'False'];
  displayedColumnsordersDevicesDataSource: any[] = [
    'ID',
    'SerialNo',
    'Type',
    'Status',
    'Group',
    'SendCommand',
    'Option',
  ];
  ordersDevicesDataSource = new MatTableDataSource(ELEMENT_DATA);
  constructor() {}

  ngOnInit(): void {}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
