import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { AddDeviceComponent } from '../modals/add-device/add-device.component';
import { CommandDeviceComponent } from '../modals/command-device/command-device.component';
import { EditDeviceComponent } from '../modals/edit-device/edit-device.component';
import { Device, DeviceType } from '../models/device';
import { AuthService } from '../services/auth.service';
import { DeviceService } from '../services/device.service';
import { SocketService } from '../services/socket.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css'],
})
export class DevicesComponent implements OnInit {
  isLoading = false;
  devicesData: any = [];
  displayedColumns: string[] = [
    'number',
    'serialNumber',
    'deviceType',
    // 'group',
    'status',
    'commands',
    'options',
  ];
  dataSource = new MatTableDataSource([]);

  constructor(
    private socketService: SocketService,
    private dialog: MatDialog,
    private deviceService: DeviceService,
    private toastr: ToastrService,
    private router: Router,
    private auth: AuthService
  ) {}

  typesLists = [''];
  GroupList = [
    'Reset Filter',
    'Altkamul',
    'Another',
    'AnotherOne',
    'AnotherOneTow',
  ];
  
  statusList = ['', 'True', 'False'];

  ngOnInit() {
    this.getDevices();
  }

  async toggle() {
    let data = await this.socketService.getDeviceInfo('123').catch((e) => {
      console.log({ e });
    });
    console.log({ data });
  }

  obs() {
    console.log('obs...');
    this.socketService.getDeviceInfoObs('123').subscribe(
      (res: any) => {
        console.log({ res });
        if (res.event != 'error')
          if (res.args.data.wifi == 'waiting') {
            setTimeout(() => {
              this.obs();
            }, 2000);
            console.log('waiting..');
          } else {
            console.log(res.event);
          }
      },
      (err) => {
        console.error(err);
      }
    );
  }

  openEditDeviceDialog(device: Device): void {
    console.log('Edit Dialog Device', device);
    const EditDialogRef = this.dialog.open(EditDeviceComponent, { data: { device }, width: '800px' });
    EditDialogRef.afterClosed().subscribe(result => {
      // console.log('dilog result',result)
      this.ngOnInit();
    })
    
  }

  openAddDeviceDialog(): void {
    this.dialog
      .open(AddDeviceComponent, { width: '800px' })
      .afterClosed()
      .subscribe(() => {
        this.getDevices();
      });
  }

  openCommandDeviceDialog(device: Device) {
    this.dialog.open(CommandDeviceComponent, { data: { device }, width: '800px' });
  }

  async info() {
    console.log('FIRSTER');
    this.isLoading = true;
    let data = await this.socketService.sendCommand('toggleWifi', ['123']);
    console.log({ data });
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterDrop(devicetype: string) {
    // const filterValue = type;
    console.log('Gafr', devicetype);
    if (devicetype == '') {
      this.dataSource.filter = '';
    }
    console.log(this.dataSource.filteredData[0].deviceType.name);
    this.dataSource.filter = devicetype.trim().toLowerCase();

    //   .trim()
    //   .toLocaleLowerCase()
    //   .indexOf(filterValue.trim().toLocaleLowerCase()) >= 0;
  }

  filterDrop(devicetype: string) {
    if (devicetype == '') {
      console.log('reset filter')
      this.dataSource.data = this.devicesData;
      return;
    }
    let filteredData = this.devicesData.filter(
      (device) => device.type.name == devicetype
    );
    this.dataSource.data = filteredData;
  }

  getDevices() {
    this.isLoading = true;
    this.deviceService.getDeviceTypes().subscribe(
      (res: any) => {
        for (let index = 0; index < res.length; index++) {
          this.typesLists[index + 1] = res[index].name;
        }
        this.deviceService.getDevices().subscribe((res: any) => {
          res.forEach((element) => {
            if (element.deviceTypeId != null) {
              element.deviceType = this.deviceService.getTypeByID(
                element.deviceTypeId
              ).name;
              element.type = this.deviceService.getTypeByID(
                element.deviceTypeId
              );
            } else {
              element.deviceType = null;
            }
          });
          console.log({ res });
          this.devicesData = res;
          this.dataSource.data = res;

          this.isLoading = false;
        });
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error(`${err.statusText}`);
        this.router.navigateByUrl('/login').then(() => {
          this.auth.clearUserData();
          location.reload();
        });
      }
    );
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
