<!-- <div class="loading-shade" *ngIf="isLoading">
  <mat-progress-spinner
    style="margin: auto"
    mode="indeterminate"
    *ngIf="isLoading"
  ></mat-progress-spinner>
</div> -->
<app-loader *ngIf="isLoading"></app-loader>
<mat-form-field style="width: 95%; margin: auto; margin-top: 20px; left: 2%">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input />
</mat-form-field>
<button
  mat-raised-button
  color="primary"
  style="left: 2%"
  (click)="openAddGroupDialog()"
>
  Add Group
</button>

<table
  mat-table
  multiTemplateDataRows
  [dataSource]="dataSource"
  *ngIf="dataSource.data.length > 0"
  class="mat-elevation-z8 devices-table"
>
  <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->
  <ng-container
    matColumnDef="{{ getColomn(column) }}"
    *ngFor="let column of displayedColumns"
  >
    <th mat-header-cell *matHeaderCellDef>{{ column | titlecase }}</th>
    <td mat-cell *matCellDef="let element; let i = dataIndex;">
      <div *ngIf="column == 'count'">{{ getDevicesCount(element) }}</div>
      {{ column == "No." ? i : element[column] }}
    </td>
  </ng-container>

  <!-- Options Column -->

  <!-- Commands Column -->
  <ng-container matColumnDef="commands">
    <th mat-header-cell *matHeaderCellDef>Commands</th>
    <td mat-cell *matCellDef="let element">
      <button
        class="row-button-margin"
        mat-stroked-button
        color="primary"
        (click)="openCommandGroupDialog(element)"
      >
        <mat-icon>send</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div
        *ngIf="element['subGroups'].length >= 1"
        class="example-element-detail"
        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
      >
        <div class="example-element-description">
          <app-recursive-table
            [group]="element['subGroups']"
          ></app-recursive-table>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef>
      <mat-label style="margin: 10%">Edit</mat-label>
      <mat-label style="margin: 10%">Add</mat-label>
      <mat-label style="margin: 10%">Delete</mat-label>
    </th>
    <td mat-cell *matCellDef="let element">
      <button
        (click)="openEditDeviceDialog()"
        mat-icon-button
        color="primary"
        aria-label="Edit Group"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <!-- <button
      class="row-button-margin"
      mat-stroked-button
      color="primary"
      (click)="openAddGroupDialog(element)"
    >
      <mat-icon>queue</mat-icon>
    </button> -->
      <button
        (click)="openAddDeviceToGroupDialog(element)"
        mat-icon-button
        color="primary"
        aria-label="Add A Device To Group"
      >
        <mat-icon>add</mat-icon>
      </button>

      <button
        (click)="openDeleteGroupDialog(element)"
        mat-icon-button
        color="primary"
        aria-label="Add A Device To Group"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="example-detail-row"
  ></tr>
</table>

<div
  class="empty pt-5"
  *ngIf="!isLoading && dataSource.data.length == 0"
  style="width: 25%; margin: auto"
>
  <img src="../../../assets/img/no-data.svg" class="img-fluid" alt="" />
  <h3 class="text-center">Client Has No Groups !</h3>
</div>
