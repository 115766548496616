<h2 mat-dialog-title>Add Devices To Group</h2>
<mat-dialog-content
  class="add-device-dialog mat-typography"
  minWidth="1920"
>
  <div class="loading-shade" *ngIf="isLoading">
    <mat-progress-spinner
      style="margin: auto"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
  <mat-tab-group dynamicHeight>
    <!-- Start add Devices Dialog -->
    <mat-tab
      label="Add Devices"
    >
    <div class="container-fluid">
      <form>
        <div class="row">
          <div class="col-12 col-lg-6">
            <mat-form-field class="serach-device-form-field">
              <mat-label>Serial No.</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event)"
                placeholder="Search Devices"
                #input
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field class="serach-device-form-field">
              <mat-label>Type</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event)"
                placeholder="Type Devices"
                #input
                [formControl]="myControl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
              <mat-form-field class="serach-device-form-field">
                <mat-label>Status</mat-label>
                <input
                  matInput
                  (keyup)="applyFilter($event)"
                  placeholder="Search Devices"
                  #input
                  [formControl]="myControl"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field class="serach-device-form-field">
              <mat-label>Groups</mat-label>
              <input
                matInput
                (keyup)="applyFilter($event)"
                placeholder="Search Devices"
                #input
                [formControl]="myControl"
                [matAutocomplete]="auto"
              />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptions | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-table #table [dataSource]="dataSource" class="devices-table">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Position Column -->
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.id | slice: 0:5 }}...
              </mat-cell>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="serialNumber">
              <mat-header-cell *matHeaderCellDef> S/N </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.serialNumber | slice: 0:5 }}...
              </mat-cell>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="deviceType">
              <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.deviceType == null ? "null" : element.deviceType }}
              </mat-cell>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> status </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.status }}
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="selection.toggle(row)"
            >
            </mat-row>
          </mat-table>
          <mat-paginator
              #paginator
              [pageSize]="1"
              [pageSizeOptions]="[5, 10, 20]"
            >
            </mat-paginator>
            <button
              mat-raised-button
              class="add-devices-to-group-button"
              cdkFocusInitial
              color="primary"
              (click)="transferSelectedRows()"
            >
              Add Devices To Group <mat-icon> trending_flat</mat-icon>
            </button>
        </div>
        <div class="col-12 col-lg-6">
          <mat-label class="selected-device-table-lable">
            Selected Devices (Those Devices Will Be Added To The Group)
          </mat-label>
          <div class="example-container mat-elevation-z8">
            <mat-table #table [dataSource]="checkedDataSource">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? masterCheckedToggle() : null"
                    [checked]="
                      checkedSelection.hasValue() && isAllCheckedSelected()
                    "
                    [indeterminate]="
                      checkedSelection.hasValue() && !isAllCheckedSelected()
                    "
                  >
                  </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? checkedSelection.toggle(row) : null"
                    [checked]="checkedSelection.isSelected(row)"
                  >
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <!-- Position Column -->
              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.id }}
                </mat-cell>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="serialNumber">
                <mat-header-cell *matHeaderCellDef>
                  Serial Number
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.serialNumber }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumnsSelected"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumnsSelected"
                (click)="checkedSelection.toggle(row)"
              >
              </mat-row>
            </mat-table>

            <mat-paginator
              #checkedpaginator
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 20]"
            >
            </mat-paginator>
          </div>
          <div class="bottom-add-devices-to-group py-5">
            <button
              mat-raised-button
              cdkFocusInitial
              class="bottom-buttons-right-side"
              color="danger"
              (click)="removeSelectedRows()"
            >
              Cancel
            </button>
            <button
              mat-raised-button
              cdkFocusInitial
              class="bottom-buttons-right-side"
              color="primary"
              (click)="addDevicesToGroup()"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    </mat-tab>

    <!-- Ends dd Devices By Search Dialog -->
    <mat-divider [vertical]="true"></mat-divider>

    <mat-tab
      label="Add Devices By Search"
    >
    <div class="container-fluid py-5">
      <div class="row">
        <div class="col-12 col-lg-6">
          <form class="form-serial-text-area-right-tab">
            <mat-form-field class="form-serial-text-area-right-tab">
              <mat-label
                >Enter Devices Serial Every Device In Single Line</mat-label
              >
              <textarea matInput class="text-area-contanier"> </textarea>
            </mat-form-field>
            <button mat-raised-button color="primary">Add</button>
          </form>
        </div>
        <div class="col-12 col-lg-6">
          <ngx-file-drop
            class="form-serial-text-area-right-tab"
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)"
            (onFileOver)="fileOver($event)"
            (onFileLeave)="fileLeave($event)"
            dropZoneClassName="group-file-drop-zone"
            contentClassName="group-file-drop-zone-content"
            accept=".xlsx , .xls"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              Drag Device Sheet in Here or
              <button mat-button color="primary" (click)="openFileSelector()">
                Browse Files
              </button>
            </ng-template>
          </ngx-file-drop>
          <button class="margin-2-precentage" mat-raised-button color="primary">
            Upload
          </button>
          <mat-label>
            <a
              mat-button
              [href]="sheetTemplateLink"
              download="sheet-template"
              target="_blank"
            >
              Download Sheet Template
            </a>
          </mat-label>
        </div>
      </div>
    </div>
    </mat-tab>
    <!-- Start Add Devices By Sheet  Dialog -->
    <!-- <mat-tab
      label="Add Devices By Sheet"
      style="height: fit-content; min-height: fit-content; max-height: 780px"
    >
      <div class="row">
        <div class="column">
          <form>
            <mat-form-field class="devices-serial-text-area">
              <mat-label
                >Enter Devices Serial Every Device In Single Line</mat-label
              >
              <textarea matInput class="serial-input-text-area"> </textarea>
            </mat-form-field>
          </form>
        </div>
        <div class="column">
          <form>
            <mat-form-field class="devices-serial-text-area">
              <mat-label
                >Enter Devices Serial Every Device In Single Line</mat-label
              >
              <textarea matInput class="serial-input-text-area"> </textarea>
            </mat-form-field>
          </form>
        </div>
      </div>
    </mat-tab> -->

    <!-- Ends Add Devices By Sheet  Dialog -->
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions align="end"> </mat-dialog-actions>
<!--


  <table
            mat-table
            [dataSource]="dataSourceDevices"
            class="mat-elevation-z8"
            style="width: 100%; height: fit-content"
          >

            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>id</th>
              <td mat-cell *matCellDef="let element">
                {{ element.id }}
              </td>
            </ng-container>


            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                {{ element.name }}
              </td>
            </ng-container>


            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? masterToggleSelected() : null"
                  [checked]="
                    selectionDevicesSelected.hasValue() &&
                    isAllSelectedSelected()
                  "
                  [indeterminate]="
                    selectionDevicesSelected.hasValue() &&
                    !isAllSelectedSelected()
                  "
                  [aria-label]="checkboxLabelSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="
                    $event ? selectionDevicesSelected.toggle(row) : null
                  "
                  [checked]="selectionDevicesSelected.isSelected(row)"
                  [aria-label]="checkboxLabelSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDevices"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsDevices"
              (click)="selectionDevicesSelected.toggle(row)"
            ></tr>
          </table>

 -->
