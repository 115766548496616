import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device, DeviceInfo } from 'src/app/models/device';
import { DeviceService } from 'src/app/services/device.service';
import { ToastrService } from 'ngx-toastr';

enum DeviceStatus {
  Offline = 0,
  Available = 1,
}

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.css'],
})
export class EditDeviceComponent implements OnInit {
  editDevice: FormGroup;
  typesList: any = [];
  groupsList: string[] = ['Altakamul', 'Blah Blah'];
  deviceImageUrl: string = '';
  isLoading: boolean = false;
  statusList: Array<string> = Object.keys(DeviceStatus).filter((key) =>
    isNaN(+key)
  );

  constructor(
    public dialogRef: MatDialogRef<EditDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public Device_Data: { device: Device },
    private deviceService: DeviceService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  Filtered_Devices_Groups: Observable<string[]>;

  ngOnInit() {
    this.typesList = this.deviceService.deviceTypes;
    //console.log(this.Device_Data.device.deviceStatus);
    this.deviceImageUrl = this.Device_Data.device.type.imageUrl;

    this.editDevice = this.fb.group({
      deviceTypeId: [this.Device_Data.device.deviceTypeId],
      deviceStatus: [this.Device_Data.device.deviceStatus],
      status: true,
      accountManagerID: [this.Device_Data.device.accountManagerID],
      deviceLocation: [this.Device_Data.device.deviceLocation],
      ref: [this.Device_Data.device.ref],
      simSerial: [this.Device_Data.device.simSerial],
      msisdn: [this.Device_Data.device.msisdn],
      firmwareVersion: [this.Device_Data.device.firmwareVersion],
      apkVersion: [this.Device_Data.device.apkVersion],
    });
  }

  editDeviceInfo() {
    this.isLoading = true;
    console.log('edit device form:', this.editDevice.value);

    this.deviceService
      .editDevice(this.Device_Data.device.serialNumber, this.editDevice.value)
      .subscribe(
        (res) => {
          this.dialogRef.close();
          this.isLoading = false;
          this.toastr.success('Updated Succefully');
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error(err.message);
        }
      );
  }

  toNumber(value): number {
    return Number(value);
  }

  onTypeChange(event) {
    //console.log({ event });
    this.deviceImageUrl = this.deviceService.getTypeByID(event.value).imageUrl;
  }

  addDeviceToGroup() {}
  // addGroup(topping) {
  //   console.log(topping);
  //   if (this.toppingList.includes(topping)) {

  //     console.log('have');
  //     console.log(this.toppingList, 'BEFORE EVERY THING');

  //     var index = this.toppingList.indexOf(topping);
  //     console.log(index);

  //     this.toppingList.splice(index, 1);
  //     console.log(this.toppingList, 'AFTER EVERY THING');
  //   }
  //   this.toppingList.push(topping);
  //   console.log('Not have');
  // }
}
