<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>
      <span>MDM</span>
    </mat-toolbar>
    <mat-nav-list>
      <app-menu-item
        *ngFor="let item of menuItems"
        (click)="selectedItem = item"
        [item]="item"
      ></app-menu-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>
        {{
          (selectedItem == undefined ? "" : selectedItem.displayName)
            | titlecase
        }}
      </span>
      <span class="spacer"></span>
      <button
        mat-icon-button
        aria-label="Logout"
        (click)="logout()"
        *ngIf="isLoggedIn == 'true'"
      >
        <mat-icon>logout</mat-icon>
      </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
