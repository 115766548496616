<div class="loading-shade" *ngIf="isLoading">
  <mat-progress-spinner style="margin: auto" mode="indeterminate" *ngIf="isLoading"></mat-progress-spinner>
</div>
<div class="example-button-container">
  <button mat-fab color="primary" aria-label="Add Devices" style="right: 5%; bottom: 5%; position: fixed; z-index: 99"
    (click)="openAddDeviceDialog()">
    <mat-icon>add</mat-icon>
  </button>
</div>
<div class="container">
  <div class="row">
    <div class="col-12">
      <p class="py-4 mb-0">Client : {{ clientData.clientName }}</p>
    </div>
    <div class="col-12 col-md-4">
      <form class="devices-top-form">
        <mat-form-field>
          <mat-label>Filter Devices By ID</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Search Devices" #input />
        </mat-form-field>
      </form>
    </div>
    <div class="col-12 col-md-4">
      <form class="devices-top-form">
        <mat-form-field>
          <mat-label>Device Type</mat-label>
          <mat-select>
            <mat-option *ngFor="let type of typesLists" [value]="type">
              {{ type == "" ? "Reset Filter" : type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="col-12 col-md-4">
      <form class="devices-top-form">
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select>
            <mat-option *ngFor="let state of statusList" [value]="state">
              {{ state == "" ? "Reset Filter" : state }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="col-12" *ngIf="ClientDevicesDataSource.data.length > 0">
      <div class="table-responsive">
        <table *ngIf="ClientDevicesDataSource.data.length > 0" mat-table multiTemplateDataRows
          [dataSource]="ClientDevicesDataSource" class="mat-elevation-z8 devices-table">
          <ng-container matColumnDef="ID">
            <mat-header-cell *matHeaderCellDef> ID. </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.id | slice: 0:5 }}..
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="serialNumber">
            <mat-header-cell *matHeaderCellDef> Serial Number </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.serialNumber }}
            </mat-cell>
          </ng-container>
    
          <!-- Symbol Column -->
          <ng-container matColumnDef="Type">
            <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.deviceType }}
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="Status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.status }}
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="createdOn">
            <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.createdOn | dateAgo }}
            </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="SendCommand">
            <th mat-header-cell *matHeaderCellDef>Commands</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="openCommandDeviceDialog(element)">
                <mat-icon>send</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="Option">
            <mat-header-cell *matHeaderCellDef> Option </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button color="primary" (click)="editDevice(element)">
                <mat-icon>create</mat-icon>
              </button>
    
              <button mat-icon-button color="primary" (click)="deleteDevice(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
    
          <mat-header-row *matHeaderRowDef="displayedColumnsClientDevicesDataSource"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsClientDevicesDataSource"></mat-row>
        </table>
      </div>
    </div>
  </div>
  <div class="text-center py-5" *ngIf="ClientDevicesDataSource.data.length === 0">
    <img width="200" class="img-fluid" src="../../../assets/img/no-data.svg" alt="" />
    <p class="text-center mb-0">Client Has No Devices !</p>
  </div>
</div>
