<h1 mat-dialog-title>Uninstall APK</h1>
<div mat-dialog-content>
  are you sure you want to uninstall {{ appData.appName }} ?
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button color="warn" (click)="deleteApp()">
    Delete
    <span
      *ngIf="isLoading"
      class="spinner-border spinner-border-sm mr-1"
    ></span>
  </button>
</div>
