import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { DeviceService } from 'src/app/services/device.service';
import { FileUploadService } from 'src/app/services/file-upload.service';

enum DeviceStatus {
  Available = 0,
  Offline = 1,
}
@Component({
  selector: 'app-client-add-device',
  templateUrl: './client-add-device.component.html',
  styleUrls: ['./client-add-device.component.css'],
})
export class ClientAddDeviceComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  AddDeviceForm: FormGroup;
  serialNo = new FormControl();
  types = new FormControl();
  status = new FormControl();
  isLoading: boolean = false;
  typesList: any = [];
  statusList: Array<string> = Object.keys(DeviceStatus).filter((key) =>
    isNaN(+key)
  );
  imagTypeUrl: string = '';
  sheetTemplateLink: string = '../../../assets/files/sheet-example.xlsx';
  constructor(
    public fileUpload: FileUploadService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private deviceService: DeviceService,
    private dialog: MatDialog,
    private toster: ToastrService
  ) {
    this.AddDeviceForm = this.formBuilder.group({
      serialNumber: ['', Validators.required],
      deviceTypeId: ['', Validators.required],
      deviceStatus: [0, Validators.required],
      // group: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.typesList = this.deviceService.deviceTypes;
  }

  AddDeviceFunc() {
    console.log('Gafar', this.AddDeviceForm.value);
    if (this.AddDeviceForm.valid) {
      this.isLoading = true;
      this.deviceService
        .addDevice(this.AddDeviceForm.value, this.data)
        .subscribe((data) => {
          this.isLoading = false;
          this.toster.success('device added successfully')
          this.dialog.closeAll();
        }, err => {
          this.isLoading = false;
          this.toster.error(err.error.message)
        });
    }
  }

  onTypeChange(event) {
    console.log({ event });
    this.imagTypeUrl = this.deviceService.getTypeByID(event.value).imageUrl;
  }
  toNumber(value): number {
    return Number(value);
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          // You could upload it like this:
          const formData = new FormData();
          formData.append('sheet', file, droppedFile.relativePath);

          //   this.deviceService.addDevicesBySheet(formData).subscribe(
          //     (res) => {
          //       console.log({ res });
          //       this.toastr.success('Devices Added Succesfully');
          //     },
          //     (err) => {
          //       console.log({ err });

          //       this.toastr.error(err.error.message);
          //     }
          //   );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
}
