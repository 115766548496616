import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FormatsService } from 'src/app/services/formats.service';
import { GroupService } from 'src/app/services/group.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css'],
})
export class StatusComponent implements OnInit {
  typesLists: any = ['placeholder', 'placeholder'];
  groupIdList: any[] = [];
  filteredGroupIds: Observable<string[]>;
  isLoading: boolean = false;
  tableData: any = [];
  accountManagerName: string = "";
  groupInput: string;
  sliceTableData: any[] = [];
  statusReportForm: FormGroup;
  accountManagers: any[] = [];
  showDownload: boolean = false;
  meanuType: string = this.authService.setMenuType();
  clients: object;
  clientId: string;
  filters: any;
  breakpoint: number;
  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private dashboardService: DashboardService,
    private groupService: GroupService,
    private reportsService: ReportsService,
    private formatsService: FormatsService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.statusReportForm = this.formBuilder.group({
      deviceGroupId: [''],
      accountManagerId: [''],
      startDate: [''],
      endDate: [''],
      excel: true,
    });
  }


  ngOnInit(): void {
    this.breakpoint = window.innerWidth <= 800 ? 1 : 4;
    this.getAccountManagers();
    this.getDeviceGroupId();
    this.filteredGroupIds = this.statusReportForm.get('deviceGroupId').valueChanges.pipe(
      startWith(),
      map(value => this.filter(value))
    );

    if (this.meanuType == '1') {
      this.getClients();
    }
  }

  onResize(event) {
    this.breakpoint = event.target.innerWidth <= 800 ? 1 : 4;
  }

  filter(value: string): string[] {
    const filterValue = this.formatsService.normalizeValue(value);
    return this.groupIdList.filter(groupId => this.formatsService.normalizeValue(groupId.name).includes(filterValue));
  }

  getClients() {
    this.clientService.getClient().subscribe(res => {
      this.clients = res;
      //console.log('clients:', this.clients)
    });
  }

  getDeviceGroupId() {
    this.groupIdList = this.groupService.deviceGroupIdList(this.clientId);
  }

  getAccountManagers() {
    this.dashboardService.accountManager().subscribe((res: any) => {
      console.log(res);
      this.accountManagers = res;
    });
  }

  getName(value: string) {
    this.groupInput = value;
  }

  onChange(e) {
    //console.log(e)
    this.getDeviceGroupId();
  }

  onSubmit() {
    this.formatsService.formatDate(this.statusReportForm);
    console.log(this.statusReportForm.value);
    this.filters = this.statusReportForm.value;
    console.log(this.filters)
    this.isLoading = true;
    this.showDownload = false;
    this.sliceTableData = [];
    this.tableData.length = 0;
    this.reportsService.statusReport(this.statusReportForm.value, 'json').subscribe(res => {
      this.isLoading = false;
      this.tableData = res.body;
      if(this.tableData.length <= 0) {
        console.log('no data')
        this.toastr.info('no data available!');
      } else {
        console.log(this.tableData)
        this.tableData.forEach(data => {
          if (data.devices.length >= 4) {
            this.sliceTableData.push({ accountManager: data.accountManager, devices: data.devices.slice(0, 4) });
          } else {
            this.sliceTableData.push({ accountManager: data.accountManager, devices: data.devices });
          }
        })

        console.log('slice data', this.sliceTableData)
        this.showDownload = true;
      }
     
    },
      err => {
        //console.log(err)
        this.toastr.error(err.message);
        this.isLoading = false;
      })

    this.reset();
    //this.deviceCommandsReportForm.reset();
  }

  reset() {
    this.statusReportForm.reset();
    this.statusReportForm.setValue({
      deviceGroupId:'',
      accountManagerId: '',
      startDate: '',
      endDate: '',
      excel: true,
    });
  }

  onPageChange(e: PageEvent, i: number) {
    const startIndex = e.pageIndex * e.pageSize;
    let endIndex = startIndex + e.pageSize;
    if (endIndex > this.tableData[i].devices.length) {
      endIndex = this.tableData[i].devices.length;
    }

    this.sliceTableData[i].devices = this.tableData[i].devices.slice(startIndex, endIndex);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

}
