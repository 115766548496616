import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  clientID: string;
  headers: HttpHeaders = new HttpHeaders();
  menuType: any = localStorage.getItem('menuType');
  constructor(private http: HttpClient) {
    this.clientID = localStorage.getItem('clientID');
    let apiKey = localStorage.getItem('apiKey');
    this.headers = this.headers.append('x-api-key', apiKey);
  }
  
  commandReport(filters, type: string) {
    // filters.clientID ? filters : filters.clientID = this.clientID;
    //console.log('filters:', filters)
    let url = this.menuType == 0? `/commandreport?ClientId=${this.clientID}` : '/commandreport';
    return this.http.post(
      environment.baseURI + 'Reports' + url,
      filters,
      {  headers: this.headers, responseType: type as 'json', observe: 'response' }
    );
  }

  statusReport(filters, type: string) {
    let url = this.menuType == 0? `/statusreport?ClientId=${this.clientID}` : '/statusreport';
    return this.http.post(
      environment.baseURI + 'Reports' + url,
      filters
      ,
      { headers: this.headers, responseType: type as 'json', observe: 'response' }
    )
  }

  detailedDeviceReport(filters, type:string) {
    let url = this.menuType == 0? `/detaileddevicereport?ClientId=${this.clientID}` : '/detaileddevicereport';
    return this.http.post(
      environment.baseURI + 'Reports' + url,
      filters ,
      { headers: this.headers, responseType: type as 'json', observe: 'response' }
    );
  }
  
}
