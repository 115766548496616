import { OnInit, Component, ViewChild, } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { DashboardService } from '../services/dashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ChartComponent } from "ng-apexcharts";

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
})
export class DashComponent implements OnInit {

  /** Based on the screen size, switch from standard to one column per row */
  cardLayout = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 4 },
        };
      }

      return {
        columns: 4,
        miniCard: { cols: 1, rows: 1 },
        chart: { cols: 2, rows: 2 },
        table: { cols: 4, rows: 4 },
      };
    })
  );

  isLoading: boolean = true;
  logsColumns: string[] = [
    'id',
    'command',
    'date',
    'userName',
    'status',
  ];

  displayedColumns: string[] = [
    'name',
    'number of devices',
    'active',
    'non active',
    'online',
    'offline',
  ];

  devicesAreaDataSource: any = new MatTableDataSource();
  groupsDataSource: any = new MatTableDataSource();
  accountManagers: any = new MatTableDataSource();
  Last10Logs: any = new MatTableDataSource();
  numberOfCommands: number;

  groupNames: string[] = [];
  groupDevices: number[] = [];
  groupActiveDevices: number[] = [];
  areaNames: string[] = [];
  areaDevices: number[] = [];
  areaActiveDevices: number[] = [];
  managersNames: string[] = [];
  managersDevices: number[] = [];
  managersActiveDevices: string[] = []

  //groups chart
  groupChartOptions = {
    series: [
      {
        name: "Active devices",
        data: this.groupActiveDevices
      },
      {
        name: "Total devices",
        data: this.groupDevices
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      stacked: true
    },
    // grid: { show: false },
    title: {
      text: ""
    },
    xaxis: {
      type: "category",
      categories: this.groupNames
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
      }
    },
    fill: {
      colors: ['#32cf8b', '#AAAAAA']
    },
    legend: {
      markers: {
        fillColors: ['#32cf8b', '#AAAAAA']
      }
    }
  };

  //area chart
  areaChartOptions = {
    series: [
      {
        name: "Active devices",
        data: this.areaActiveDevices
      },
      {
        name: "Total devices",
        data: this.areaDevices
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      stacked: true
    },
    title: {
      text: ""
    },
    xaxis: {
      type: "category",
      categories: this.areaNames
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
      }
    },
    fill: {
      colors: ['#32cf8b', '#AAAAAA']
    }
  };

  //managers chart
  managersChartOptions = {
    series: [
      {
        name: "Active devices",
        data: this.managersActiveDevices
      },
      {
        name: "Total devices",
        data: this.managersDevices
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      stacked: true
    },
    title: {
      text: ""
    },
    xaxis: {
      type: "category",
      categories: this.managersNames
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
      }
    },
    fill: {
      colors: ['#32cf8b', '#AAAAAA']
    }
  };


  @ViewChild('groupsPaginator', { read: MatPaginator }) groupsPaginator: MatPaginator;
  @ViewChild('devicesAreaPaginator', { read: MatPaginator }) devicesAreaPaginator: MatPaginator;
  @ViewChild('accountManagersPaginator', { read: MatPaginator }) accountManagersPaginator: MatPaginator;
  @ViewChild('logsPaginator', { read: MatPaginator }) logsPaginator: MatPaginator;

  @ViewChild("chart") chart: ChartComponent;

  constructor(private breakpointObserver: BreakpointObserver, private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.dashboardService.getGroupsStatus().subscribe(res => {
      //console.log('groups status', res);
      this.groupsDataSource.data = res;
      let array = [];
      array.push(res);
      //console.log(array[0])
      array[0].forEach(element => {
        this.groupNames.push(element.group);
        this.groupDevices.push(element.devices);
        this.groupActiveDevices.push(element.active)
      });

      this.updategroupsChartSeries();

    })

    this.dashboardService.getDevicesByArea().subscribe(res => {
      //console.log('devices area', res);
      this.devicesAreaDataSource.data = res;

      let array = [];
      array.push(res);
      //console.log(array[0])
      array[0].forEach(element => {
        this.areaNames.push(element.area);
        this.areaDevices.push(element.devices);
        this.areaActiveDevices.push(element.active)
      });

      this.updateAreaChartSeries();;
    });

    this.dashboardService.getAccountManagers().subscribe(res => {
      //console.log('account managers', res);
      this.accountManagers.data = res;

      let array = [];
      array.push(res);
      //console.log(array[0])
      array[0].forEach(element => {
        this.managersNames.push(element.accountManager);
        this.managersDevices.push(element.devices);
        this.managersActiveDevices.push(element.active)
      });

      this.updateManagersChartSeries();
    });

    this.dashboardService.getLast10Logs().subscribe(res => {
      //console.log('Last10Logs', res);
      this.Last10Logs.data = res;
    });

    this.dashboardService.getCommandsNotCompeleted().subscribe(res => {
      //console.log("countofCommand:", res);
      this.numberOfCommands = res;
    })

    this.isLoading = false;
  }

  updategroupsChartSeries() {
    this.groupChartOptions.series = [
      {
        name: "Active devices",
        data: this.groupActiveDevices
      },
      {
        name: "Total devices",
        data: this.groupDevices
      },
    ];

    this.groupChartOptions.xaxis = {
      type: "category",
      categories: this.groupNames
    }
  };

  updateAreaChartSeries() {
    this.areaChartOptions.series = [
      {
        name: "Active devices",
        data: this.areaActiveDevices
      },
      {
        name: "Total devices",
        data: this.areaDevices
      },
    ];

    this.areaChartOptions.xaxis = {
      type: "category",
      categories: this.areaNames
    }
  };

  updateManagersChartSeries() {
    this.managersChartOptions.series = [
      {
        name: "Active devices",
        data: this.managersActiveDevices
      },
      {
        name: "Total devices",
        data: this.managersDevices
      },
    ];

    this.managersChartOptions.xaxis = {
      type: "category",
      categories: this.managersNames
    }
  };


  ngAfterViewInit() {
    this.groupsDataSource.paginator = this.groupsPaginator;
    this.devicesAreaDataSource.paginator = this.devicesAreaPaginator;
    this.accountManagers.paginator = this.accountManagersPaginator;
    this.Last10Logs.paginator = this.logsPaginator;
  }

}
