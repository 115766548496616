<app-loader *ngIf="isLoading"></app-loader>
<!-- Start Fab-button Add Devices -->
<div class="example-button-container">
  <button
    mat-fab
    color="primary"
    aria-label="Add Devices"
    style="right: 5%; bottom: 5%; position: fixed"
    (click)="openAddDeviceDialog()"
  >
    <mat-icon>add</mat-icon>
  </button>
</div>
<!-- Ends Fab-button Add Devices -->
<div class="container">
  <div class="form-row mt-5">
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <mat-label>Filter By serial #</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search Devices"
          #input
        />
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <mat-label>Device Type</mat-label>
        <mat-select>
          <mat-option
            (click)="filterDrop(type)"
            *ngFor="let type of typesLists"
            [value]="type"
          >
            {{ type == "" ? "Reset Filter" : type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select>
          <mat-option
            (click)="applyFilterDrop(state)"
            *ngFor="let state of statusList"
            [value]="state"
          >
            {{ state == "" ? "Reset Filter" : state }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="table-responsive my-5">
    <!-- Start Devices Table -->
    <table
      mat-table
      multiTemplateDataRows
      [dataSource]="dataSource"
      class="mat-elevation-z8 w-100"
      *ngIf="dataSource.data.length > 0"
    >
      <!-- Start ID Column -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex;">{{ i }}</td>
      </ng-container>
      <!-- Ends ID Column -->

      <!-- Start Serial Number Column -->
      <ng-container matColumnDef="serialNumber">
        <th mat-header-cell *matHeaderCellDef>Serial Number</th>
        <td mat-cell *matCellDef="let element">{{ element.serialNumber }}</td>
      </ng-container>
      <!-- Ends Serial Number Column -->

      <!-- Start Type Column -->
      <ng-container matColumnDef="deviceType">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">
          {{ element.deviceType }}
        </td>
      </ng-container>
      <!-- Ends Type Column -->

      <!-- Start Group Column -->
      <!-- <ng-container matColumnDef="group">
    <th mat-header-cell *matHeaderCellDef>Group</th>
    <td mat-cell *matCellDef="let element">{{ element.group }}</td>
  </ng-container> -->
      <!-- Ends Group Column -->

      <!-- Start Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>
      <!-- Ends Status Column -->

      <!-- Start Commands Column -->
      <ng-container matColumnDef="commands">
        <th mat-header-cell *matHeaderCellDef>Commands</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-stroked-button
            color="primary"
            (click)="openCommandDeviceDialog(element)"
          >
            <mat-icon>send</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- Ends Commands Column -->

      <!-- Start Options Column -->
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>Options</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-stroked-button
            color="primary"
            (click)="openEditDeviceDialog(element)"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- Ends Options Column -->

      <!-- Start First Row Contains Titles -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <!-- Ends First Row Contains Titles -->
      <!-- Start Tables Row For Each Column -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <!-- Ends Tables Row For Each Column -->
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" length="dataSource"></mat-paginator>
    <!-- Ends Devices Table -->
  </div>
</div>

<div
  class="empty pt-5 text-center "
  *ngIf="!isLoading && dataSource.data.length == 0"
  style="width: 30%; margin: auto"
>
  <img src="../../../assets/img/no-data.svg" class="img-fluid" alt="" />
  <h3 class="my-3">Client Has No Devices !</h3>
</div>
