<div class="loading-shade" *ngIf="isLoading">
  <mat-progress-spinner style="margin: auto" mode="indeterminate" *ngIf="isLoading"></mat-progress-spinner>
</div>
<!-- Ends Loader -->
<!-- <button class="btn" (click)="onButtonClick()">excel</button> -->
<button mat-button [matMenuTriggerFor]="menu" class="download">download Report <mat-icon class="download-icon">arrow_downward</mat-icon></button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="onButtonClick('excel')">
    <mat-icon class="excel">description</mat-icon>
    <span>Excel</span>
  </button>
  <!-- <button mat-menu-item (click)="onButtonClick('pdf')">
    <mat-icon class="pdf">picture_as_pdf</mat-icon>
    <span>Pdf</span>
  </button> -->
</mat-menu>

<div id="fab-dismiss" *ngIf="fabTogglerState === 'active'" (click)="onToggleFab()"></div>

<!-- <div class="fab-container" matTooltip="download Excel">
  <button
    (click)="onToggleFab()"
    class="fab-toggler"
    mat-fab
    color="primary"
    aria-label="Add Devices"
    style="right: 5%; bottom: 5%; position: fixed"
  >
    <mat-icon [@fabToggler]="{ value: fabTogglerState }">save</mat-icon>
  </button>

  <div
    [@speedDialStagger]="buttons.length"
    style="right: 5.5%; bottom: 11%; position: fixed"
  >
    <button
      *ngFor="let btn of buttons"
      mat-mini-fab
      class="fab-secondary"
      color="secondary"
      (click)="onButtonClick(btn.action)"
    >
      <mat-icon>{{ btn.icon }}</mat-icon>
    </button>
  </div>
</div> -->