<div class="main-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" color="primary" style="text-align: center">
    <mat-card-header>
      <mat-card-title>Log In</mat-card-title>
    </mat-card-header>

    <form class="example-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" formControlName="email" />
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input
            matInput
            type="password"
            placeholder="Password"
            formControlName="password"
          />
        </mat-form-field>
      </mat-card-content>
      <button mat-stroked-button color="primary" class="btn-block">
        Log-in
        <span
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
      </button>
    </form>
    <a routerLink="/forgot-password" class="btn btn-link reset-link">
      Forgot Your Password ?
    </a>
  </mat-card>
</div>
