import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { AdminAddOrderComponent } from '../modals/admin-add-order/admin-add-order.component';

@Component({
  selector: 'app-client-orders',
  templateUrl: './client-orders.component.html',
  styleUrls: ['./client-orders.component.css'],
})
export class ClientOrdersComponent implements OnInit {
  isLoading = false;

  displayedColumnsClients: any[] = [
    'id',
    'clientId',
    'count',
    'currentCount',
    'expiryDate',
  ];
  ordersDataSource = new MatTableDataSource();
  clientData: any;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private orderService: OrderService
  ) {
    if (router.getCurrentNavigation().extras.state) {
      let client = router.getCurrentNavigation().extras.state.client;
      console.log({ client });
      this.clientData = client;
      this.getAllOrders(client.id);
    }
  }

  ngOnInit(): void {
    console.log(this.ordersDataSource);
    // this.getAllOrders();
  }

  getAllOrders(client?) {
    this.isLoading = true;
    this.orderService.getOrders().subscribe(
      (orders: any) => {
        console.log({ orders });
        this.ordersDataSource.data = orders;
        this.isLoading = false;
      },
      (err) => {
        console.log({ err });
        this.isLoading = false;
      }
    );
  }

  openAddOrderDialog(client) {
    this.dialog
      .open(AdminAddOrderComponent, { data: client })
      .afterClosed()
      .subscribe((data) => {
        console.log({ data });
        if (data.result == 'success') this.getAllOrders();
      });
  }
  goToOrderDetails() {
    this.router.navigateByUrl('client-order-details');
  }
}
