import { Component, OnInit } from '@angular/core';
import {
  EmailValidator,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client.service';

enum UserType {
  Support = 1,
  Client = 2,
}

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css'],
})
export class AddClientComponent implements OnInit {
  isLoading = false;
  imageLoaded: boolean = false;
  addClientForm: FormGroup;
  typeList: Array<string> = Object.keys(UserType).filter((key) => isNaN(+key));
  constructor(
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<AddClientComponent>
  ) {
    console.log(this.typeList, 'TYPES');

    this.addClientForm = this.formBuilder.group({
      clientName: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      phoneNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(10),
        ]),
      ],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      role: ['', Validators.required],
    });
  }

  ngOnInit(): void {}
  AddDeviceFunc() {
    console.log('Gafar', this.addClientForm.value);
    if (this.addClientForm.valid) {
      this.isLoading = true;
    }
  }

  toNumber(value): number {
    return Number(value) + 1;
  }

  onSubmit() {
    if (this.addClientForm.valid) {
      this.isLoading = true;
      console.log(this.addClientForm.value);
      this.clientService.addClient(this.addClientForm.value).subscribe(
        (res: any) => {
          console.log({ res });
          this.isLoading = false;
          if (res.result == 'success') {
            this.toastr.success('Success');
            this.dialogRef.close(res);
          }
        },
        (err) => {
          this.isLoading = false;
          this.toastr.error('Something Went Wrong');
        }
      );
    } else {
      this.toastr.error('Please Fill The Following Form');
    }
  }

  onImageLoad() {
    this.imageLoaded = true;
  }
}
