<!-- <h2 mat-dialog-title>Edit Device</h2> -->
<mat-dialog-content class="add-device-dialog mat-typography" minWidth="1920">
  <mat-tab-group>
    <!-- Start Edit Device Dialog -->

    <mat-tab label="Edit Device">
      <div class="container-fluid">
        <div class="row justify-content-center align-items-center position-relative">
          <div class="col-6 py-2 auto-column">
            <form class="example-form" [formGroup]="editDevice">
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Serial Number</label>
                  <input
                    class="custom-input"
                    [value]="Device_Data.device.serialNumber"
                    disabled
                  />
                </div>
                <div class="form-group col-12">
                  <label>Device Type</label>
                  <mat-button-toggle-group formControlName="deviceTypeId">
                    <mat-button-toggle
                      class="selection-btn"
                      *ngFor="let type of typesList"
                      [value]="type.id"
                      (change)="onTypeChange($event)"
                      >{{ type.name }}</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
                <div class="form-group col-12">
                  <label>Device Status</label>
                  <mat-button-toggle-group
                    formControlName="deviceStatus"
                    [value]="Device_Data.device.deviceStatus"
                  >
                    <mat-button-toggle
                      class="selection-btn"
                      *ngFor="let state of statusList | keyvalue"
                      [value]="toNumber(state.key)"
                      >{{ state.value }}</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
                <!-- <div class="form-group col-12">
                  <label>Network Status</label>
                  <mat-button-toggle-group formControlName="networkStatus" [value]="Device_Data.device.networkStatus">
                    <mat-button-toggle class="selection-btn" [value]="1">Online</mat-button-toggle>
                    <mat-button-toggle class="selection-btn" [value]="0">Offline</mat-button-toggle>
                  </mat-button-toggle-group>
                </div> -->
                <div class="form-group col-12">
                  <label>Account Manager ID</label>
                  <input
                    class="custom-input"
                    formControlName="accountManagerID"
                    [(value)]="Device_Data.device.accountManagerID"
                  />
                </div>
                <div class="form-group col-12">
                  <label>Device Location</label>
                  <input
                    class="custom-input"
                    formControlName="deviceLocation"
                    [(value)]="Device_Data.device.deviceLocation"
                  />
                </div>
                <div class="form-group col-12">
                  <label>Status</label>
                  <mat-button-toggle-group
                    formControlName="status"
                    [value]="Device_Data.device.status"
                  >
                    <mat-button-toggle class="selection-btn" [value]="true"
                      >True</mat-button-toggle
                    >
                    <mat-button-toggle class="selection-btn" [value]="false"
                      >False</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
                <div class="form-group col-12">
                  <label>Ref</label>
                  <input
                    class="custom-input"
                    formControlName="ref"
                    [(value)]="Device_Data.device.ref"
                  />
                </div>
                <div class="form-group col-12">
                  <label>Sim Serial</label>
                  <input
                    class="custom-input"
                    formControlName="simSerial"
                    [(value)]="Device_Data.device.simSerial"
                  />
                </div>
                <div class="form-group col-12">
                  <label>Msisdn</label>
                  <input
                    class="custom-input"
                    formControlName="msisdn"
                    [(value)]="Device_Data.device.msisdn"
                  />
                </div>
                <div class="form-group col-12">
                  <label>Firmware Version</label>
                  <input
                    class="custom-input"
                    formControlName="firmwareVersion"
                    [(value)]="Device_Data.device.firmwareVersion"
                  />
                </div>
                <div class="form-group col-12">
                  <label>Apk Version</label>
                  <input
                    class="custom-input"
                    formControlName="apkVersion"
                    [(value)]="Device_Data.device.apkVersion"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="col-6 text-center py-2 position-static">
            <img style="max-width: 100%" [src]="deviceImageUrl" width="150" height="100%" alt="device" />
            <mat-dialog-actions class="justify-content-end mt-1 py-0 mb-0 position-absolute px-2 action-btn">
              <button class="btn-secondary-custome" [mat-dialog-close]="true">Cancel</button>
              <button class="btn-primary-custome ml-2" cdkFocusInitial (click)="editDeviceInfo()">
                Save
                <span
                  *ngIf="isLoading"
                  class="spinner-border spinner-border-sm ml-2"
                ></span>
              </button>
            </mat-dialog-actions>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- Ends Edit Device Dialog -->
  </mat-tab-group>
</mat-dialog-content>
