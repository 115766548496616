<app-loader *ngIf="isLoading"></app-loader>

<div class="container">
  <div class="row">
    <div class="col-2 py-3">
      <mat-label style="font-size: large; font-weight: 420">
        Clients
      </mat-label>
    </div>
    <div class="col-2 py-3">
      <button
        mat-raised-button
        color="primary"
        style=""
        (click)="openAddClientDialog()"
      >
        Add Client
      </button>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 px-3">
      <div class="table-container">
        <table
          *ngIf="clientsDataSorce.data.length > 0"
          mat-table
          multiTemplateDataRows
          [dataSource]="clientsDataSorce"
          class="mat-elevation-z8 devices-table"
        >
          >
          <ng-container matColumnDef="No">
            <mat-header-cell *matHeaderCellDef> ID. </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = dataIndex">
              {{ i }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.clientName }}
            </mat-cell>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="phone">
            <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.phoneNumber }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
            <mat-cell *matCellDef="let element" style="padding: 10px">
              {{ element.createdOn | dateAgo }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Groups">
            <mat-header-cell *matHeaderCellDef> Groups </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a mat-button (click)="goToGroups(element)">
                <u> Groups </u>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Devices">
            <mat-header-cell *matHeaderCellDef> Devices </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a mat-button (click)="goToDevices(element)">
                <u> Devices </u>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Orders">
            <mat-header-cell *matHeaderCellDef> Orders </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a mat-button (click)="openOrders(element)">
                <u> Orders </u>
              </a>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsClients"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsClients"
          ></mat-row>
        </table>
      </div>
    </div>
  </div>
</div>
