import { Injectable } from '@angular/core';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(public toastr: ToastrService) {}

  public files: NgxFileDropEntry[] = [];

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    if (files.length > 1) {
      this.toastr.error('Cannot add more than 1 File at a time.');
    } else {
      for (const droppedFile of files) {
        // Is it a file?
        if (
          droppedFile.fileEntry.isFile &&
          this.isFileAllowed(droppedFile.fileEntry.name)
        ) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            // Here you can access the real file
            console.log(droppedFile.relativePath, file);

            // You could upload it like this:
            const formData = new FormData();
            formData.append('sheet', file, droppedFile.relativePath);

            // Headers
            // const headers = new HttpHeaders({
            //   'security-token': 'mytoken'
            // })

            // this.http
            //   .post(
            //     'https://mybackend.com/api/upload/sanitize-and-save-logo',
            //     formData
            //   )
            //   .subscribe((data) => {
            //     // Sanitized logo returned from backend
            //   });
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          this.toastr.error(
            "Only files in '.xls', '.xlsx'  format are accepted and directories are not allowed."
          );
          // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // console.log(droppedFile.relativePath, fileEntry);
        }
      }
    }
  }
  fileOver(event) {
    console.log(event);
  }

  fileLeave(event) {
    console.log(event);
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.xlsx', '.xls'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }
}
