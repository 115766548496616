import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  constructor(public datepipe: DatePipe) { }

  // date formatting
  formatDate(value) {
    let startDate = value.get('startDate').value;
    let endDate = value.get('endDate').value;

    console.log(startDate, endDate)
    let fullStartDate;
    let fullEndDate;

    if ((endDate == '' || endDate == null) && (startDate == '' || startDate == null)) {
      console.log('both empty')
      fullStartDate = startDate;
      fullEndDate = endDate;
    } else if (endDate == '' || endDate == null) {
      console.log('end date empty')
      let startMonth = startDate.getMonth() + 1;
      let startDay = startDate.getDate();
      fullStartDate = `${startDate.getFullYear()}-${String(startMonth).length == 1 ? '0' + startMonth : startMonth}-${String(startDay).length == 1 ? '0' + startDay : startDay} 00:00`;

      fullEndDate = endDate;
    } else if (startDate == '' || startDate == null) {
      console.log('start date empty')
      fullStartDate = startDate;

      let endMonth = endDate.getMonth() + 1;
      let endDay = endDate.getDate();
      fullEndDate = `${endDate.getFullYear()}-${String(endMonth).length == 1 ? '0' + endMonth : endMonth}-${String(endDay).length == 1 ? '0' + endDay : endDay} 23:59`;
    } else {
      console.log('no empty date')
      let startMonth = startDate.getMonth() + 1;
      let startDay = startDate.getDate();
      fullStartDate = `${startDate.getFullYear()}-${String(startMonth).length == 1 ? '0' + startMonth : startMonth}-${String(startDay).length == 1 ? '0' + startDay : startDay} 00:00`;

      let endMonth = endDate.getMonth() + 1;
      let endDay = endDate.getDate();
      fullEndDate = `${endDate.getFullYear()}-${String(endMonth).length == 1 ? '0' + endMonth : endMonth}-${String(endDay).length == 1 ? '0' + endDay : endDay} 23:59`;

    }


    value.get('startDate').setValue(fullStartDate);
    value.get('endDate').setValue(fullEndDate);
    value.get('excel').setValue(false);
  }

  // autocomplete filter
  normalizeValue(value: string): string {
    if (value !== null) {
      return value.toLowerCase().replace(/\s/g, '');
    }
  }
}