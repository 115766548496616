<div class="loading-shade" *ngIf="isLoading">
  <mat-progress-spinner
    style="margin: auto"
    mode="indeterminate"
    *ngIf="isLoading"
  ></mat-progress-spinner>
</div>

<mat-grid-list cols="9" rowHeight="2:1">
  <mat-grid-tile colspan="2">
    <mat-label style="font-size: large; font-weight: 420">
      Orders Details
    </mat-label>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <mat-label style="font-size: large; font-weight: 420">
      Client : Altkamul Smart E-pay
    </mat-label>
  </mat-grid-tile>
  <mat-grid-tile colspan="5"> </mat-grid-tile>

  <mat-grid-tile colspan="9" rowspan="2">
    <!-- Start Top Part[whole Grid] Includes 4 inputs and button  -->
    <mat-grid-list
      style="width: 100%; height: 100%"
      cols="4"
      rowHeight="1:0.1"
      class="devices-top-from-grid"
    >
      <!-- Start Filter Tile  -->
      <mat-grid-tile colspan="2" rowspan="2">
        <form class="devices-top-form">
          <mat-form-field style="width: 100%">
            <mat-label>Filter Devices By ID</mat-label>
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Search Devices"
              #input
            />
          </mat-form-field>
        </form>
      </mat-grid-tile>
      <!-- Ends Filter Tile  -->

      <!-- Start Type [Auto Complete Element] Tile -->
      <mat-grid-tile colspan="2" rowspan="2">
        <form class="devices-top-form">
          <mat-form-field style="width: 100%">
            <mat-label>Device Type</mat-label>
            <mat-select>
              <mat-option *ngFor="let type of typesLists" [value]="type">
                {{ type == "" ? "Reset Filter" : type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="2">
        <form class="devices-top-form">
          <mat-form-field style="width: 100%">
            <mat-label>Status</mat-label>
            <mat-select>
              <mat-option *ngFor="let state of statusList" [value]="state">
                {{ state == "" ? "Reset Filter" : state }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="2">
        <button mat-raised-button color="primary">Add Device</button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-grid-tile>

  <mat-grid-tile
    colspan="9"
    style="width: fit-content; height: fit-content"
    rowspan="10"
  >
    <mat-table
      [dataSource]="ordersDevicesDataSource"
      style="width: 100%; height: 100%; overflow-y: scroll"
    >
      <ng-container matColumnDef="ID">
        <mat-header-cell *matHeaderCellDef> ID. </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.ID }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SerialNo">
        <mat-header-cell *matHeaderCellDef> SerialNo </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.SerialNo }}
        </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="Type">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.Type }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Status">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.Status }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Group">
        <mat-header-cell *matHeaderCellDef> Group </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.Group }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="SendCommand">
        <mat-header-cell *matHeaderCellDef> Send Command</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.SendCommand }} <mat-icon class="margin-10">send </mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Option">
        <mat-header-cell *matHeaderCellDef> Option </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-icon class="margin-10">create</mat-icon>
          <mat-icon class="margin-10">delete</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumnsordersDevicesDataSource"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumnsordersDevicesDataSource"
      ></mat-row>
    </mat-table>
  </mat-grid-tile>
</mat-grid-list>
