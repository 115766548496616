import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  getOrders() {
    return this.http.get(environment.baseURI + 'Orders');
  }

  getOrderDetail(orderID) {
    return this.http.get(`${environment.baseURI}Orders/${orderID}`);
  }

  addOrder(orderData, client) {
    orderData.clientId = client.id;
    return this.http.post(environment.baseURI + 'Orders', orderData);
  }
}
