<h2 mat-dialog-title>Add Device</h2>

<mat-dialog-content class="add-device-dialog mat-typography" minWidth="1920">
  <mat-tab-group>
    <!-- Start Add Device Dialog -->
    <mat-tab label="Add Device">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-center position-relative mx-0">
          <div class="col-6 py-2 auto-column">
            <form class="example-form" [formGroup]="AddDeviceForm">
              <div class="form-row">
                <div class="form-group col-12">
                  <label>Serial Number</label>
                  <input class="custom-input" formControlName="serialNumber"
                    placeholder="Enter device serial number.." />
                </div>
                <div class="form-group col-12">
                  <label>Device Type</label>
                  <mat-button-toggle-group formControlName="deviceTypeId">
                    <mat-button-toggle class="selection-btn" *ngFor="let type of typesList" [value]="type.id"
                      (change)="onTypeChange($event)">{{ type.name }}</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="form-group col-12">
                  <label>Device Status</label>
                  <mat-button-toggle-group formControlName="deviceStatus">
                    <mat-button-toggle class="selection-btn" [value]="'1'">Available</mat-button-toggle>
                    <mat-button-toggle class="selection-btn" [value]="'0'">Offline</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
                <div class="form-group col-12">
                  <label>Account Manager ID</label>
                  <mat-select class="custom-input" formControlName="accountManagerID">
                    <mat-option
                      *ngFor="let manager of accountManagers"
                      [value]="manager.accountManagerID"
                    >
                    {{manager.accountManagerName}}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="form-group col-12">
                  <label>Device Location</label>
                  <input class="custom-input" formControlName="deviceLocation" placeholder="ex: Dubai" />
                </div>
                <div class="form-group col-12">
                  <label>Ref</label>
                  <input class="custom-input" formControlName="ref" placeholder="Enter Ref Number" />
                </div>
                <div class="form-group col-12">
                  <label>Sim Serial</label>
                  <input class="custom-input" formControlName="simSerial" placeholder="Enter SIM Serial Number" />
                </div>
                <div class="form-group col-12">
                  <label>Msisdn</label>
                  <input class="custom-input" formControlName="msisdn" placeholder="Enter Msisdn" />
                </div>
                <div class="form-group col-12">
                  <label>Firmware Version</label>
                  <input class="custom-input" formControlName="firmwareVersion" placeholder="ex: v3" />
                </div>
                <div class="form-group col-12">
                  <label>Apk Version</label>
                  <input class="custom-input" formControlName="apkVersion" placeholder="ex: v3" />
                </div>
              </div>
            </form>
          </div>
          <div class="col-6 text-center py-2 position-static">
            <img [src]="imagTypeUrl" style="max-width: 100%" width="150" height="100%"
              alt="Photo of a POS" />
              <mat-dialog-actions class="justify-content-end mt-1 py-0 mb-0 position-absolute action-btn px-2">
                <button class="btn-secondary-custome" mat-dialog-close="true">Cancel</button>
                <button class="btn-primary-custome ml-2" cdkFocusInitial type="submit" (click)="AddDeviceFunc()">
                  Save
                  <span *ngIf="isLoading" class="spinner-border spinner-border-sm ml-2"></span>
                </button>
              </mat-dialog-actions>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Add Devices By Search">
      <div class="container-fluid py-5">
        <div class="row">
          <div class="col-12 col-lg-6">
            <form class="form-serial-text-area-right-tab">
              <mat-form-field class="form-serial-text-area-right-tab">
                <mat-label>Enter Devices Serial Every Device In Single Line</mat-label>
                <textarea matInput class="text-area-contanier"> </textarea>
              </mat-form-field>
              <div style="text-align: right;">
                <button mat-raised-button color="basic">Add</button>
              </div>
            </form>
          </div>
          <div class="col-12 col-lg-6">
            <ngx-file-drop class="form-serial-text-area-right-tab" dropZoneLabel="Drop files here"
              (onFileDrop)="dropped($event)" (onFileOver)="fileUpload.fileOver($event)"
              (onFileLeave)="fileUpload.fileLeave($event)" dropZoneClassName="group-file-drop-zone"
              contentClassName="group-file-drop-zone-content" accept=".xlsx">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                Drag Device Sheet in Here or
                <button mat-button color="primary" (click)="openFileSelector()">
                  Browse Files
                </button>
              </ng-template>
            </ngx-file-drop>
            <div style="text-align: right;">
              <button mat-raised-button color="primary">
                Upload
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-label>
              <a mat-stroked-button color="primary" [href]="sheetTemplateLink" download="sheet-template"
                target="_blank">
                Download Sheet Template
              </a>
            </mat-label>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- Ends Add Device Dialog -->
  </mat-tab-group>
</mat-dialog-content>