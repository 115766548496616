<div class="container py-5">
  <app-loader *ngIf="isLoading"></app-loader>
  <form class="devices-top-form px-5" [formGroup]="deviceCommandsReportForm" (ngSubmit)="onSubmit()">
    <h6 class="my-3 title">Command Status Report</h6>
    <div class="row mb-3">
      <div class="col-6 px-5" *ngIf="meanuType == '1'">
        <mat-form-field>
          <mat-label>client Name</mat-label>
          <mat-select [(ngModel)]='clientId' [ngModelOptions]="{standalone: true}" (ngModelChange)="onChange($event)">
            <mat-option *ngFor="let client of clients" [value]="client.id">
              {{ client.clientName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>Merchant</mat-label>
          <mat-select formControlName="merchantName">
            <mat-option *ngFor="let name of MerchntNames" [value]="name">
              {{ name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>Device Type</mat-label>
          <mat-select formControlName="deviceTypeId">
            <mat-option *ngFor="let type of typesLists" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>Device Status</mat-label>
          <mat-select formControlName="deviceStatus">
            <mat-option [value]="'1'">
              available
            </mat-option>
            <mat-option [value]="'0'">
              offline
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>Network Status</mat-label>
          <mat-select formControlName="networkStatus">
            <mat-option [value]="'1'">
              online
            </mat-option>
            <mat-option [value]="'0'">
              offline
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>Location</mat-label>
          <input matInput formControlName="locationName" />
        </mat-form-field>
      </div>
      <div class="col-6 px-5">
        <mat-form-field>
          <mat-label>POS Group Id</mat-label>
          <input matInput type="text" formControlName="deviceGroupId" [matAutocomplete]="auto" [value]="groupInput">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let groupId of filteredGroupIds | async" [value]="groupId.id"
              (onSelectionChange)="getName(groupId.name)">
              {{groupId.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-3 px-5">
        <mat-form-field>
          <mat-label>From Date</mat-label>
          <input matInput (dateInput)="addEvent('input', $event)" [matDatepicker]="fromDate" #start formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-3 px-5">
        <mat-form-field>
          <mat-label>To Date</mat-label>
          <input matInput [matDatepicker]="toDate" (dateInput)="addEvent('input', $event)" formControlName="endDate" />
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex justify-content-end my-4">
      <button type="submit" class="px-4 py-2 mr-2 btn-primary-custome">Submit</button>
      <button type="button" class="px-4 py-2 btn-secondary-custome" (click)="reset()">Clear</button>
    </div>
  </form>
</div>
<div class="container">
  <div class="text-right mb-3">
    <app-speed-dial-fab [filters]="filters" [reportType]="'commandReport'"
      *ngIf="showDownload"></app-speed-dial-fab>
  </div>
  <div class="table-responsive mat-elevation-z8 mb-5" *ngIf="tableData.length > 0">
    <table class="table">
      <thead class="">
        <th>Number</th>
        <th>Command</th>
        <th>Date sent</th>
        <th>Username</th>
        <th>Command status</th>
        <th>Account manager name</th>
        <th>Device type</th>
        <th>Device ID</th>
        <th>Serial number</th>
        <th>Merchant name</th>
        <th>Location name</th>
        <th>device status</th>
        <th>Network status</th>
      </thead>
      <tbody>
        <tr *ngFor="let data of dataSlice; let i = index;">
          <td>{{i}}</td>
          <td>{{data.command}}</td>
          <td>{{data.dateSent | date: "M/d/yy, h:mm a"}}</td>
          <td>{{data.userName}}</td>
          <td>{{data.commandStatus ? data.commandStatus : "-"}}</td>
          <td>{{data.accountManagerName ? data.accountManagerName: "-"}}</td>
          <td>{{data.deviceType}}</td>
          <td>{{data.deviceId}}</td>
          <td>{{data.serialNumber}}</td>
          <td>{{data.merchantName ? data.merchantName : "-"}}</td>
          <td>{{data.locationName ? data.locationName : "-"}}</td>
          <td>{{data.deviceStatus == 1? 'available' : 'offline'}}</td>
          <td>{{data.networkStatus}}</td>
        </tr>
      </tbody>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20]" [length]="tableData.length" showFirstLastButtons (page)="onPageChange($event)">
    </mat-paginator>
  </div>
</div>