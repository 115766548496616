import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Device } from 'src/app/models/device';
import { DeviceService } from 'src/app/services/device.service';

enum DeviceStatus {
  Available = 0,
  Offline = 1,
}

@Component({
  selector: 'app-client-edit-device',
  templateUrl: './client-edit-device.component.html',
  styleUrls: ['./client-edit-device.component.css'],
})
export class ClientEditDeviceComponent implements OnInit {
  editDevice: FormGroup;
  selected: any;
  typesList: any = [];
  groupsList: string[] = ['Altakamul', 'Blah Blah'];
  deviceImageUrl: string = '';
  isLoading: boolean = false;
  statusList: Array<string> = Object.keys(DeviceStatus).filter((key) =>
    isNaN(+key)
  );

  constructor(
    public dialogRef: MatDialogRef<ClientEditDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) public Device_Data: { device: Device },
    private deviceService: DeviceService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  Filtered_Devices_Groups: Observable<string[]>;

  ngOnInit() {
    this.editDevice = this.fb.group({
      deviceTypeId: [''],
      deviceStatus: [''],
    });
  }

  editDeviceInfo() {
    this.isLoading = true;
    console.log(this.editDevice.value);
  }

  toNumber(value): number {
    return Number(value);
  }
  onTypeChange(event) {
    console.log({ event });
    this.deviceImageUrl = this.deviceService.getTypeByID(event.value).imageUrl;
  }

  addDeviceToGroup() {}
}
