import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-delete-group',
  templateUrl: './delete-group.component.html',
  styleUrls: ['./delete-group.component.css'],
})
export class DeleteGroupComponent implements OnInit {
  groupId = '';
  isLoading: boolean = false;
  constructor(
    private groupService: GroupService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<DeleteGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public groupData: any,
    private dialog: MatDialog,
  ) {
    console.log('group data:',this.groupData);
    this.groupId = this.groupData.id;
  }

  ngOnInit(): void {}
  deleteGroup(id) {
    try {
      this.isLoading = true;
      this.groupService.deleteGroup(id).subscribe(
        (res) => {
          this.isLoading = false;
          this.toastr.success('Success');
          //console.log('delete group:', res);
          this.dialogRef.close();
          this.groupService.getGroups().subscribe(res => {
            console.log('thisis updated groups:', res)
          })
        },
        (error) => {
          this.dialog.closeAll();
          this.isLoading = false;
          this.toastr.error(error.message);
          console.log('Gafar', error);
        }
      );
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
}
