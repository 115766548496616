<div class="groups mb-3">
  <mat-form-field class="example-chip-list" *ngIf="deviceGroups.length > 0">
    <mat-label>Groups</mat-label>
    <mat-chip-list #chipList aria-label="Groups selection">
      <mat-chip *ngFor="let group of deviceGroups" [selectable]="selectable">
        {{group.groupName}}
        <mat-icon matChipRemove *ngIf="removable"  (click)="RemoveDeviceFromGroup(group)">cancel</mat-icon>
      </mat-chip>
      <input placeholder="Add new group ..." [formControl]="groupsCtrl" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matAutocomplete]="auto" (matChipInputTokenEnd)="AddDeviceToGroup($event)" autofocus>
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option (onSelectionChange)="addDeviceToGroup(group.id, group.name)" *ngFor="let group of filteredDeviceGroups | async" [value]="group.id">
        {{group.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div class="apps">
  <mat-form-field class="example-chip-list" *ngIf="Device_Apps.length > 0">
    <mat-label>Apps</mat-label>
    <mat-chip-list #chipList2>
      <mat-chip *ngFor="let app of Device_Apps; index as i">
        {{ app.appName | slice: 0:15 }}
        <mat-icon matChipRemove *ngIf="removable" (click)="removeAPK(app)">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </mat-form-field>
</div>