import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeviceType } from '../models/device';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  @Output() refresh = new EventEmitter<null>();
  headers: HttpHeaders = new HttpHeaders();
  clientID: string;
  deviceTypes: DeviceType[];
  constructor(private http: HttpClient) {
    let apiKey = localStorage.getItem('apiKey');
    this.clientID = localStorage.getItem('clientID');
    this.headers = this.headers.append('x-api-key', apiKey);
  }

  status: {} = {
    available: 0,
    offline: 1
  }

  getDevices() {
    return this.http.get(environment.baseURI + `devices/${this.clientID}`, {
      headers: this.headers,
    });
  }

  getDevicesByClientID(clientID) {
    return this.http.get(environment.baseURI + `devices/${clientID}`, {
      headers: this.headers,
    });
  }

  addDevice(device, client?) {
    //console.log('device', device)
    device.clientId = this.clientID;
    device.status = true;
    if (client) device.clientId = client.id;
    return this.http.post(environment.baseURI + 'Devices', device, {
      headers: this.headers,
    });
  }

  deleteDevice(serial: string) {
    return this.http.delete(environment.baseURI + 'Devices/' + serial);
  }

  addDevicesBySheet(file: FormData) {
    return this.http.post(environment.baseURI + 'devices/excel', file, {
      headers: this.headers,
    });
  }

  editDevice(serialNumber: string, device: any) {
    device.clientId = this.clientID;
    device.serialNumber = serialNumber;
    //console.log(device)
    return this.http.put(
      environment.baseURI + `Devices/${serialNumber}`,
      device,
      { headers: this.headers }
    );
  }

  getDeviceTypes() {
    return this.http
      .get(environment.baseURI + 'devices/types', {
        headers: this.headers,
      })
      .pipe(
        map((res: DeviceType[]) => {
          this.deviceTypes = res;
          return res;
        })
      );
  }

  getDeviceGroup() {

  }

  getTypeByID(id: string) {
    if (id) {
      let idx = this.deviceTypes.findIndex((type) => {
        return type.id == id;
      });
      return this.deviceTypes[idx];
    }
  }

  getDeviceLog(data) {
    return this.http.post(
      environment.baseURI + 'devicecommandlogs/filter',
      data,
      {
        headers: this.headers,
      }
    );
  }

  uploadAPK(file: FormData) {
    return this.http.post(
      environment.baseURI + 'devicecommandlogs/uploadapk/',
      file,
      { headers: this.headers }
    );
  }

  getClientAccountManager() {
    return this.http
      .get(environment.baseURI + `AccountManager/GetClientAccountManager?ClientId=${this.clientID}`, {
        headers: this.headers,
      })
      .pipe(
        map((res: any[]) => {
          return res;
        })
      );
  }

}
