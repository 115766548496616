// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const Domain = 'mdmapis.altkamul.ae';
export const environment = {
  production: false,
  SOCKET_ENDPOINT: 'http://178.157.14.40:3000/',
  domain: Domain,
  baseURI: `http://${Domain}/api/`,
  baseAPKUrl: `http://${Domain}/api/devicecommandlogs/downloadapk/`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
