import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoading: Boolean = false;
  constructor(
    private auth: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    if (this.loginForm.invalid) return;
    // this.auth.isLoggedIn = true;
    this.isLoading = true;
    this.auth.login(this.f.email.value, this.f.password.value).subscribe(
      (res) => {
        this.isLoading = false;
        // console.log(res);
        // console.log(res.apiKey, 'APIKEY');

        if (res.role > 0) {
          this.router.navigateByUrl('').then(() => {
            console.log('devices')
            location.reload();
          });
        } else {
          this.router.navigateByUrl('/clients').then(() => {
            location.reload();
          });
        }
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error(err.error.message);
        //console.log(err);
      }
    );
    // this.router.navigateByUrl('dashboard');
  }
}
