import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { Command, Device, DeviceInfo } from 'src/app/models/device';
import { DeviceService } from 'src/app/services/device.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { GroupService } from 'src/app/services/group.service';
import { SocketResponse, SocketService } from 'src/app/services/socket.service';
import { environment } from 'src/environments/environment';
import { CommandDeviceComponent } from '../command-device/command-device.component';

const ELEMENT_DATA: any[] = [
  {
    id: 1,
    command: 'WIFI ON',
    status: 'Waiting',
    time: 'H',
    user: 'Gafar',
    group: 'Takamul',
  },
  {
    id: 2,
    command: 'NFC Of',
    status: 'Excuted',
    time: 'H',
    user: 'Hargar',
    group: 'Takamul',
  },
];
@Component({
  selector: 'app-group-command',
  templateUrl: './group-command.component.html',
  styleUrls: ['./group-command.component.css'],
})
export class GroupCommandComponent implements OnInit {
  isLoading: boolean = false;
  isCheckingDeviceSate: boolean = false;
  apkUrl: string;
  installAPKSubscription: Subscription;
  installAPKBtn: string = 'install';
  isInstalling: boolean = false;
  map: boolean = false;
  Commands: Command[] = [
    {
      id: 123,
      name: 'Wifi',
      command: 'wifi-on',
      icon: 'network_wifi',
      state: false,
    },
    {
      id: 123,
      name: 'Data',
      icon: 'network_cell',
      command: 'data-on',
      state: false,
    },
    {
      id: 3,
      name: 'Bluetooth',
      icon: 'bluetooth',
      command: 'bluetooth-on',
      state: false,
    },
    { id: 4, name: 'NFC', icon: 'nfc', command: 'nfc-on', state: false },
    {
      id: 5,
      name: 'Reboot',
      icon: 'restart_alt',
      command: 'reboot',
      state: false,
    },
    {
      id: 6,
      name: 'PowerOff',
      icon: 'power_off',
      command: 'power-off',
      state: false,
    },
    {
      id: 7,
      name: 'Locathion',
      icon: 'location_on',
      command: 'location-on',
      state: false,
    },
    {
      id: 8,
      name: 'Test Printer',
      icon: 'print',
      command: 'print',
      state: false,
      text: 'TESTPRINT!',
    },
  ];

  SelectedDevicesTable: string[] = ['id', 'serialNumber', 'type', 'command'];
  datasource = [];

  deviceOnline: number = 0;
  deviceOffline: number = 0;
  printingText: string;

  Device_Group = new FormControl();
  filteredDeviceGroupsCommand: Observable<string[]>;
  deviceGroupsCommands: string[] = [
    'WIFI',
    'Celleuar Data',
    'Bluetooth',
    'NFC',
    'Reboot',
    'Power Off',
    'Location',
    'Print',
  ];
  logDataSource = new MatTableDataSource([]);
  displayedColumnsLogs: any[] = [
    'id',
    'commandData',
    'reciveTime',
    'sendTime',
    'group',
  ];
  // map: boolean = true;
  center: google.maps.LatLngLiteral = {
    lat: 0.0,
    lng: 0.0,
  };
  markerOptions: { animation: google.maps.Animation.BOUNCE };
  deviceLat: any;
  deviceLng: any;
  deviceLocathion: any;
  deviceInfo: DeviceInfo = {
    osVersion: 0,
    model: 'N\\A',
    serialNumber: 'N\\A',
    bluetoothStatus: 'N\\A',
    nfcStatus: 'N\\A',
    dataStatus: 'N\\A',
    locationStatus: 'N\\A',
    baterry: 'N\\A',
    wifiStatus: 'N\\A',
    status: 'Offline',
  };
  startDate: any;
  dateRangeFilter: FormGroup;
  logData: any = [];
  // groubDevices: any = [{ po }];
  devicesList: any[] = [];
  constructor(
    private socketService: SocketService,
    private toastr: ToastrService,
    public fileUpload: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public groupData: any,
    private deviceService: DeviceService,
    private dialog: MatDialog,
    private groupService: GroupService,
    private fb: FormBuilder
  ) {
    this.addMarker();
    this.isLoading = true;
    this.devicesList = groupData.devices;
    if (groupData.subGroups.length > 0) {
      console.log('HAs More');
      groupData.subGroups.forEach((group) => {
        this.devicesList = this.devicesList.concat(group.devices);
      });
    }

    this.deviceService.getDeviceTypes().subscribe(
      (res) => {
        this.isLoading = false;
        console.log(res);
        this.devicesList.forEach((element) => {
          element.action = 'Send';
          element.isInstalling == false;
          element.deviceType = this.deviceService.getTypeByID(
            element.deviceTypeId
          );
        });

        console.log({ groupData });

        this.datasource = this.devicesList;
        console.log(this.devicesList, 'device list');
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public onDate(event): void {
    console.log('STRTING DATE', this.startDate, event);
  }

  location() {
    // this.map = !this.map;
    // var myLatlng = new google.maps.LatLng(parseFloat(this.deviceLat),parseFloat(this.deviceLng));
    this.addMarker();
    this.center = {
      lat: parseFloat(this.deviceLat),
      lng: parseFloat(this.deviceLng),
    };
    // navigator.geolocation.getCurrentPosition((position) => {

    // });
  }
  markers: any = [
    {
      label: {
        color: 'blue',
        text: 'Marker label 0',
      },
      options: { animation: google.maps.Animation.BOUNCE },
      position: {
        lat: 0.03560780423799588,
        lng: 0.08203271107115673,
      },
      title: 'Marker title 2',
    },
  ];

  addMarker() {
    // this.isLoading = !this.isLoading;
    for (let index = 0; index < 5; index++) {
      console.log('FMARKER');

      this.markers.push({
        position: {
          lat: this.center.lat + ((Math.random() - 0.5) * 2) / 10,
          lng: this.center.lng + ((Math.random() - 0.5) * 2) / 10,
        },
        label: {
          color: 'red',
          text: 'Marker label ' + (this.markers.length + 1),
        },
        title: 'Marker title ' + (this.markers.length + 1),
        options: { animation: google.maps.Animation.BOUNCE },
      });
    }
    console.log('ADFSDFS', this.markers);

    // this.isLoading = !this.isLoading;
  }
  ngOnInit(): void {
    this.getGroupLogs();
    this.checkDevices();
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });

    this.filteredDeviceGroupsCommand = this.Device_Group.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterGroups(value))
    );

    this.dateRangeFilter = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
    });
  }

  private _filterGroups(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.deviceGroupsCommands.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }
  filterLogs() {
    console.log(this.dateRangeFilter.value);
    if (this.dateRangeFilter.valid) {
      let data = this.logData.filter((log) => {
        let date = new Date(log.dateTime).getTime();
        return (
          date >= this.dateRangeFilter.value.startDate &&
          date <= this.dateRangeFilter.value.endDate
        );
      });
      this.logDataSource = data;
      console.log({ data });
    } else {
      this.toastr.error('Please Enter A Valid Date Range');
    }
  }
  getGroupLogs() {
    console.log('AFAFAF', this.groupData.id);
    this.groupService.getGroupsLogs(this.groupData.id).subscribe(
      (res: any) => {
        var logs: any = [
          {
            id: '',
            commandData: '',
            reciveTime: '',
            sendTime: '',
            user: '',
          },
        ];
        this.logDataSource = res[0].deviceCommandLogs;
        this.logData = res[0].deviceCommandLogs;
      },
      (error) => {
        console.error('ERROR LOG', error);
      }
    );
  }
  dateFilter(start, end) {
    var selectedMembers = this.logDataSource.data.filter(
      (m) =>
        new Date(m.date) >= new Date(start) && new Date(m.date) <= new Date(end)
    );
  }
  applyFilter(event: Event) {
    console.log('HAHAHA', this.logDataSource);

    const filterValue = (event.target as HTMLInputElement).value;
    let data = this.logData.filter((log) => {
      return log.commandData.includes(filterValue);
    });
    this.logDataSource = data;
    // this.logDataSource.filter = filterValue.trim().toLowerCase();
    // this.logDataSource.filter((log)=>(return log.age <= 40));
    console.log('NOT HAHAHAH', this.logDataSource);
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async checkDevices() {
    if (this.devicesList.length > 0) {
      this.deviceOnline = 0;
      this.deviceOffline = 0;
      this.isCheckingDeviceSate = true;
      let count = 0;
      for (const device of this.devicesList) {
        try {
          await this.socketService.isDeviceOnline(device.serialNumber);
          this.deviceOnline = this.deviceOnline + 1;
        } catch (e) {
          this.deviceOffline = this.deviceOffline + 1;
        }

        count++;
        console.log({ count });

        count == this.devicesList.length
          ? (this.isCheckingDeviceSate = false)
          : (this.isCheckingDeviceSate = true);
      }
    }
  }

  // ngOnInit(): void {

  // }
  reload() {
    this.ngOnInit();
  }
  async getDeviceTypes() {}

  openCommandDeviceDialog(device: Device) {
    this.dialog.open(CommandDeviceComponent, { data: { device } });
  }

  findDeviceIdxByID(id) {
    let idx = this.groupData.devices.findIndex((device) => {
      if (device.id == id) return device;
    });
    return idx;
  }

  printText() {
    if (this.deviceOnline == 0)
      return this.toastr.error('All Device Are Offline');

    this.isLoading = true;
    let printText = this.printingText;
    console.log({ printText });

    this.socketService
      .sendGroupCommand(
        'printText',
        this.groupData.id,
        { printText },
        this.groupData.devices.length
      )
      .then(
        (data: any) => {
          console.log(data);
          if (data.event != 'error') {
            // command.state = !command.state;
            this.toastr.success('Success');
          } else {
            this.toastr.error(data.args.message);
          }
          this.isLoading = false;
        },
        (error) => {
          console.log({ error });
          this.isLoading = false;
        }
      )
      .catch((e) => {
        console.log({ e });
        this.isLoading = false;
        this.toastr.error(e);
      });
  }

  installApk() {
    if (this.deviceOnline == 0)
      return this.toastr.error('All Device Are Offline');
    this.isLoading = true;
    let url = this.apkUrl;
    console.log({ url });
    this.installAPKSubscription = this.socketService
      .sendGroupCommandObs(
        'install-apk',
        this.groupData.id,
        { url },
        this.groupData.devices.length
      )
      .subscribe(
        (res: SocketResponse) => {
          console.log({ res });

          this.groupData.devices.forEach((device) => {
            // let idx = this.findDeviceIdxByID(res.id)
            if (device.serialNumber == res.args.data.serialNo) {
              if (res.args.data.installStatus.statusText == 'STATUS_RUNNING') {
                device.action = 'RUNNING';
                device.isInstalling = true;
                // this.installAPKBtn = 'RUNNING';
              } else if (
                res.args.data.installStatus.statusText == 'STATUS_FAILED'
              ) {
                device.action = 'Failed';
                device.isInstalling = false;
                // this.installAPKSubscription.unsubscribe();
                this.toastr.error(res.args.data.installStatus.reasonText);
                this.isLoading = false;
              } else if (
                res.args.data.installStatus.statusText == 'STATUS_SUCCESSFUL'
              ) {
                device.action = 'Send';
                device.isInstalling = false;
                this.isLoading = false;
                this.toastr.success(
                  `installed Succesfully on ${device.serialNumber}`
                );
                // this.installAPKSubscription.unsubscribe();
              } else if (
                res.args.data.installStatus.statusText == 'STATUS_PENDING'
              ) {
                device.isInstalling = true;
                device.action = 'PENDING';
              }
            }
          });
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  async sendCommand(command: Command) {
    if (command.id == 7) this.map = !this.map;
    if (this.deviceOnline == 0)
      return this.toastr.error('All Device Are Offline');
    console.log('FIRSTER');
    this.isLoading = true;
    if (command.id == 7) {
      this.location();
    }
    console.log(this.groupData.id);
    let deviceCount = this.groupData.devices.length;
    let args = {
      printText: command.text,
    };
    this.socketService
      .sendGroupCommand(command.command, this.groupData.id, args, deviceCount)
      .then((res) => {
        this.isLoading = false;
        res.forEach((response) => {
          console.log(response.args.serialNo);
        });
        console.log({ res });
        this.toastr.success('Success');
      });
  }
  public files: NgxFileDropEntry[] = [];

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    if (files.length > 1) {
      this.toastr.error('Cannot add more than 1 File at a time.');
    } else {
      for (const droppedFile of files) {
        // Is it a file?
        if (
          droppedFile.fileEntry.isFile &&
          this.isFileAllowed(droppedFile.fileEntry.name)
        ) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            // Here you can access the real file
            console.log(droppedFile.relativePath, file);

            // You could upload it like this:
            const formData = new FormData();
            formData.append('apk', file, droppedFile.relativePath);
            this.isLoading = true;
            this.deviceService.uploadAPK(formData).subscribe((res: any) => {
              console.log({ res });
              this.isLoading = false;
              if (res.isSucceeded) {
                this.toastr.success('Uploaded Successfully');
                this.apkUrl = environment.baseAPKUrl + res.apkFileURL;
                this.installApk();
              } else {
                this.toastr.error('Something Went Wrong');
              }
            });
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          this.toastr.error(
            "Only files in '.apk', '.abb'  format are accepted and directories are not allowed."
          );
          // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // console.log(droppedFile.relativePath, fileEntry);
        }
      }
    }
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.apk', '.aab'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  fileOver(event) {
    console.log(event);
  }

  fileLeave(event) {
    console.log(event);
  }
}
