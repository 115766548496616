<h2 mat-dialog-title>Add Device</h2>

<mat-dialog-content
  style="width: 800px"
  class="add-device-dialog mat-typography"
  minWidth="1920"
>
  <mat-tab-group dynamicHeight>
    <!-- Start Edit Device Dialog -->

    <mat-tab label="Add Device">
      <mat-grid-list cols="9" rowHeight="1:5.5">
        <mat-grid-tile colspan="6">
          <form
            class="example-form"
            [formGroup]="AddDeviceForm"
            (ngSubmit)="AddDeviceFunc()"
          >
            <mat-form-field class="example-full-width">
              <mat-label>Serial Number</mat-label>
              <input matInput formControlName="serialNumber" />
            </mat-form-field>
            <br />
            <br />

            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Type</mat-label>
              <mat-select
                placeholder="Type"
                (selectionChange)="onTypeChange($event)"
                formControlName="deviceTypeId"
              >
                <mat-option *ngFor="let type of typesList" [value]="type.id">
                  {{ type.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Status</mat-label>
              <mat-select formControlName="deviceStatus" placeholder="Status">
                <mat-option
                  *ngFor="let state of statusList | keyvalue"
                  [value]="toNumber(state.key)"
                >
                  {{ state.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Group</mat-label>
              <mat-select formControlName="group" placeholder="Status">
                <mat-option *ngFor="let group of groupsList" [value]="group">
                  {{ group }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->

            <br />
            <mat-dialog-actions align="end">
              <button mat-button mat-dialog-close>Cancel</button>
              <button mat-button cdkFocusInitial type="submit">
                Save
                <span
                  *ngIf="isLoading"
                  class="spinner-border spinner-border-sm mr-1"
                ></span>
              </button>
            </mat-dialog-actions>
          </form>
        </mat-grid-tile>
        <mat-grid-tile colspan="3">
          <img
            mat-card-image
            [src]="imagTypeUrl"
            class="img-fluid"
            width="150"
            alt="Photo of a POS"
          />
        </mat-grid-tile>
      </mat-grid-list>
    </mat-tab>

    <mat-tab
      label="Add Devices By Search"
      style="height: 550px; width: fit-content"
    >
      <div class="row margin-top">
        <div class="column">
          <form class="form-serial-text-area-right-tab">
            <mat-form-field class="form-serial-text-area-right-tab">
              <mat-label
                >Enter Devices Serial Every Device In Single Line</mat-label
              >
              <textarea matInput class="text-area-contanier"> </textarea>
            </mat-form-field>
            <button mat-raised-button color="primary">Add</button>
          </form>
        </div>
        <!-- Right side -->
        <div class="column">
          <!-- <form class="form-serial-text-area-right-tab">
          <mat-form-field class="form-serial-text-area-right-tab">
            <mat-label
              >Enter Devices Serial Every Device In Single Line</mat-label
            >
            <textarea matInput class="text-area-contanier"> </textarea>
          </mat-form-field>
          <button mat-raised-button color="primary">Upload</button>
          <mat-label>
            <u>Download Sheet Template</u>
          </mat-label>
        </form> -->

          <ngx-file-drop
            class="form-serial-text-area-right-tab"
            dropZoneLabel="Drop files here"
            (onFileDrop)="dropped($event)"
            (onFileOver)="fileUpload.fileOver($event)"
            (onFileLeave)="fileUpload.fileLeave($event)"
            dropZoneClassName="group-file-drop-zone"
            contentClassName="group-file-drop-zone-content"
            accept=".xlsx"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              Drag Device Sheet in Here or
              <button mat-button color="primary" (click)="openFileSelector()">
                Browse Files
              </button>
            </ng-template>
          </ngx-file-drop>
          <button class="margin-2-precentage" mat-raised-button color="primary">
            Upload
          </button>
          <mat-label>
            <a
              mat-button
              [href]="sheetTemplateLink"
              download="sheet-template"
              target="_blank"
            >
              Download Sheet Template
            </a>
          </mat-label>
        </div>
      </div>
    </mat-tab>

    <!-- Ends Edit Device Dialog -->
  </mat-tab-group>
</mat-dialog-content>
