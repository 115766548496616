import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const helper = new JwtHelperService();
    let accessToken = localStorage.getItem('access_token');
    console.log({ accessToken });
    const isExpired = helper.isTokenExpired(accessToken);
    console.log({ isExpired });

    let isLoggedIn =
      localStorage.getItem('isLoggedIn') == 'true' ? true : false;
    console.log({ isLoggedIn });

    if (!isExpired && isLoggedIn) {
      this.auth.isLoggedIn = true;
      return true;
    } else {
      console.log('Token Invalid');
      return this.router.parseUrl('login');
    }
  }
}
