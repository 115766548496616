import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommandDeviceComponent } from 'src/app/modals/command-device/command-device.component';
import { Device } from 'src/app/models/device';
import { DeviceService } from 'src/app/services/device.service';
import { ClientAddDeviceComponent } from '../modals/client-add-device/client-add-device.component';
import { ClientDeleteDeviceComponent } from '../modals/client-delete-device/client-delete-device.component';
import { ClientEditDeviceComponent } from '../modals/client-edit-device/client-edit-device.component';

@Component({
  selector: 'app-client-devices',
  templateUrl: './client-devices.component.html',
  styleUrls: ['./client-devices.component.css'],
})
export class ClientDevicesComponent implements OnInit {
  isLoading: boolean = false;
  typesLists = ['MP4', 'MP4+', 'MP3'];
  GroupList = [
    'Reset Filter',
    'Altkamul',
    'Another',
    'AnotherOne',
    'AnotherOneTow',
  ];
  statusList = ['', 'True', 'False'];
  displayedColumnsClientDevicesDataSource: any[] = [
    'ID',
    'serialNumber',
    'Type',
    'Status',
    'createdOn',
    'SendCommand',
    'Option',
  ];
  ClientDevicesDataSource = new MatTableDataSource([]);
  devicesData: any;
  clientData: any;
  constructor(
    private dialog: MatDialog,
    private deviceService: DeviceService,
    private toastr: ToastrService,
    private router: Router
  ) {
    if (router.getCurrentNavigation().extras.state) {
      let client = router.getCurrentNavigation().extras.state.client;
      //console.log({ client });
      this.clientData = client;
      this.getDevices(client.id);
    }
  }

  ngOnInit(): void {
    this.deviceService.refresh.subscribe(res => this.getDevices(this.clientData.id));
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.ClientDevicesDataSource.filter = filterValue.trim().toLowerCase();
  }

  selectionChange(event: MatSelectChange) {
    this.ClientDevicesDataSource.filter = event.value.trim().toLowerCase();
  }

  openAddDeviceDialog(): void {
    this.dialog
      .open(ClientAddDeviceComponent, { data: this.clientData })
      .afterClosed()
      .subscribe(() => {
        this.getDevices(this.clientData.id);
      });
  }

  openCommandDeviceDialog(device: Device) {
    this.dialog.open(CommandDeviceComponent, { data: { device },  width: '800px' });
  }

  deleteDevice(device) {
    // console.log(device)
    this.dialog.open(ClientDeleteDeviceComponent, {data: {serialNumber: device.serialNumber}});
  }

  editDevice(device) {
    this.dialog.open(ClientEditDeviceComponent, { data: { device } });
  }

  async getDevices(clientID) {
    this.isLoading = true;
    this.deviceService.getDeviceTypes().subscribe(
      (res: any) => {
        console.log('Types', res);
        console.log('AFTER API', this.typesLists);
        for (let index = 0; index < res.length; index++) {
          this.typesLists[index + 1] = res[index].name;
        }
        console.log('AFTER API', this.typesLists);

        this.deviceService
          .getDevicesByClientID(clientID)
          .subscribe((res: any) => {
            console.log('BB', res);

            res.forEach((element) => {
              if (element.deviceTypeId != null) {
                element.deviceType = this.deviceService.getTypeByID(
                  element.deviceTypeId
                ).name;
                element.type = this.deviceService.getTypeByID(
                  element.deviceTypeId
                );
              } else {
                element.deviceType = null;
              }
            });
            console.log({ res });
            this.devicesData = res;
            this.ClientDevicesDataSource.data = res;

            this.isLoading = false;
          });
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error(`${err.statusText}`);
        this.router.navigateByUrl('/login');
      }
    );
  }
}
