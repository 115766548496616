<form
  class="example-form"
  style="padding-top: calc((-0.6667% - 0.666667px) * 1 + 1px)"
>
  <mat-form-field class="example-full-width">
    <mat-label>Groups</mat-label>
    <input
      type="text"
      placeholder="Pick one"
      aria-label="Number"
      matInput
      [formControl]="Device_Group"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredDeviceGroups | async"
        [value]="option"
        (click)="AddDeviceToGroup(option)"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
<mat-list role="list">
  <mat-list-item *ngFor="let opthion of Device_Own_Group">
    {{ opthion
    }}<mat-icon
      style="position: relative; right: -80%"
      (click)="RemoveDevicesFromGroup(opthion)"
    >
      close
    </mat-icon> </mat-list-item
  ><br />
</mat-list>

<mat-label>Apps</mat-label>
<mat-list role="list">
  <mat-list-item *ngFor="let opthion of Device_Apps">
    {{ opthion
    }}<mat-icon style="position: relative; right: -80%">
      close
    </mat-icon> </mat-list-item
  ><br />
</mat-list>
