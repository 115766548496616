<mat-dialog-content class="command-device-dialog mat-typography" minWidth="1920">
  <mat-tab-group>
    <!-- Start Command Device Dialog -->
    <mat-tab label="Commands">
      <div class="container-fluid">
        <div class="row justify-content-between align-items-center">
          <div class="col-6 auto-column py-2">
            <mat-label class="device-log-lb px-2">
              Device Id : {{ device.serialNumber }}
            </mat-label>
            <div class="form-row mt-3 mx-0">
              <div class="form-group col-12">
                <!-- Commands Card -->
                <div *ngIf="!map">
                  <google-map height="200px" [center]="center">
                    <map-marker [position]="center" [label]="deviceInfo.serialNumber" [options]="markerOptions">
                    </map-marker>
                  </google-map>
                </div>
                <div class="row justify-content-between mx-0">
                  <mat-button-toggle-group *ngFor="let command of Commands" class="col-3 px-0">
                    <mat-button-toggle class="selection-btn command-log mb-2" (click)="sendCommand(command)"
                      [class.selected]="command.state" [class.power-off]="command.id == 6"
                      [class.reboot]="command.id == 5" [class.location]="command.id == 7">
                      <mat-icon> {{ command.icon }} </mat-icon>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                  <div class="position-absolute w-100 h-100 spinner-container" *ngIf="sending">
                    <span class="spinner-border"></span>
                  </div>
                </div>
                <!-- Ends Commands Card -->
              </div>
              <div class="form-group col-12 d-flex justify-content-between align-items-center mb-5">
                <div class="print-field mr-3">
                  <!-- <label>Clearable input</label> -->
                  <input [(ngModel)]="printingText" class="custom-input" placeholder="Clearable input" type="text" />
                </div>
                <button class="btn-primary-custome" [ngClass]="{'d-flex align-items-center justify-content-between' : printing}" (click)="printText()">
                  Print
                  <span *ngIf="printing" class="spinner-border spinner-border-sm mr-1"></span>
                </button>
              </div>
              <div class="form-group col-12 mb-3">
                <ngx-file-drop class="full-width" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                  (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" dropZoneClassName="file-drop-zone"
                  contentClassName="file-drop-zone-content" accept=".apk">
                  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    Drag APK in Here or
                    <button mat-button color="primary" (click)="openFileSelector()">
                      Browse Files
                    </button>
                    <span *ngIf="isLoading" class="spinner-border spinner-border-sm tx-sm"></span>
                  </ng-template>
                  
                </ngx-file-drop>
              </div>
              <div class="form-group col-12 mb-5">
                <div class="print-field mb-2">
                  <input class="custom-input" type="text" [(ngModel)]="apkUrl" placeholder="APK URL" />
                </div>
                <div class="text-right">
                  <button class="btn-primary-custome" [ngClass]="{'d-flex align-items-center justify-content-between' : installing}" (click)="installApk()">
                    {{ installAPKBtn }}
                    <span *ngIf="installing" class="spinner-border spinner-border-sm ml-1"></span>
                  </button>
                </div>
              </div>
              <div class="form-group mb-0 col-12">
                <div class="row mx-0">
                  <div class="col-6 mb-3">
                    <mat-button-toggle class="selection-btn w-100">
                      Complete Recall
                    </mat-button-toggle>
                  </div>
                  <div class="col-6 mb-3">
                    <mat-button-toggle class="selection-btn w-100">
                      Update Products
                    </mat-button-toggle>
                  </div>
                  <div class="col-6 mb-3">
                    <mat-button-toggle class="selection-btn w-100">
                      Update Logos
                    </mat-button-toggle>
                  </div>
                  <div class="col-6 mb-3">
                    <mat-button-toggle class="selection-btn w-100">
                      Update Layout
                    </mat-button-toggle>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 auto-column py-2">
            <div class="row">
              <div class="col-12 text-center my-3">
                <img class="img-fluid device-img" width="150" height="100%" [src]="deviceImageUrl" alt="pos device"
                  (load)="onImageLoad()" />
              </div>
              <div class="col-12 text-center mb-3">
                <p class="device-log-lb px-2">
                  Battery : {{ deviceInfo.baterry }}
                </p>
                <p class="device-log-lb px-2">
                  Android Version : {{ deviceInfo.osVersion }}
                </p>
                <p class="device-log-lb px-2">
                  Model : {{ deviceInfo.model }}
                </p>
                <p class="device-log-lb px-2">
                  Status : {{ deviceInfo.status }}
                </p>
              </div>
              <div class="col-12 text-center">
                <app-groups-apps [device]="device"> </app-groups-apps>
              </div>
            </div>
            <!-- <ngx-skeleton-loader *ngIf="!imageLoaded" [theme]="{
              width: '70%',
              margin: 'auto',
              'text-align': 'center',
              display: 'block',
              height: '25%'
            }"></ngx-skeleton-loader> -->
          </div>
        </div>
      </div>

    </mat-tab>
    <!-- Ends Command Device Dialog -->

    <!-- Start Log  Dialog -->
    <mat-tab label="Logs">
      <div class="container-fluid">
        <form class="form-row example-form py-5" [formGroup]="Filter" (ngSubmit)="onFilterLogs()">
          <div class="col-6">
            <div formGroupName="date" class="w-100">
              <label>Date range</label>
              <mat-date-range-input class="custom-input position-relative" placeholder="Enter a date range"
                [rangePicker]="picker">
                <input matStartDate class="custom-input" placeholder="Start date" formControlName="startDate" />
                <input matEndDate class="custom-input" placeholder="End date" formControlName="endDate" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker" class="position-absolute custome-picker">
              </mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </div>
          </div>
          <div class="col-6">
            <label>Command</label>
            <input class="custom-input" placeholder="Search Logs by Command" formControlName="commandData" />
          </div>
          <div class="col-12 text-right mt-3">
            <button type="submit" class="btn-primary-custome mr-3">
              Search
            </button>
            <button type="button" class="btn-secondary-custome" (click)="onClearFilter()">
              Reset Filter
            </button>
          </div>
        </form>
        <div class="row mx-0">
          <div class="col-12 px-0">
            <div class="table-responsive mat-elevation-z8 mb-5">
              <mat-spinner class="custom-spinner" *ngIf="loading"></mat-spinner>
              <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">
                    {{ i + 1 }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="commandData">
                  <mat-header-cell *matHeaderCellDef> Command Data </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.command }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="commandStatus">
                  <mat-header-cell *matHeaderCellDef> Command Status </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.commandStatus ? element.commandStatus : '-' }}
                  </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sendingDateTime">
                  <mat-header-cell *matHeaderCellDef>
                    Sent at
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.dateSent | date: "M/d/yy, h:mm a" }}
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="userName">
                  <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.userName == null ? "null" : element.userName }}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsLogs"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsLogs"></mat-row>
              </mat-table>
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" length="dataSource"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- Ends Log  Dialog -->
  </mat-tab-group>
</mat-dialog-content>