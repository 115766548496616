import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MerchantsService } from 'src/app/merchants.service';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';
import { DeviceService } from 'src/app/services/device.service';
import { FormatsService } from 'src/app/services/formats.service';
import { GroupService } from 'src/app/services/group.service';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/services/reports.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-device-details',
  templateUrl: './device-details.component.html',
  styleUrls: ['./device-details.component.css'],
})
export class DeviceDetailsComponent implements OnInit {
  typesLists: any = [];
  isLoading: boolean = false;
  clients: any;
  clientId: string;
  groupIdList: any[] = [];
  filteredGroupIds: Observable<string[]>;
  deviceDetailsReportForm: FormGroup;
  breakpoint: number;
  MerchntNames: any = [];
  meanuType: string = this.authService.setMenuType();
  client: string;
  tableData: any;
  sliceTableData: any[] = [];
  showDownload: boolean = false;
  groupInput: string;
  filters: any;
  constructor(private formBuilder: FormBuilder,
    private clientService: ClientService,
    private groupService: GroupService,
    private deviceService: DeviceService,
    private merchantsService: MerchantsService,
    private reportsService: ReportsService,
    private authService: AuthService,
    private formatsService: FormatsService,
    private toastr: ToastrService) 
    {
    this.client = localStorage.getItem('clientID');
    this.deviceDetailsReportForm = this.formBuilder.group({
      deviceGroupId: [''],
      networkStatus: [''],
      deviceStatus: [''],
      locationName: [''],
      merchantName: [''],
      deviceTypeId: [''],
      startDate: [''],
      endDate: [''],
      excel: true
    });
  }

  ngOnInit(): void {
    if (this.meanuType == '1') {
      this.getClients();
      this.getMerchantNames('')
    }

    if (this.meanuType == '0') {
      this.getMerchantNames(this.client)
      this.getDeviceGroupId()
    }

    this.getDeviceTypes();

    this.filteredGroupIds = this.deviceDetailsReportForm.get('deviceGroupId').valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
    this.breakpoint = window.innerWidth <= 800 ? 1 : 5;
  }

  onResize(event) {
    this.breakpoint = event.target.innerWidth <= 800 ? 1 : 5;
  }

  getClients() {
    console.log('get clients')
    this.clientService.getClient().subscribe(res => {
      this.clients = res;
    });
  }

  getMerchantNames(id: string) {
    console.log('get merchants')
    this.merchantsService.getMercants(id).subscribe(res => {
      //console.log(res)
      this.MerchntNames = res;
      console.log("name:", this.MerchntNames)
    })
  }

  getDeviceGroupId() {
    if (this.meanuType == '1') {
      this.groupIdList = this.groupService.deviceGroupIdList(this.clientId);
    } else {
      this.groupIdList = this.groupService.deviceGroupIdList(this.client);
    }
  }

  getDeviceTypes() {
    console.log('get DeviceTypes')
    this.deviceService.getDeviceTypes().subscribe(
      (res: any) => {
        this.typesLists = res;
      },
      err => { console.log(err) }
    );

  }

  onChange(e) {
    //console.log(e)
    this.getDeviceGroupId();
    this.getMerchantNames(this.clientId);
  }

  filter(value: string): string[] {
    const filterValue = this.formatsService.normalizeValue(value);
    return this.groupIdList.filter(groupId => this.formatsService.normalizeValue(groupId.name).includes(filterValue));
  }

  onSubmit() {
    this.formatsService.formatDate(this.deviceDetailsReportForm);
    console.log(this.deviceDetailsReportForm.value);
    this.filters = this.deviceDetailsReportForm.value;
    this.isLoading = true;
    this.showDownload = false;
    this.sliceTableData = [];
    this.reportsService.detailedDeviceReport(this.deviceDetailsReportForm.value, 'json').subscribe(res => {
      this.isLoading = false;
      this.tableData = res.body;
      if(this.tableData.length <= 0) {
        //console.log('no data')
        this.toastr.info('no data available!');
        //this.toastr.warning('no data available!');
      } else {
        console.log(this.tableData)
        this.tableData.forEach(data => {
          if(data.devices.length > 4) {
            this.sliceTableData.push({merchant: data.merhcantGroupName, devices: data.devices.slice(0, 4)});
          } else {
            this.sliceTableData.push({merchant: data.merhcantGroupName, devices: data.devices});
          }
          
        })

        this.showDownload = true;
      }
      //console.log("after:", this.sliceTableData)
    },
    err => {
      //console.log(err)
      this.toastr.error(err.message);
      this.isLoading = false;
    })

    this.reset();
    //this.deviceCommandsReportForm.reset();
  }

  getName(value: string) {
    this.groupInput = value;
  }

  reset() {
      this.deviceDetailsReportForm.reset();
      this.deviceDetailsReportForm.setValue({
        deviceTypeId: '',
        deviceStatus: '',
        deviceGroupId: '',
        networkStatus: '',
        merchantName: '',
        locationName: '',
        startDate: '',
        endDate: '',
        excel: true,
      });
  }

  onPageChange(e: PageEvent, i: number) {
    const startIndex= e.pageIndex * e.pageSize;
    let endIndex = startIndex + e.pageSize;
    if(endIndex > this.tableData[i].devices.length) {
      endIndex = this.tableData[i].devices.length;
    }

    this.sliceTableData[i].devices =  this.tableData[i].devices.slice(startIndex, endIndex);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

}
