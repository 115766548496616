import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AddDevicesGroupComponent } from 'src/app/modals/add-devices-group/add-devices-group.component';
import { AddGroupComponent } from 'src/app/modals/add-group/add-group.component';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-client-groups',
  templateUrl: './client-groups.component.html',
  styleUrls: ['./client-groups.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ClientGroupsComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'count', 'commands', 'options'];
  dataSource = new MatTableDataSource([]);
  isLoading: boolean = false;
  groups: any = [];
  clientData: any;
  private state$: Observable<object>;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private groupService: GroupService,
    private toastr: ToastrService
  ) {
    if (router.getCurrentNavigation().extras.state) {
      let client = router.getCurrentNavigation().extras.state.client;
      console.log({ client });
      this.clientData = client;
      this.getGroups(client.id);
    }
  }

  ngOnInit(): void {
    this.state$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationStart),
      map(() => this.router.getCurrentNavigation().extras.state)
    );
  }

  openAddDeviceToGroupDialog(group: any) {
    this.dialog
      .open(AddDevicesGroupComponent, { data: group })
      .afterClosed()
      .subscribe(() => {
        // this.getGroups();
      });
  }

  openAddGroupDialog(group?) {
    this.dialog
      .open(AddGroupComponent, { data: this.clientData })
      .afterClosed()
      .subscribe(() => {
        this.getGroups(this.clientData.id);
      });
  }
  async getGroups(clientID) {
    this.isLoading = true;
    this.groupService.getGroupsByClientID(clientID).subscribe(
      (res) => {
        this.groups = this.groupService.groups;
        console.log(this.groups, 'Formatted');
        this.dataSource = new MatTableDataSource(this.groups);
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.toastr.error(`${err.statusText}`);
        this.router.navigateByUrl('/login');
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getColomn(colomn: string) {
    if (colomn == 'commands') return null;
    if (colomn == 'options') return 'undefined';

    return colomn;
  }
}
