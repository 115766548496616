import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { DeviceService } from 'src/app/services/device.service';
import { MatPaginator } from '@angular/material/paginator';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Device } from 'src/app/models/device';
import { EditDeviceComponent } from '../edit-device/edit-device.component';
import { Group } from 'src/app/models/group';
import { GroupService } from 'src/app/services/group.service';
import { error } from 'selenium-webdriver';

export interface AllDevices {
  id: number;
  serialNumber: string;
  deviceType: string;
  status: string;
  group: String;
}
export interface Element {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
// export interface SelectedDevices {
//   id: number;
//   name: string;
// }
// const Devices_Data: AllDevices[] = [
//   { id: 1, serialNumber: '123456', deviceType: 'MP4', status: 'H', group: 'ALtkamul' },
//   { id: 2, serialNumber: '123456', deviceType: 'MP3', status: 'H', group: 'ALtkamul' },
// ];

// var Selected_Devices_Data: SelectedDevices[] = [];

@Component({
  selector: 'app-add-devices-group',
  templateUrl: './add-devices-group.component.html',
  styleUrls: ['./add-devices-group.component.css'],
})
export class AddDevicesGroupComponent implements OnInit {
  // displayedColumns = ['select', 'position', 'name', 'weight', 'symbol'];
  displayedColumns: string[] = [
    'select',
    'id',
    'serialNumber',
    'deviceType',
    'status',
  ];
  displayedColumnsSelected: string[] = ['select', 'id', 'serialNumber'];
  data = [];

  dataSource = new MatTableDataSource<AllDevices>(this.data);
  selection = new SelectionModel<AllDevices>(true, []);

  checkedData = [];
  uncheckedData = this.data;
  checkedDataSource = new MatTableDataSource<AllDevices>(this.checkedData);
  checkedSelection = new SelectionModel<AllDevices>(true, []);

  selectedRows = [];
  selectedRowsChecked = [];

  sheetTemplateLink: string = '../../../assets/files/sheet-example.xlsx';

  public files: NgxFileDropEntry[] = [];
  header: HttpHeaders = new HttpHeaders();
  clientId: string;
  apiKey: string;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('checkedpaginator') checkedpaginator: MatPaginator;

  constructor(
    public fileUpload: FileUploadService,
    private deviceService: DeviceService,
    public toastr: ToastrService,
    private http: HttpClient,
    private groupService: GroupService,
    @Inject(MAT_DIALOG_DATA) public groupData: { group: Group },
    private dialog: MatDialog
  ) {
    console.log('HERE COME BOOM', this.groupData);
  }
  isLoading = false;
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.getDevices();
    this.dataSource.paginator = this.paginator;
    this.checkedDataSource.paginator = this.checkedpaginator;
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map((value) => this._filter(value))
    // );
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAllCheckedSelected() {
    const numSelected = this.checkedSelection.selected.length;
    const numRows = this.checkedDataSource.data.length;
    return numSelected === numRows;
  }

  transferSelectedRows() {
    console.log(this.selection.selected);

    this.selection.selected.forEach((item) => {
      let index: number = this.uncheckedData.findIndex((d) => d === item);
      console.log({ index });
      console.log(this.uncheckedData[index]);

      this.checkedData.push(this.uncheckedData[index]);
      this.uncheckedData.splice(index, 1);
    });

    console.log(this.uncheckedData, 'unchecked');
    console.log(this.checkedData, 'checked');

    this.selection = new SelectionModel<AllDevices>(true, []);
    this.dataSource = new MatTableDataSource<AllDevices>(this.uncheckedData);
    this.checkedDataSource = new MatTableDataSource<AllDevices>(
      this.checkedData
    );
    // this.dataSource.paginator = this.paginator;
    // this.checkedDataSource.paginator = this.checkedpaginator;
  }

  removeSelectedRows() {
    this.checkedSelection.selected.forEach((item) => {
      let index: number = this.checkedData.findIndex((d) => d === item);

      this.uncheckedData.push(this.checkedData[index]);
      this.checkedData.splice(index, 1);
    });

    this.checkedSelection = new SelectionModel<AllDevices>(true, []);
    this.dataSource = new MatTableDataSource<AllDevices>(this.uncheckedData);
    this.checkedDataSource = new MatTableDataSource<AllDevices>(
      this.checkedData
    );
    // this.dataSource.paginator = this.paginator;
    // this.checkedDataSource.paginator = this.checkedpaginator;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    console.log(this.data);
  }

  masterCheckedToggle() {
    this.isAllCheckedSelected()
      ? this.checkedSelection.clear()
      : this.dataSource.data.forEach((row) =>
          this.checkedSelection.select(row)
        );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async getDevices() {
    this.isLoading = true;
    this.deviceService.getDeviceTypes().subscribe();
    this.deviceService.getDevices().subscribe((res: any) => {
      console.log('RESPONDE', { res });
      this.isLoading = false;
      console.log(
        'OverNight',
        res.filter(
          ({ id: id1 }) =>
            !this.groupData['devices'].some(({ id: id2 }) => id2 === id1)
        )
      );
      this.dataSource.data = res.filter(
        ({ id: id1 }) =>
          !this.groupData['devices'].some(({ id: id2 }) => id2 === id1)
      );
      this.uncheckedData = this.dataSource.data;
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    if (files.length > 1) {
      this.toastr.error('Cannot add more than 1 File at a time.');
    } else {
      for (const droppedFile of files) {
        // Is it a file?
        if (
          droppedFile.fileEntry.isFile &&
          this.isFileAllowed(droppedFile.fileEntry.name)
        ) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            // Here you can access the real file
            console.log(droppedFile.relativePath, file);

            // You could upload it like this:
            const formData = new FormData();
            formData.append('sheet', file, droppedFile.relativePath);

            // Headers
            // const headers = new HttpHeaders({
            //   'security-token': 'mytoken'
            // })
            this.apiKey = localStorage.getItem('apiKey');
            this.clientId = localStorage.getItem('clientID');
            this.header = this.header.append('x-api-key', this.apiKey);
            this.http
              .post(environment.baseURI + 'devices/excel', formData, {
                headers: this.header,
              })
              .subscribe((data) => {
                // Sanitized logo returned from backend
                console.log(data);
              });
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          this.toastr.error(
            "Only files in '.xls', '.abb'  format are accepted and directories are not allowed."
          );
          // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          // console.log(droppedFile.relativePath, fileEntry);
        }
      }
    }
  }
  fileOver(event) {
    console.log(event);
  }

  fileLeave(event) {
    console.log(event);
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = ['.xlsx', '.xls'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  addDevicesToGroup() {
    this.isLoading = true;
    var selectedDevices: any = { serials: [], groupId: '' };
    console.log('checkedData:', this.checkedData);
    this.checkedData.forEach((element) => {
      selectedDevices.serials.push(element.serialNumber);
    });
    selectedDevices.groupId = this.groupData['id'];
    console.log('READY OR NOT', selectedDevices);
    this.groupService.addDeviceToGroup(selectedDevices).subscribe(
      (res) => {
        console.log(res);
        this.isLoading = false;
        this.toastr.success('Devices Added Successfuly');
        this.dialog.closeAll();
      },
      (error) => {
        console.error(error);
        this.toastr.error('Devices Not Been Added');
        this.isLoading = false;
      }
    );
  }

  addDevicesBySerial() {}
}
