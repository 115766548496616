import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs/internal/Observable';
import { io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

export interface SocketResponse {
  event: string;
  id: string;
  args: {
    ray_id?: string;
    data?: any;
    message?: string;
    serialNo?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  clientID: string;
  headers: HttpHeaders = new HttpHeaders();
  constructor(private socket: Socket, private http: HttpClient) {
    let apiKey = localStorage.getItem('apiKey');
    this.clientID = localStorage.getItem('clientID');
    this.headers = this.headers.append('x-api-key', apiKey);
  }

  getDeviceInfo(serialNo: string): Promise<SocketResponse> {
    return new Promise((resolve, reject) => {
      this.socket.emit('message', {
        event: 'getDeviceInfo',
        args: '',
        id: serialNo,
      });
      this.socket.on('message', (data: SocketResponse) => {
        resolve(data);
      });
    });
  }

  addDeviceLog(msg: string, device: any[]) {
    const date = new Date().toLocaleString();
    // console.log(msg)
    const body = {
      serialNumber: device[0],
      deviceId: device[1],
      commandId: null,
      commandData: msg,
      sendingTime: date,
      receivingTime: null,
      userId: this.clientID,
      CommandStatusId: 4
    };
    return this.http.post(
      environment.baseURI + 'DeviceCommandLogs',
      body,
      {
        headers: this.headers,
      }
    );
  }

  updateDeviceLog(id: string, status: number) {
    // console.log(id , status)
    const body = {
      CommandId: id,
      CommandStatusId: status
    };
    return this.http.post(
      environment.baseURI + 'devicecommandlogs/UpdateStatus',
      body,
      {
        headers: this.headers,
      }
    );
  }

  sendCommand(command: string, serialNo: any[], args?: any) {
    // console.log(args)
    return new Promise((resolve, reject) => {
      this.socket.emit('message', {
        event: command,
        id: serialNo,
        args,
      });

      this.socket.on('message', (data) => {
        resolve(data);
      });

      setTimeout(() => {
        reject('Request Timeout');
        //send timeout log
      }, 10000);
    });
  }
  
  
  sendCommandWithoutTimeOut(command: string, serialNo: string[], args?: any) {
    return new Promise((resolve, reject) => {
      this.socket.emit('message', {
        event: command,
        id: serialNo,
        args,
      });
      this.socket.on('message', (data) => {
        resolve(data);
      });
    });
  }

  isDeviceOnline(serialNo: string): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.socket.emit('message', {
        event: 'getDeviceInfo',
        args: '',
        id: serialNo,
      });
      this.socket.once('message', (data: SocketResponse) => {
        console.log(data.event, serialNo);
        if (data.event === 'device-information') {
          resolve(true);
        } else {
          reject(false);
        }
        // this.socket.disconnect();
      });
      setTimeout(() => {
        reject(false);
      }, 5000);
    });
  }

  sendCommandObs(command: string, serialNo: string[], args?: any) {
    return new Observable((observable) => {
      this.socket.emit('message', {
        event: command,
        id: serialNo,
        args,
      });
      this.socket.on('message', (data) => {
        observable.next(data);
      });
    });
  }

  sendGroupCommandObs(
    command: string,
    group: string,
    args?: any,
    deviceCount?: number
  ): Observable<SocketResponse> {
    // let res: SocketResponse[] = [];
    return new Observable((observable) => {
      this.socket.emit('message', {
        event: 'groupCommand',
        eventName: command,
        group,
        args,
      });
      this.socket.on('message', (data: SocketResponse) => {
        // res.push(data);
        observable.next(data);
      });
      // if (res.length == deviceCount) observable.next(res);
      // setTimeout(() => {
      //   observable.next(res);
      // }, 10000);
    });
  }

  sendGroupCommand(
    command: string,
    group: string,
    args?: any,
    deviceCount?: number
  ): Promise<SocketResponse[]> {
    let res: SocketResponse[] = [];
    return new Promise((resolve, reject) => {
      this.socket.emit('message', {
        event: 'groupCommand',
        eventName: command,
        group,
        args,
      });
      this.socket.on('message', (data: SocketResponse) => {
        res.push(data);
      });
      if (res.length == deviceCount) resolve(res);
      setTimeout(() => {
        resolve(res);
      }, 10000);
    });
  }

  getDeviceInfoObs(serialNo: string) {
    let observable = new Observable((observer) => {
      this.socket.emit('message', {
        event: 'getDeviceInfo',
        args: '',
        id: serialNo,
      });

      this.socket.on('message', (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }

}
