import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MerchantsService {

  constructor(private http: HttpClient) {
    let apiKey = localStorage.getItem('apiKey');
    this.headers = this.headers.append('x-api-key', apiKey);
   }

   headers: HttpHeaders = new HttpHeaders();

  getMercants(id: string) {
    let body = {clientId: id};
    return this.http.post(environment.baseURI + 'Devices/GetMerchant', body, {
      headers: this.headers
    })
  }
}


