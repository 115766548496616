import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddDevicesGroupComponent } from 'src/app/modals/add-devices-group/add-devices-group.component';
import { AddGroupComponent } from 'src/app/modals/add-group/add-group.component';
import { DeleteGroupComponent } from 'src/app/dialogs/delete-group/delete-group.component';
import { GroupCommandComponent } from 'src/app/modals/group-command/group-command.component';
import { Group } from 'src/app/models/group';

@Component({
  selector: 'app-recursive-table',
  templateUrl: './recursive-table.component.html',
  styleUrls: ['./recursive-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class RecursiveTableComponent implements OnInit {
  @Input() group: any;
  @Input() index: number = 0;

  displayedColumns: string[] = ['id', 'name', 'count', 'commands', 'options'];
  dataSource;
  expandedElement: Group | null;

  constructor(private dialog: MatDialog) {}

  openEditDeviceDialog(): void {
    // this.dialog.open(EditDeviceComponent);
  }
  openAddDeviceToGroupDialog(group: any) {
    this.dialog
      .open(AddDevicesGroupComponent, { data: group })
      .afterClosed()
      .subscribe(() => {
        // this.getGroups();
      });
  }

  openDeleteGroupDialog(group: any) {
    this.dialog
      .open(DeleteGroupComponent, { data: group })
      .afterClosed()
      .subscribe(() => {
        // this.getGroups();
      });
  }
  openCommandGroupDialog(group: any) {
    this.dialog.open(GroupCommandComponent, { data: group });
  }
  openAddGroupDialog(group: any) {
    this.dialog.open(AddGroupComponent, { data: group });
  }

  getColomn(colomn: string) {
    if (colomn == 'commands') return null;
    if (colomn == 'options') return 'undefined';

    return colomn;
  }

  getDevicesCount(group) {
    let count = 0;
    if (group.subGroups.length > 0) {
      group.subGroups.forEach((group) => {
        count += group.devices.length;
      });
    }
    count += group.devices.length;

    return count;
  }

  ngOnInit(): void {
    console.log(this.group, 'Group');
    this.dataSource = new MatTableDataSource(this.group);
  }
}
