import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Device } from '../models/device';
import { Group } from '../models/group';
import { MatDialog } from '@angular/material/dialog';
import { AddDeviceComponent } from '../modals/add-device/add-device.component';
import { EditDeviceComponent } from '../modals/edit-device/edit-device.component';
import { CommandDeviceComponent } from '../modals/command-device/command-device.component';
import { AddDevicesGroupComponent } from '../modals/add-devices-group/add-devices-group.component';
import { GroupCommandComponent } from '../modals/group-command/group-command.component';
import { DeleteGroupComponent } from '../dialogs/delete-group/delete-group.component';
import { AddGroupComponent } from '../modals/add-group/add-group.component';
import { GroupService } from '../services/group.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class GroupsComponent implements OnInit {
  isLoading = false;
  displayedColumns: string[] = ['No.', 'name', 'count', 'commands', 'options'];
  dataSource = new MatTableDataSource([]);
  expandedElement: Group | null;
  groups: any;
  constructor(
    private dialog: MatDialog,
    private groupService: GroupService,
    private toastr: ToastrService,
    private router: Router,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getGroups();
  }

  getDevicesCount(group) {
    let count = 0;
    if (group.subGroups.length > 0) {
      group.subGroups.forEach((group) => {
        count += group.devices.length;
      });
    }
    count += group.devices.length;

    return count;
  }

  getGroups() {
    this.isLoading = true;
    this.groupService.getGroups().subscribe(
      (res) => {
        this.groups = this.groupService.groups;
        console.log(this.groups, 'Formatted');
        this.dataSource = new MatTableDataSource(this.groups);
        this.isLoading = false;
      },
      (err) => {
        console.log({ err });

        this.isLoading = false;
        this.toastr.error(`${err.statusText}`);
        this.router.navigateByUrl('/login').then(() => {
          this.auth.clearUserData();
          location.reload();
        });
      }
    );
  }

  expanded(item: any) {
    item.expanded = !item.expanded;
    this.dataSource.data = this.getItems(this.dataSource.data, null, 0);
  }

  getColomn(colomn: string) {
    if (colomn == 'commands') return null;
    if (colomn == 'options') return 'undefined';

    return colomn;
  }

  getItems(data, items, index) {
    data.forEach((x) => {
      if (!items) items = [];
      items.push(x);
      items[items.length - 1].index = index;
      if (x.subgroups && x.expanded)
        this.getItems(x.subgroups, items, index + 1);
    });
    return items;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openEditDeviceDialog(): void {
    // this.dialog.open(EditDeviceComponent);
  }
  openAddDeviceDialog(): void {
    // this.dialog.open(AddDeviceComponent);
  }
  openCommandGroupDialog(group: any) {
    this.dialog.open(GroupCommandComponent, { data: group });
  }

  openAddDeviceToGroupDialog(group: any) {
    this.dialog
      .open(AddDevicesGroupComponent, { data: group })
      .afterClosed()
      .subscribe(() => {
        this.getGroups();
      });
  }
  openDeleteGroupDialog(group: any) {
    this.dialog
      .open(DeleteGroupComponent, { data: group })
      .afterClosed()
      .subscribe(() => {
        this.getGroups();
      });
  }

  openAddGroupDialog(group?) {
    this.dialog
      .open(AddGroupComponent, { data: group })
      .afterClosed()
      .subscribe(() => {
        this.getGroups();
      });
  }
}
