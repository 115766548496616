import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  headers: HttpHeaders = new HttpHeaders();
  menuType: any = localStorage.getItem('menuType');
  clientId: any = localStorage.getItem('clientID');
  constructor(private http: HttpClient) {
    let apiKey = localStorage.getItem('apiKey');
    this.headers = this.headers.append('x-api-key', apiKey);
  }
  
  accountManagerDashboard() {
    return this.http.post(
      environment.baseURI + 'Reports/dashboard/accountmanager',
      {}
    );
  }

  accountManager() {
    let uri = this.menuType == 0 ? `AccountManager/GetClientAccountManager?ClientId=${this.clientId}` : 'AccountManager';
    return this.http.get(environment.baseURI + uri, {  headers: this.headers });
  };

  getDevicesByArea() {
    let uri = this.menuType == 0 ? `Dashboard/GetDevicesByArea?ClientId=${this.clientId}` : 'Dashboard/GetDevicesByArea';
    return this.http.get(
      environment.baseURI + uri,
    {
      headers: this.headers,
    }
    ).pipe(map(res => {
      return res;
    }));
  };

  getGroupsStatus() {
    let uri = this.menuType == 0 ? `Dashboard/GetGroups?ClientId=${this.clientId}` : 'Dashboard/GetGroups';
    return this.http.get(
      environment.baseURI + uri,
    {
      headers: this.headers,
    }
    ).pipe(map(res => {
      return res;
    }));
  };

  getAccountManagers() {
    let uri = this.menuType == 0 ? `Dashboard/GetAccountManagers?ClientId=${this.clientId}` : 'Dashboard/GetAccountManagers';
    return this.http.get(
      environment.baseURI + uri,
    {
      headers: this.headers,
    }
    ).pipe(map(res => {
      return res;
    }));
  };

  getLast10Logs() {
    let uri = this.menuType == 0 ? `Dashboard/GetLast10Logs?ClientId=${this.clientId}` : 'Dashboard/GetLast10Logs';
    return this.http.get(
      environment.baseURI + uri,
    {
      headers: this.headers,
    }
    ).pipe(map(res => {
      return res;
    }));
  };
  
  getCommandsNotCompeleted() {
    let uri = this.menuType == 0 ? `Dashboard/GetNotCompletedCommand?ClientId=${this.clientId}` : 'Dashboard/GetNotCompletedCommand';
    return this.http.get(
      environment.baseURI + uri,
    {
      headers: this.headers,
    }
    ).pipe(map(res => {
      return res[0].countofCommand;
    }));
  }
  
}
