<h1 mat-dialog-title>Add Group</h1>
<div mat-dialog-content style="width: 700px; height: 150px">
  <form [formGroup]="addGroupForm" (ngSubmit)="addGroup()">
    <mat-form-field style="width: 100%">
      <mat-label>Group Name</mat-label>
      <input matInput id="groupName" type="text" formControlName="groupName" />
    </mat-form-field>

    <div mat-dialog-actions style="float: right">
      <button mat-button mat-dialog-close>No Thanks</button>
      <button mat-button cdkFocusInitial type="submit">
        Ok
        <span
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
      </button>
    </div>
  </form>
</div>
