import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-annual-sales-chart',
  templateUrl: './annual-sales-chart.component.html',
  styleUrls: ['./annual-sales-chart.component.css'],
})
export class AnnualSalesChartComponent implements OnInit {
  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];
  public lineChartLabels: Label[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
  ];
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];

  constructor() {}

  Device_Group = new FormControl(); // Device Groups Add
  Device_Groups: string[] = ['Altakamul', 'Another', 'Another'];
  Device_Own_Group: string[] = [];
  filteredDeviceGroups: Observable<string[]>;

  Device_App = new FormControl(); // Device Groups Add
  Device_Apps: string[] = ['App', 'AnotherApp', 'AnotherApp'];
  filteredDeviceApps: Observable<string[]>;

  ngOnInit() {
    this.filteredDeviceGroups = this.Device_Group.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterGroups(value))
    );

    this.filteredDeviceApps = this.Device_App.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterApps(value))
    );
  }

  addGroup() {
    // this.SelectedGroups.push();
  }
  AddDeviceToGroup(group) {
    this.Device_Own_Group.push(group);
    console.log(group);
  }
  RemoveDevicesFromGroup(group) {
    var index = this.Device_Own_Group.indexOf(group);
    console.log('GAFAR', index);

    this.Device_Own_Group.splice(index, 1);
  }
  private _filterGroups(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Device_Groups.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterApps(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Device_Groups.filter(
      (option) => option.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
