import {
  Component,
  OnInit,
  ViewChild,
  VERSION,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { DeviceService } from 'src/app/services/device.service';
import { FileUploadService } from 'src/app/services/file-upload.service';

enum DeviceStatus {
  Available = 1,
  Offline = 0,
}
@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.css'],
})
export class AddDeviceComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  imagTypeUrl: string = '';
  isLoading: boolean = false;
  serialNo = new FormControl();
  types = new FormControl();
  typesList: any = [];
  status = new FormControl();
  statusList: Array<string> = Object.keys(DeviceStatus).filter((key) =>
    isNaN(+key)
  );
  group = new FormControl();
  groupsList: string[] = ['Altakamul', 'Blah Blah'];
  AddDeviceForm: FormGroup;
  sheetTemplateLink: string = '../../../assets/files/sheet-example.xlsx';
  accountManagers: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    public deviceService: DeviceService,
    private dialog: MatDialog,
    public fileUpload: FileUploadService,
    private toastr: ToastrService
  ) {
    this.imagTypeUrl = this.deviceService.deviceTypes[0].imageUrl;
    this.AddDeviceForm = this.formBuilder.group({
      serialNumber: ['', Validators.required],
      deviceTypeId: ['', Validators.required],
      deviceStatus: ['', Validators.required],
      deviceLocation: ['', Validators.required],
      ref: ['', Validators.required],
      simSerial: ['', Validators.required],
      msisdn: ['', Validators.required],
      firmwareVersion: ['', Validators.required],
      apkVersion: ['', Validators.required],
      accountManagerID: [''],
    });
  }

  ngOnInit(): void {
    this.typesList = this.deviceService.deviceTypes;
    this.getClientAccountManger();
  }

  toNumber(value): number {
    return Number(value);
  }

  AddDeviceFunc() {
    if (this.AddDeviceForm.valid) {
      this.isLoading = true;
      this.deviceService.addDevice(this.AddDeviceForm.value).subscribe(
        (data) => {
          this.isLoading = false;
          this.AddDeviceForm.reset();
          this.dialog.closeAll();
          this.toastr.success('device added successfully', 'success');
        },
        (err) => {
          this.isLoading = false;
          // this.AddDeviceForm.reset();
          this.toastr.error(err.error.message, 'failed');
        }
      );
    }
  }

  onTypeChange(event) {
    console.log({ event });
    this.imagTypeUrl = this.deviceService.getTypeByID(event.value).imageUrl;
  }

  dropped(files: NgxFileDropEntry[]) {
    this.files = files;

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          // You could upload it like this:
          const formData = new FormData();
          formData.append('sheet', file, droppedFile.relativePath);

          this.deviceService.addDevicesBySheet(formData).subscribe(
            (res) => {
              console.log({ res });
              this.toastr.success('Devices Added Succesfully');
            },
            (err) => {
              console.log({ err });

              this.toastr.error(err.error.message);
            }
          );
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  getClientAccountManger() {
    this.deviceService.getClientAccountManager().subscribe(
      (res) => {
        // console.log(res);
        this.accountManagers = res;
      },
      (err) => console.log(err)
    );
  }
}
