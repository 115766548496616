<h1 mat-dialog-title>Add Order</h1>
<div mat-dialog-content style="width: 50vw; height: 17vh">
  <form [formGroup]="addOrderForm" (ngSubmit)="addOrder()">
    <mat-form-field style="width: 50%; margin-right: 5%">
      <mat-label>Device Count</mat-label>
      <input matInput id="count" type="number" formControlName="count" />
    </mat-form-field>

    <mat-form-field style="width: 50%; margin-right: 5%">
      <mat-label>Current Device Count</mat-label>
      <input
        matInput
        id="currentCount"
        type="number"
        formControlName="currentCount"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Expiry Date</mat-label>
      <input
        formControlName="expiryDate"
        matInput
        [matDatepicker]="picker"
        placeholder="title"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <div mat-dialog-actions style="float: right">
      <button mat-button mat-dialog-close>No Thanks</button>
      <button mat-button cdkFocusInitial type="submit">
        Ok
        <span
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-1"
        ></span>
      </button>
    </div>
  </form>
</div>
