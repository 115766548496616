import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from '../dialogs/logout/logout.component';
import { NavService } from '../services/nav.service';
import { NavItem } from '../models/NavItem';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
})
export class NavComponent {
  @Output() sidenavClose = new EventEmitter();
  @ViewChild('drawer') appDrawer: ElementRef;

  // menuItems = ['devices', 'groups', 'clients'];
  menuItems = [];
  isLoggedIn: string;
  selectedItem: NavItem;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public auth: AuthService,
    private dialog: MatDialog,
    private navService: NavService
  ) {
    this.auth.setMenuType();
    this.menuItems = this.auth.menuItems;
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
    this.isLoggedIn = localStorage.getItem('isLoggedIn');
  }

  logout() {
    this.dialog.open(LogoutComponent);
  }
}
